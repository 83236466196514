import axios from 'axios';
import React from 'react';
import { API_BASE_URL } from '../contants';

export default function useUploadMedia(setError, setUploadLoading, setId) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const mutate = (payload) => {
    setIsLoading(true);
    setError('');
    const endpoint = API_BASE_URL() + '/business/uploadMedia';
    axios
      .post(endpoint, payload)
      .then(({ data }) => {
        setIsLoading(false);
        setData(data.data);
        setId(data?.result?.id);
      })
      .catch((err) => {
        const { response } = err;
        setError(response?.data?.message);
        setUploadLoading(false);
        setIsLoading(false);
      });
  };
  return { mutate, isLoading, data };
}
