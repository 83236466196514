import { useEffect, useRef, useState } from 'react';
import PoweredBy from '../../common/PoweredBy';
import { loadingImage } from '../../contants';
import Banner from '../Banner';
import GoogleBadgeDesign from '../GoogleBadgeDesign';
import GoogleCarousel from '../GoogleCarousel';
import { UseWindowDimensions } from '../collage/utils';
import { IData, IView } from './DisplayWidget.container';
import PageNumber from './components/PageNumber';
import View from './components/View';
import { useDisplayWidgetContext } from './utils/context';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { hexToRgba } from '../../Helpers/Helpers';

export default function DisplayWidgetView() {
  let time = 1;
  let timer;
  const {
    albumData,
    setIsUploadModalOpen,
    handleLoadMoreCTA,
    page,
    setPage,
    headerColor,
    totalPages,
    ref,
    loader,
    isGoogle,
    guid,
  } = useDisplayWidgetContext();
  const { data }: { data: IData } = useDisplayWidgetContext();
  const layout: any = data?.result?.layout;
  const { width } = UseWindowDimensions();
  const [loadHover, setLoadHover] = useState(false);
  const topRef = useRef(null);
  const setScroll: any = (speed: number, intervalTime: number) => {
    timer = setInterval(function () {
      const down = time * speed;
      if (
        down >=
        document.getElementById('galleryLayoutId')?.scrollHeight -
          document.getElementById('galleryLayoutId')?.clientHeight
      ) {
        document.getElementById('galleryLayoutId')?.scrollTo(0, 0);
        time = 1;
      } else {
        document.getElementById('galleryLayoutId')?.scrollTo(0, down);
        time++;
      }
    }, intervalTime);
  };
  useEffect(() => {
    if (layout?.gallerySettings?.scrollInterval !== '0' && layout?.gallerySettings?.scrollSpeed !== '0') {
      time = 1;
      setScroll(parseInt(layout?.gallerySettings?.scrollSpeed), parseInt(layout?.gallerySettings?.scrollInterval));
    }
    return () => {
      clearInterval(timer);
    };
  }, [layout?.gallerySettings]);
  if (data && layout)
    return (
      <React.Fragment>
        {albumData?.length > 0 && (
          <idk-div
            class={`widgetContainer zid-bg-gray-200 ${
              layout.galleryView == 'frame' ? '' : 'zid-py-2'
            } zid-mx-auto zid-relative`}
            id="scrollable"
            style={{
              color: layout?.headerTitle?.textColor,
              fontSize: `${layout?.headerTitle?.desktopFont}px`,
              backgroundColor: hexToRgba(layout?.galleryColors?.bgColor, layout?.galleryColors?.bgOpacity),
              height: window.innerWidth < 575.98 ? '100%' : layout.gallerySettings.height,
              maxHeight: layout.gallerySettings.height,
              transform: 'translateZ(0)',
              overflowY: 'auto',
              overflowX: 'hidden',
              maxWidth: layout?.gallerySettings?.width,
            }}
          >
            {layout?.specialHeader?.showSpecialHeader && layout?.specialHeader?.specialHeaderLayout == 'vertical' && (
              <idk-div class="zid-flex zid-flex-col zid-items-center zid-mb-2">
                <FaUserCircle className="zid-text-gray-300 zid-mb-2" fontSize={80} />
                <idk-p class="zid-font-bold zid-text-lg">Idukki</idk-p>
                <idk-p class="zid-mb-2">@Idukki.io</idk-p>
                <idk-div class="zid-flex zid-items-center zid-w-[80%] justify-around zid-mb-2">
                  <idk-div class="zid-flex zid-flex-col zid-items-center zid-flex-1">
                    <idk-span class="zid-font-bold zid-text-lg">12</idk-span>
                    <idk-span>Posts</idk-span>
                  </idk-div>
                  <idk-div class="zid-flex zid-flex-col zid-items-center zid-flex-1">
                    <idk-span class="zid-font-bold zid-text-lg">2K+</idk-span>
                    <idk-span>Followers</idk-span>
                  </idk-div>
                  <idk-div class="zid-flex zid-flex-col zid-items-center zid-flex-1">
                    <idk-span class="zid-font-bold zid-text-lg">5</idk-span>
                    <idk-span>Following</idk-span>
                  </idk-div>
                </idk-div>
                <button className="zid-cursor-pointer zid-inline-flex zid-items-center zid-justify-center zid-rounded-md zid-text-sm zid-font-medium zid-transition-colors focus-visible:zid-outline-none focus-visible:zid-ring-1 focus-visible:zid-ring-ring disabled:zid-pointer-events-none disabled:zid-opacity-50 zid-bg-black zid-text-white zid-shadow hover:zid-bg-black/90 zid-h-9 zid-px-4 zid-py-2 zid-mb-2">
                  Follow
                </button>
                <idk-p>
                  Responsive widget for Instagram. Featuring amazing photographers! Need Instagram widget for your
                  website? Visit:
                </idk-p>
                <a className="zid-underline zid-text-[#0077c7]" href="https://app.idukki.io/" target="_blank">
                  https://app.idukki.io/
                </a>
              </idk-div>
            )}
            {layout?.specialHeader?.showSpecialHeader && layout?.specialHeader?.specialHeaderLayout == 'horizontal' && (
              <idk-div class="zid-flex zid-flex-col">
                <idk-div class="zid-flex zid-flex-row zid-items-center zid-mb-2 zid-gap-5">
                  <FaUserCircle className="zid-text-gray-300 zid-mb-2" fontSize={80} />
                  <idk-div class="zid-flex zid-flex-col">
                    <idk-p class="zid-font-bold zid-text-lg">Idukki</idk-p>
                    <idk-p class="zid-mb-2">@Idukki.io</idk-p>
                  </idk-div>
                  <idk-div class="zid-flex zid-items-center justify-around zid-mb-2 zid-gap-5">
                    <idk-div class="zid-flex zid-flex-col zid-items-center zid-flex-1">
                      <idk-span class="zid-font-bold zid-text-lg">12</idk-span>
                      <idk-span>Posts</idk-span>
                    </idk-div>
                    <idk-div class="zid-flex zid-flex-col zid-items-center zid-flex-1">
                      <idk-span class="zid-font-bold zid-text-lg">2K+</idk-span>
                      <idk-span>Followers</idk-span>
                    </idk-div>
                    <idk-div class="zid-flex zid-flex-col zid-items-center zid-flex-1">
                      <idk-span class="zid-font-bold zid-text-lg">5</idk-span>
                      <idk-span>Following</idk-span>
                    </idk-div>
                  </idk-div>
                  <button className="zid-cursor-pointer zid-inline-flex zid-items-center zid-justify-center zid-rounded-md zid-text-sm zid-font-medium zid-transition-colors focus-visible:zid-outline-none focus-visible:zid-ring-1 focus-visible:zid-ring-ring disabled:zid-pointer-events-none disabled:zid-opacity-50 zid-bg-black zid-text-white zid-shadow hover:zid-bg-black/90 zid-h-9 zid-px-4 zid-py-2 zid-mb-2">
                    Follow
                  </button>
                </idk-div>
                <idk-p class="zid-text-sm">
                  Responsive widget for Instagram. Featuring amazing photographers! Need Instagram widget for your
                  website? Visit:
                </idk-p>
                <a className="zid-underline zid-text-[#0077c7]" href="https://app.idukki.io/" target="_blank">
                  https://app.idukki.io/
                </a>
              </idk-div>
            )}

            {layout?.specialHeader?.showSpecialHeader && layout?.specialHeader?.specialHeaderLayout == 'layout1' && (
              <idk-div class="zid-flex zid-flex-col">
                <idk-div class="zid-flex zid-items-center zid-justify-between">
                  <idk-div class="zid-flex zid-flex-row zid-items-center zid-mb-2 zid-gap-5">
                    <FaUserCircle className="zid-text-gray-300 zid-mb-2" fontSize={80} />
                    <idk-div class="zid-flex zid-flex-col">
                      <idk-p class="zid-font-bold zid-text-lg">Idukki</idk-p>
                      <idk-p class="zid-mb-2">@Idukki.io</idk-p>
                    </idk-div>
                  </idk-div>
                  <button className="zid-cursor-pointer zid-inline-flex zid-items-center zid-justify-center zid-rounded-md zid-text-sm zid-font-medium zid-transition-colors focus-visible:zid-outline-none focus-visible:zid-ring-1 focus-visible:zid-ring-ring disabled:zid-pointer-events-none disabled:zid-opacity-50 zid-bg-black zid-text-white zid-shadow hover:zid-bg-black/90 zid-h-9 zid-px-4 zid-py-2 zid-mb-2">
                    Follow
                  </button>
                </idk-div>
                <idk-div class="zid-flex zid-items-center zid-justify-between zid-mb-2">
                  <idk-div class="zid-flex zid-flex-col zid-items-center ">
                    <idk-span class="zid-font-bold zid-text-lg">12</idk-span>
                    <idk-span>Posts</idk-span>
                  </idk-div>
                  <idk-div class="zid-flex zid-flex-col zid-items-center ">
                    <idk-span class="zid-font-bold zid-text-lg">2K+</idk-span>
                    <idk-span>Followers</idk-span>
                  </idk-div>
                  <idk-div class="zid-flex zid-flex-col zid-items-center ">
                    <idk-span class="zid-font-bold zid-text-lg">5</idk-span>
                    <idk-span>Following</idk-span>
                  </idk-div>
                </idk-div>
                <idk-p class="zid-text-sm">
                  Responsive widget for Instagram. Featuring amazing photographers! Need Instagram widget for your
                  website? Visit:
                </idk-p>
                <a className="zid-underline zid-text-[#0077c7]" href="https://app.idukki.io/" target="_blank">
                  https://app.idukki.io/
                </a>
              </idk-div>
            )}

            <idk-div ref={topRef}>
              {layout?.gallerySiteUploads?.buttonShow && (
                <idk-div class="zid-flex zid-justify-center zid-mt-5">
                  <button
                    className="zid-px-3 zid-py-2 zid-rounded zid-cursor-pointer zid-drop-shadow hover:zid-scale-105 zid-transition"
                    onClick={() => setIsUploadModalOpen(true)}
                    style={{
                      backgroundColor: layout?.gallerySiteUploads?.siteUploadButtonColor,
                      fontSize: '16px',
                      color: layout?.gallerySiteUploads?.siteUploadButtonTextColor,
                    }}
                  >
                    {layout?.gallerySiteUploads?.addButtonText}
                  </button>
                </idk-div>
              )}
              {layout?.galleryView != 'badge' && layout?.galleryView != 'frame' && (
                <idk-div
                  style={{ flexDirection: width <= 600 ? 'column' : 'row' }}
                  class="zid-flex zid-justify-between zid-m-5 zid-mb-2 zid-font-[500] zid-items-center zid-mt-0 zid-gap-[100px]"
                >
                  <idk-div
                    class="heading_main_container"
                    style={{ fontFamily: layout?.headerTitle?.fontFamily, flex: 1 }}
                  >
                    <idk-p
                      style={{
                        wordBreak: 'break-word',
                        fontSize:
                          width > 400 ? layout?.headerTitle?.desktopFont : layout?.headerTitle?.mobileFont + 'px',
                        textAlign: layout?.headerTitle?.alignment,
                      }}
                      dangerouslySetInnerHTML={{ __html: layout?.headerTitle?.title }}
                    />

                    <idk-p
                      class="zid-text-[14px] zid-text-black"
                      dangerouslySetInnerHTML={{ __html: layout?.headerTitle?.subTitle }}
                    />
                  </idk-div>
                  {layout?.headerButton?.followUs && (
                    <button
                      className=" zid-px-3 zid-py-2 zid-rounded zid-drop-shadow hover:zid-scale-105 zid-cursor-pointer zid-transition	"
                      onClick={() => {
                        window.open(layout?.headerButton?.headerButtonUrl, '_blank');
                      }}
                      style={{
                        backgroundColor: layout?.headerButton?.headerButtonColor,
                        fontSize: '16px',
                        color: layout?.headerButton?.headerButtonTextColor,
                      }}
                    >
                      {layout.headerButton.headerButtonText}
                    </button>
                  )}
                </idk-div>
              )}
            </idk-div>
            <idk-div>
              <idk-div
                class="zid-w-full zid-flex zid-justify-center scrollbar-hide"
                id={
                  layout?.gallerySettings?.scrollInterval && layout?.gallerySettings?.scrollSpeed
                    ? 'galleryLayoutId'
                    : ''
                }
                style={{
                  transform: `rotate(${layout.gallerySettings.tiltAngle}deg)`,
                  // overflowY: 'auto',
                  height: layout?.gallerySettings?.height + 'px',
                }}
                ref={ref}
              >
                <idk-div class={`zid-w-full ${layout?.galleryView == 'frame' ? '' : 'md:zid-p-[16px]'} zid-h-full`}>
                  {layout?.banners?.bannerTop && (
                    <Banner src={layout?.banners?.bannerTop} link={layout?.banners?.bannerTopUrl} />
                  )}
                  <idk-div
                    class="zid-flex zid-items-center"
                    style={{
                      flexDirection: width < 500 ? 'column' : 'row',
                      paddingBottom: `${
                        layout?.galleryView == 'frame' ? 0 : layout?.galleryInformation?.postSpacing
                      }px`,
                    }}
                  >
                    {isGoogle && layout?.galleryView !== 'carousel' && layout?.googleBadges?.positionTop && (
                      <idk-div style={{ flexGrow: 1 }}>
                        <GoogleBadgeDesign metaJson={data?.result?.metaJson} />{' '}
                      </idk-div>
                    )}
                  </idk-div>
                  {isGoogle && layout?.googlePhoto?.showPhoto && layout?.googlePhoto?.photoPositionTop && (
                    <idk-div class="zid-mb-2">
                      <GoogleCarousel />
                    </idk-div>
                  )}
                  <View />
                  {isGoogle && layout?.googlePhoto?.showPhoto && !layout?.googlePhoto?.photoPositionTop && (
                    <idk-div class="zid-mt-2">
                      <GoogleCarousel />
                    </idk-div>
                  )}
                  {layout?.googleBadges?.showBadge &&
                    !layout?.googleBadges?.positionTop &&
                    isGoogle &&
                    layout?.galleryView !== 'carousel' && <GoogleBadgeDesign metaJson={data?.result?.metaJson} />}
                  {layout?.banners?.bannerBottom && (
                    <Banner src={layout?.banners?.bannerBottom} link={layout?.banners?.bannerBottomUrl} />
                  )}
                </idk-div>
              </idk-div>
            </idk-div>

            {layout?.galleryView !== 'carousel' && layout?.galleryView !== 'frame' && (
              <idk-div class="zid-flex zid-w-full zid-justify-center zid-mt-5">
                {layout?.galleryInformation?.pagination == 'loadMore' && page < totalPages && (
                  <>
                    {loader ? (
                      <ContentLoading />
                    ) : (
                      <button
                        onMouseOver={() => setLoadHover(true)}
                        onMouseOut={() => setLoadHover(false)}
                        className="zid-px-3 zid-py-2 zid-rounded-md zid-cursor-pointer"
                        onClick={handleLoadMoreCTA}
                        style={{
                          border: `1px solid ${headerColor}`,
                          color: loadHover ? 'white' : headerColor,
                          backgroundColor: loadHover ? headerColor : 'inherit',
                          fontSize: '16px',
                        }}
                      >
                        Load More
                      </button>
                    )}
                  </>
                )}
                {layout?.galleryInformation?.pagination == 'pageNumbers' && (
                  <PageNumber page={page} setPage={setPage} totalPages={totalPages} />
                )}
              </idk-div>
            )}
            {layout?.galleryInformation?.pagination == 'autoScroll' && loader && <ContentLoading />}
            <idk-div class="zid-flex zid-justify-end">
              {layout?.gallerySettings?.poweredBy && <PoweredBy guid={guid} />}
            </idk-div>
          </idk-div>
        )}
      </React.Fragment>
    );

  return <></>;
}

const ContentLoading = () => {
  return (
    <idk-div style={{ width: '100%', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={loadingImage} loading="lazy" />
    </idk-div>
  );
};
