import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleView from '../GoogleView';
import React, { useState, useRef, useEffect } from 'react';
import { UseWindowDimensions } from '../collage/utils';
import { convertHeightWidth } from '../../Helpers/Helpers';
import LinkedinView from '../LinkedinView';

const Premium1 = () => {
  const [myHeight, setMyHeight] = useState(0);
  const child1Ref = useRef(null);
  const {
    showModal,
    clickMutate,
    albumData,
    postSpacing,
    gridTemplateColumns,
    setAlbumId,
    isGoogle,
    isLinkedIn,
    layout,
  } = useDisplayWidgetContext();

  useEffect(() => {
    const containerElement = child1Ref.current;

    if (containerElement) {
      const childElements = Array.from(containerElement.children);

      childElements.sort((a, b) => {
        return b?.['clientHeight'] - a?.['clientHeight'];
      });

      let totalHeight = 0;
      for (let i = 0; i < Math.min(2, childElements.length); i++) {
        totalHeight += childElements[i]?.['clientHeight'];
      }

      setMyHeight(totalHeight + 10);
    }
  }, []);

  function calculateImageStyles(screenWidth) {
    const images = [
      {
        x: screenWidth * 1,
        y: screenWidth * 20,
        width: screenWidth * 22,
        height: screenWidth * 22,
      },
      {
        x: screenWidth * 25,
        y: screenWidth * 0,
        width: screenWidth * 42,
        height: screenWidth * 42,
      },
      {
        x: screenWidth * 69,
        y: screenWidth * 8,
        width: screenWidth * 19,
        height: screenWidth * 19,
      },
      {
        x: screenWidth * 19,
        y: screenWidth * 44,
        width: screenWidth * 26,
        height: screenWidth * 26,
      },
      {
        x: screenWidth * 47,
        y: screenWidth * 44,
        width: screenWidth * 20,
        height: screenWidth * 20,
      },
      {
        x: screenWidth * 69,
        y: screenWidth * 29,
        width: screenWidth * 30,
        height: screenWidth * 30,
      },
    ];

    const imagesWithStyles = images.map((image) => {
      const dynamicStyles = {
        transform: `translate(${image.x}px, ${image.y}px)`,
        width: `${image.width}px`,
        height: `${image.height}px`,
        borderRadius: '12%',
      };

      return {
        ...image,
        style: dynamicStyles,
      };
    });

    return imagesWithStyles;
  }

  const { width } = UseWindowDimensions();

  const imageData = calculateImageStyles(
    (convertHeightWidth(layout.gallerySettings.width, true) < 300 || width < 575.98
      ? 340
      : window.innerWidth < convertHeightWidth(layout.gallerySettings.width, true)
      ? window.innerWidth - 50
      : convertHeightWidth(layout.gallerySettings.width, true) - 50) / 100,
  );

  return (
    <React.Fragment>
      <idk-div
        style={{
          height: myHeight,
          minWidth: 300,
          maxWidth:
            convertHeightWidth(layout.gallerySettings.width, true) < 300 || width < 575.98
              ? 340
              : convertHeightWidth(layout.gallerySettings.width, true),
        }}
        class="zid-m-auto"
      >
        <idk-div class="zid-relative" ref={child1Ref}>
          {albumData?.length > 0 &&
            albumData.slice(0, 6).map((row: any, index: number) => {
              if (isGoogle) return <GoogleView key={index} data={row} />;
              if (isLinkedIn) return <LinkedinView key={index} data={row} />;
              return (
                <idk-div
                  key={index}
                  onClick={() => {
                    clickMutate(row.id);
                    showModal(row, 'slider');
                    setAlbumId(row?.id);
                  }}
                  style={{
                    transformOrigin: 'top-left',
                    height: layout.cardSettings.cardHeight,
                    borderRadius: layout.cardSettings.cardBorder,
                    ...imageData[index].style,
                  }}
                  class="zid-absolute zid-top-0 zid-left-0"
                >
                  <HotspotMedia
                    layout={layout}
                    row={row}
                    playFunctionality={false}
                    style={{ borderRadius: '5px' }}
                    autoPlay={layout?.videoSettings?.videoAutoPlay}
                  />
                </idk-div>
              );
            })}
        </idk-div>
      </idk-div>
    </React.Fragment>
  );
};

export default Premium1;
