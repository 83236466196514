export function calculatePercentage(percentage) {
  const value = (percentage / 100) * 950;
  return value;
}

export const convertHeightWidth = (value, premium) => {
  if (!value) {
    return 0;
  }
  if (typeof value == 'string') {
    const pattern = /(\d+(\.\d+)?)(%|px|em)?/;
    const match = value.match(pattern);
    const number = parseFloat(match[1]);
    if (value.includes('%') && premium) {
      return calculatePercentage(number);
    }
    return number;
  }
  return value;
};

export const hexToRgba = (hex, alpha) => {
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export function restructureCarouselAlbum(data) {
  const newData = [];
  for (let i = 0; i < data.length; i++) {
    const post = data[i];

    if (post.media_type === 'CAROUSEL_ALBUM' && post.children && post.children.data) {
      const childrenData = post.children.data;

      for (let j = 0; j < childrenData.length; j++) {
        const child = childrenData[j];
        const newPost = { ...post };
        newPost.media_url = child.media_url;
        newPost.media_type = child.media_type;
        delete newPost.children;
        newData.push(newPost);
      }
    } else {
      newData.push(post);
    }
  }
  return newData;
}

export function epochToRelativeDate(timestamp) {
  const dateObj = new Date(timestamp);
  const currentDate = new Date();
  const elapsed = currentDate.getTime() - dateObj.getTime();

  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years >= 1) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months >= 1) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days >= 1) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours >= 1) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes >= 1) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
  }
}

export const getCurrentSymbol = (currency, locale) => {
  let currencySymbol = new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency').value;
  if (currencySymbol === 'ZAR') {
    currencySymbol = 'R';
  }
  if (currencySymbol === 'USD') {
    currencySymbol = '$';
  }
  if (currencySymbol === 'EUR') {
    currencySymbol = '€';
  }
  if (currencySymbol === 'GBP') {
    currencySymbol = '£';
  }
  if (currencySymbol === 'JPY') {
    currencySymbol = '¥';
  }
  if (currencySymbol === 'AUD') {
    currencySymbol = 'A$';
  }
  if (currencySymbol === 'CAD') {
    currencySymbol = 'C$';
  }
  if (currencySymbol === 'NZD') {
    currencySymbol = 'NZ$';
  }
  if (currencySymbol === 'INR') {
    currencySymbol = '₹';
  }
  return currencySymbol + ' ';
};

export const calculatePrice = (price, rate) => {
  return price * rate;
};

export const PriceDisplay = ({ price }) => {
  if (price) {
    const [integerPart, fractionalPart] = parseFloat(price).toFixed(2).split('.');
    const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString();

    return (
      <span>
        <span>{formattedIntegerPart}</span>
        <span style={{ fontSize: '0.75em' }}>.{fractionalPart}</span>
      </span>
    );
  } else {
    return <></>;
  }
};
