import * as React from 'react';

const FlagIcon = ({ fill, ...props }) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.5 17.5C0.947715 17.5 0.5 17.0523 0.5 16.5V1.49999C0.5 0.947702 0.947715 0.499963 1.5 0.499963H7.88C8.26016 0.497342 8.60893 0.710483 8.78 1.04999L9.5 2.49999H14.5C15.0523 2.49999 15.5 2.9477 15.5 3.49999V11.5C15.5 12.0523 15.0523 12.5 14.5 12.5H9.11C8.73344 12.4988 8.38945 12.2863 8.22 11.95L7.5 10.5H2.5V16.5C2.5 17.0523 2.05228 17.5 1.5 17.5ZM2.5 2.49999V8.49999H8.5L9.5 10.5H13.5V4.49999H8.5L7.5 2.49999H2.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default FlagIcon;
