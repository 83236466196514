import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { IData, IView } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleRating from '../GoogleRating';

const Badge3 = ({ rating, reviews }) => {
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;
  const style = galleryLayout?.googleBadges;
  return (
    <idk-div
      style={{
        border: `${style?.border} solid #515A5A`,
        borderRadius: '4px',
        backgroundColor: style.bgColor,
        color: style.fontColor,
        maxWidth: 400,
        height: '100%',
        justifyContent: 'space-between',
      }}
      class="zid-flex zid-justify-center zid-flex-col"
    >
      <idk-div style={{ padding: '20px 15px' }} class="zid-flex zid-gap-2">
        <idk-div class="zid-my-auto">
          <idk-div style={{ padding: '5px', borderRadius: '8px' }}>
            <FcGoogle size={60} />
          </idk-div>
        </idk-div>
        <idk-div>
          <idk-div class="zid-my-auto">
            <GoogleRating rating={rating} size="35px" color={style.starColor} />
          </idk-div>
          <idk-div class="zid-flex zid-gap-2" style={{ alignItems: 'flex-end' }}>
            <idk-p class="zid-m-0 zid-my-auto" style={{ fontSize: '25px', fontWeight: 500 }}>
              {rating}
            </idk-p>
            <idk-p class="zid-m-0" style={{ fontSize: '16px', fontWeight: 500 }}>
              Based on {reviews} reviews
            </idk-p>
          </idk-div>
        </idk-div>
      </idk-div>
      <idk-div
        style={{ fontSize: '14px', color: 'white', backgroundColor: '#2E86C1', padding: '4px 0' }}
        class="zid-flex zid-justify-center"
      >
        Google Reviews
      </idk-div>
    </idk-div>
  );
};

export default Badge3;
