// export const currentEnv = process?.env?.REACT_APP_ENV as 'prod' | 'stage';
// export const BASE_API = process.env.REACT_APP_BASE_URL;

import EventBus from './common/EventBus';

const BASE_API_MAP = {
  prod: 'https://api.idukki.io',
  stage: 'https://api.idukki.io',
};
let staging = false;
EventBus.getInstance().addListener('isStaging', (e) => {
  staging = e;
  console.log('staging', staging);
});
export const API_BASE_URL = () => BASE_API_MAP[staging ? 'stage' : 'prod'];

export const grid = 'grid';
export const carousel = 'carousel';
export const collage = 'collage';
export const feed = 'feed';
export const wall1 = 'wall1';
export const wall2 = 'wall2';
export const masonry = 'masonry';
export const carousel3D = 'carousel3D';
export const expandableImage = 'expandable';
export const testimonial1 = 'testimonial1';
export const testimonial2 = 'testimonial2';
export const testimonial3 = 'testimonial3';
export const premium1 = 'premium1';
export const premium2 = 'premium2';
export const stories = 'stories';
export const story = 'story';
export const badge = 'badge';
export const frame = 'frame';
export const vertical = 'vertical';
export const specialGrid = 'specialGrid';

export const maxContainerWidth = '1000px';

export const googleAPI = 'AIzaSyCzX_zfLv45CojDrbJN6LpuDylJhN5VY44';

export const emptyImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/empty.svg';
export const halfStarImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/halfStar.svg';
export const Idukki_shortImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/Idukki-Short.png';
export const loadingImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/loading.svg';
export const notfoundImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/not-found.svg';
export const transparentLoderImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/transparentLoder.gif';
export const uploadImage = 'https://idukki-ext-staging.s3.us-east-2.amazonaws.com/upload.png';
