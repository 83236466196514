import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import HotspotMedia from '../../../common/ImageHotspot/HotspotMedia';
import PoweredBy from '../../../common/PoweredBy';
import CloseIcon from '../../../icons/CloseIcon';
import FlagIcon from '../../../icons/FlagIcon';
import { getSubscriptionData } from '../../../utils/sharedData';
import CustomModal from '../../CustomModal';
import { useDisplayWidgetContext } from '../../DisplayWidget/utils/context';
import { UseWindowDimensions } from '../../collage/utils';
import { IModal } from './interfaces';
import './modal.scss';
import ReadMoreReadLess from '../../../common/ReadMoreReadLess';
import { IoClose } from 'react-icons/io5';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import ShopifyModalRight from '../components/ShopifyModalRight';
import WocommerceModalRight from '../components/WocommerceModalRight';
import ProductsOverlay from '../components/ProductsOverlay';
import { MdOutlineClose } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { GoMute, GoUnmute } from 'react-icons/go';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { Navigation } from 'swiper/modules';
import { getCurrentSymbol, PriceDisplay } from '../../../Helpers/Helpers';

const Modal: React.FC<IModal> = ({ isOpen, onClose }) => {
  const {
    data,
    layout,
    setReportModalData,
    onReportModalOpen,
    albumData,
    albumId,
    isShopify,
    shopifyData,
    quantity,
    setQuantity,
    guid,
    isWooCommerceSite,
    fetchNextPost,
  } = useDisplayWidgetContext();
  const isFlag = layout?.gallerySettings?.flagInappropriate;
  const [index, setIndex] = React.useState(null);
  const [isHotspot, setIsHotspot] = React.useState(false);

  React.useEffect(() => {
    setIsHotspot(false);
  }, [isOpen, index]);

  React.useEffect(() => {
    albumData?.map((row, id) => {
      if (row?.id == albumId) setIndex(id);
    });
  }, [albumId]);

  const products = albumData[index]?.products;
  React.useEffect(() => {
    return () => {
      setQuantity(1);
    };
  }, []);

  const updateHeight = () => {
    const ht: number = window.innerHeight;
    const short_video_container = document.querySelector('.short_video_container');
    short_video_container['style']['height'] = ht + 'px';
  };

  const [isMuted, setIsMuted] = React.useState(true);

  const toggleMute = (index) => {
    const modalContainer = document.getElementById('idk_modal_container');
    const tempElement = modalContainer.querySelectorAll('.swiper-slide');
    const currentVideoElement = tempElement[index].getElementsByTagName('video');
    if (currentVideoElement.length > 0) {
      if (currentVideoElement[0].muted) {
        setIsMuted(false);
        currentVideoElement[0].muted = false;
      } else {
        setIsMuted(true);
        currentVideoElement[0].muted = true;
      }
    }
  };

  React.useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const handleSlide = (slider: any) => {
    const modalContainer = document.getElementById('idk_modal_container');
    const tempElement = modalContainer.querySelectorAll('.swiper-slide');
    const currentVideoElement = tempElement[slider.activeIndex].getElementsByTagName('video');
    const previousVideoElement = tempElement[slider.previousIndex].getElementsByTagName('video');

    if (currentVideoElement?.length > 0) {
      if (!isMuted) {
        currentVideoElement[0].muted = false;
      } else {
        currentVideoElement[0].muted = true;
      }
      currentVideoElement[0].currentTime = 0;
      currentVideoElement[0].play();
    }
    if (previousVideoElement?.length > 0) {
      previousVideoElement[0].pause();
    }
  };

  const { width } = UseWindowDimensions();
  if (!isOpen) return <></>;
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className="zid-bg-transparent">
      <idk-div class="zid-flex" style={{ flexDirection: 'row', cursor: 'pointer' }}>
        <idk-div
          class="zid-hidden md:zid-flex zid-items-center zid-justify-center zid-text-[30px] zid-text-[#fff] zid-bg-transparent"
          onClick={() => {
            if (index == 0) setIndex(albumData.length - 1);
            else setIndex(index - 1);
          }}
        >
          <MdArrowBackIos />
        </idk-div>
        <idk-div
          class="zid-justify-center zid-items-center zid-flex zid-inset-0"
          style={{ width: width <= 600 ? '100vw' : 'max-content', height: width <= 600 ? window.innerHeight : '90vh' }}
        >
          {width < 600 && index !== null ? (
            <idk-div class="short_video_container zid-w-[100vw]">
              <Swiper
                autoHeight={true}
                direction="vertical"
                onInit={() => {
                  updateHeight();
                }}
                initialSlide={index}
                onSlideChange={(slider) => {
                  updateHeight();
                  handleSlide(slider);
                  if (slider.isEnd) {
                    fetchNextPost();
                  }
                }}
                modules={[Navigation]}
                navigation={{
                  nextEl: '.image-swiper-button-next',
                  prevEl: '.image-swiper-button-prev',
                  disabledClass: 'swiper-button-disabled',
                }}
              >
                {albumData.map((mediaData: any, index: number) => {
                  return (
                    <SwiperSlide key={index} className="zid-h-full">
                      <idk-div class="zid-bg-white zid-w-full zid-h-full zid-rounded">
                        <idk-div class="zid-flex zid-flex-col sm:zid-flex-row zid-h-full zid-relative zid-border-2 zid-rounded">
                          <idk-div
                            class="zid-flex zid-w-full zid-h-full zid-bg-white"
                            style={{ flexDirection: width <= 600 ? 'column' : 'row' }}
                          >
                            <idk-div class="lg:zid-hidden zid-items-center zid-gap-2 zid-p-3 zid-border-b zid-border-b-slate-200 zid-hidden zid-md-flex">
                              <idk-div class="zid-h-8 zid-w-8 zid-bg-slate-300 zid-rounded-full zid-flex zid-items-center zid-justify-center zid-text-white">
                                {albumData[index]?.profilePic ? (
                                  <img
                                    src={albumData[index]?.profilePic}
                                    className="zid-h-full zid-w-full zid-rounded-full"
                                    loading="lazy"
                                  ></img>
                                ) : (
                                  albumData[index]?.username?.charAt(0)?.toUpperCase()
                                )}
                              </idk-div>
                              <idk-div class="zid-text-black">{albumData[index]?.username}</idk-div>
                            </idk-div>
                            <idk-div
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   e.stopPropagation();
                              //   window.open(albumData[index]?.permalink, '_blank');
                              // }}
                              class="zid-relative zid-h-full"
                              style={{ maxWidth: width <= 600 ? '100%' : '50%' }}
                            >
                              {mediaData.media_type == 'VIDEO' && (
                                <idk-div
                                  class="zid-bg-[#0000004d] zid-rounded zid-flex zid-items-center zid-justify-center zid-h-[35px] zid-w-[35px]"
                                  style={{ position: 'absolute', top: '13px', right: '8px', zIndex: 99999 }}
                                  onClick={() => toggleMute(index)}
                                >
                                  {isMuted ? (
                                    <GoMute fontSize="18px" color="#ffffff" />
                                  ) : (
                                    <GoUnmute fontSize="18px" color="#ffffff" />
                                  )}
                                </idk-div>
                              )}
                              <HotspotMedia
                                layout={layout}
                                isSwiper={true}
                                autoPlay={true}
                                playFunctionality={true}
                                row={albumData[index]}
                                isModal={true}
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                              <idk-div
                                class="zid-bg-[#0000004d] zid-rounded zid-flex zid-items-center zid-justify-center zid-h-[35px] zid-w-[35px]"
                                style={{ position: 'absolute', top: '13px', left: '8px', zIndex: 9999999 }}
                                onClick={() => onClose()}
                              >
                                <MdOutlineClose fontSize={18} color="#ffffff" />
                              </idk-div>

                              {width <= 600 && layout.gallerySettings.showProducts && (
                                <ProductsOverlay
                                  isOpen={isOpen}
                                  shopifyData={shopifyData}
                                  row={albumData[index]}
                                ></ProductsOverlay>
                              )}
                              {isFlag && (
                                <idk-div
                                  class="zid-absolute zid-bottom-4 zid-right-4 zid-p-1 zid-cursor-pointer zid-bg-gray-200 zid-rounded-md"
                                  style={{ border: '1px solid #1C2833' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setReportModalData(albumData[index]);
                                    onReportModalOpen();
                                  }}
                                >
                                  <FlagIcon width="20px" height="20px" fill="#1C2833" />
                                </idk-div>
                              )}
                            </idk-div>
                            {isShopify &&
                            layout?.gallerySettings?.showModalProducts &&
                            albumData[index]?.products?.length > 0 ? (
                              <idk-div
                                class="zid-hidden md:zid-flex zid-flex-col zid-justify-between"
                                style={{ width: '350px' }}
                              >
                                <ShopifyModalRight
                                  quantity={quantity}
                                  updateQuantity={setQuantity}
                                  row={albumData[index]}
                                  shopifyData={shopifyData}
                                />
                              </idk-div>
                            ) : isWooCommerceSite() &&
                              layout?.gallerySettings?.showModalProducts &&
                              albumData[index]?.products?.length > 0 ? (
                              <idk-div
                                class="zid-hidden md:zid-flex zid-flex-col zid-justify-between"
                                style={{ width: '350px' }}
                              >
                                <WocommerceModalRight
                                  quantity={quantity}
                                  updateQuantity={setQuantity}
                                  row={albumData[index]}
                                />
                              </idk-div>
                            ) : (
                              <idk-div class="zid-flex zid-flex-col zid-justify-between" style={{ width: '350px' }}>
                                <idk-div>
                                  <idk-div class="zid-hidden lg:zid-flex zid-items-center zid-gap-2 zid-p-4 zid-border-b zid-border-b-slate-200">
                                    <idk-div class="zid-h-8 zid-w-8 zid-bg-slate-300 zid-rounded-full zid-flex zid-items-center zid-justify-center zid-text-white">
                                      {albumData[index]?.profilePic ? (
                                        <img
                                          src={albumData[index]?.profilePic}
                                          className="zid-h-full zid-w-full zid-rounded-full"
                                          loading="lazy"
                                        ></img>
                                      ) : (
                                        albumData[index]?.username?.charAt(0)?.toUpperCase()
                                      )}
                                    </idk-div>
                                    <idk-div>{albumData[index]?.username}</idk-div>
                                  </idk-div>
                                  <idk-div
                                    class="zid-p-3 lg:zid-p-4 zid-text-slate-600 zid-hidden md:zid-block"
                                    style={{ height: 'calc(90vh - 153px)', overflow: 'auto' }}
                                  >
                                    <idk-div class="zid-text-sm">
                                      <ReadMoreReadLess totalWords={30}>{albumData[index]?.caption}</ReadMoreReadLess>
                                    </idk-div>
                                    {!isHotspot &&
                                      products &&
                                      layout?.gallerySettings?.showModalProducts &&
                                      products?.length > 0 && (
                                        <idk-div class="zid-w-[100%] zid-overflow-auto">
                                          <ModalHeader
                                            modalData={albumData[index]}
                                            closeModal={onClose}
                                            show={!isHotspot}
                                            isWooCommerceSite={isWooCommerceSite}
                                            currency={data.result.currency}
                                            shopifyData={shopifyData}
                                            layout={layout}
                                          />
                                        </idk-div>
                                      )}
                                  </idk-div>
                                </idk-div>
                                <idk-div class="zid-hidden md:zid-flex zid-items-center zid-justify-between zid-border-t zid-border-t-slate-200 zid-p-3 lg:zid-p-4">
                                  <idk-div>
                                    <idk-div class="zid-flex zid-mb-2 zid-gap-4">
                                      <idk-div class="zid-flex zid-gap-1 zid-items-center">
                                        <img
                                          src="https://assets.idukki.io/widget-assets/HeartOutlineFill.svg"
                                          loading="lazy"
                                          height="20px"
                                          width="20px"
                                        />
                                        <idk-div>{albumData[index]?.like_count}</idk-div>
                                      </idk-div>
                                      <idk-div class="zid-flex zid-gap-1 zid-items-center">
                                        <img
                                          src="https://assets.idukki.io/widget-assets/CommentOutline.svg"
                                          loading="lazy"
                                          height="20px"
                                          width="20px"
                                        />
                                        <idk-div>{albumData[index]?.comments_count}</idk-div>
                                      </idk-div>
                                    </idk-div>
                                    <idk-p class="zid-text-slate-600 zid-text-sm">
                                      {(window as any).moment &&
                                        albumData[index]?.timestamp &&
                                        (window as any).moment(albumData[index]?.timestamp).fromNow()}
                                    </idk-p>
                                  </idk-div>
                                  <idk-div class="zid-flex zid-flex-col zid-items-end">
                                    <idk-div class="zid-text-sky-800 hover:zid-underline zid-flex zid-gap-2">
                                      {albumData[index]?.source == 'Youtube' && (
                                        <img
                                          height={25}
                                          width={25}
                                          src="https://assets.idukki.io/widget-assets/YoutubeLogo.svg"
                                          loading="lazy"
                                        ></img>
                                      )}
                                      {albumData[index]?.source == 'Shorts' && (
                                        <img
                                          height={25}
                                          width={25}
                                          src="https://assets.idukki.io/widget-assets/ShortsLogo.svg"
                                          loading="lazy"
                                        ></img>
                                      )}
                                      {albumData[index]?.source == 'Instagram_business' && (
                                        <img
                                          height={25}
                                          width={25}
                                          src="https://assets.idukki.io/widget-assets/InstagramLogo.svg"
                                          loading="lazy"
                                        ></img>
                                      )}
                                      {albumData[index]?.source == 'Twitter' && (
                                        <img
                                          height={25}
                                          width={25}
                                          src="https://assets.idukki.io/widget-assets/XTwitterLogo.svg"
                                          loading="lazy"
                                        ></img>
                                      )}

                                      <a href={albumData[index]?.permalink} target="_blank">
                                        View Post
                                      </a>
                                    </idk-div>
                                    {layout?.gallerySettings?.poweredBy && (
                                      <PoweredBy onPost={true} guid={guid} className="zid-p-0 zid-mr-0" />
                                    )}
                                  </idk-div>
                                </idk-div>
                              </idk-div>
                            )}
                          </idk-div>
                        </idk-div>
                      </idk-div>
                      <idk-div
                        class="zid-absolute zid-z-[100] zid-flex zid-gap-3 zid-flex-col zid-right-[10px]"
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      >
                        <idk-div
                          style={{ backdropFilter: 'blur(10px)' }}
                          class="image-swiper-button-prev zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                        >
                          <MdKeyboardArrowUp color="#fff" fontSize={30} />
                        </idk-div>
                        <idk-div
                          style={{ backdropFilter: 'blur(10px)' }}
                          class="image-swiper-button-next zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                        >
                          <MdKeyboardArrowDown color="#fff" fontSize={30} />
                        </idk-div>
                      </idk-div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </idk-div>
          ) : (
            <idk-div class="zid-bg-white zid-w-full zid-h-full zid-rounded">
              <idk-div class="zid-flex zid-flex-col sm:zid-flex-row zid-h-full zid-relative zid-border-2 zid-rounded">
                <IoClose
                  fontSize={24}
                  color="#000"
                  className="zid-absolute zid-right-2.5	zid-top-2.5	zid-pointer"
                  onClick={() => onClose()}
                />
                <idk-div
                  class="zid-flex zid-w-full zid-h-full zid-bg-white"
                  style={{ flexDirection: width <= 600 ? 'column' : 'row' }}
                >
                  <idk-div class="lg:zid-hidden zid-items-center zid-gap-2 zid-p-3 zid-border-b zid-border-b-slate-200 zid-hidden zid-md-flex">
                    <idk-div class="zid-h-8 zid-w-8 zid-bg-slate-300 zid-rounded-full zid-flex zid-items-center zid-justify-center zid-text-white">
                      {albumData[index]?.profilePic ? (
                        <img
                          src={albumData[index]?.profilePic}
                          className="zid-h-full zid-w-full zid-rounded-full"
                          loading="lazy"
                        ></img>
                      ) : (
                        albumData[index]?.username?.charAt(0)?.toUpperCase()
                      )}
                    </idk-div>
                    <idk-div class="zid-text-black">{albumData[index]?.username}</idk-div>
                  </idk-div>
                  <idk-div class="zid-relative zid-h-full">
                    <HotspotMedia
                      layout={layout}
                      autoPlay={true}
                      showMute={true}
                      playFunctionality={true}
                      row={albumData[index]}
                      isModal={true}
                      style={{
                        height: '100%',
                        width: width <= 600 ? '100%' : '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <idk-div
                      class="zid-bg-[#0000004d] zid-rounded zid-flex md:zid-hidden zid-items-center zid-justify-center zid-h-[30px] zid-w-[30px]"
                      style={{ position: 'absolute', top: '13px', left: '8px' }}
                      onClick={() => onClose()}
                    >
                      <MdOutlineClose fontSize={18} color="#ffffff" />
                    </idk-div>

                    {width <= 600 && layout.gallerySettings.showProducts && (
                      <ProductsOverlay
                        isOpen={isOpen}
                        shopifyData={shopifyData}
                        row={albumData[index]}
                      ></ProductsOverlay>
                    )}
                    {isFlag && (
                      <idk-div
                        class="zid-absolute zid-bottom-4 zid-right-4 zid-p-1 zid-cursor-pointer zid-bg-gray-200 zid-rounded-md"
                        style={{ border: '1px solid #1C2833' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setReportModalData(albumData[index]);
                          onReportModalOpen();
                        }}
                      >
                        <FlagIcon width="20px" height="20px" fill="#1C2833" />
                      </idk-div>
                    )}
                  </idk-div>
                  {isShopify && layout?.gallerySettings?.showModalProducts && albumData[index]?.products?.length > 0 ? (
                    <idk-div class="zid-hidden md:zid-flex zid-flex-col zid-justify-between" style={{ width: '350px' }}>
                      <ShopifyModalRight
                        quantity={quantity}
                        updateQuantity={setQuantity}
                        row={albumData[index]}
                        shopifyData={shopifyData}
                      />
                    </idk-div>
                  ) : isWooCommerceSite() && albumData[index]?.products?.length > 0 ? (
                    <idk-div class="zid-hidden md:zid-flex zid-flex-col zid-justify-between" style={{ width: '350px' }}>
                      <WocommerceModalRight quantity={quantity} updateQuantity={setQuantity} row={albumData[index]} />
                    </idk-div>
                  ) : (
                    <idk-div class="zid-flex zid-flex-col zid-justify-between" style={{ width: '350px' }}>
                      <idk-div>
                        <idk-div class="zid-hidden lg:zid-flex zid-items-center zid-gap-2 zid-p-4 zid-border-b zid-border-b-slate-200">
                          <idk-div class="zid-h-8 zid-w-8 zid-bg-slate-300 zid-rounded-full zid-flex zid-items-center zid-justify-center zid-text-white">
                            {albumData[index]?.profilePic ? (
                              <img
                                src={albumData[index]?.profilePic}
                                className="zid-h-full zid-w-full zid-rounded-full"
                                loading="lazy"
                              ></img>
                            ) : (
                              albumData[index]?.username?.charAt(0)?.toUpperCase()
                            )}
                          </idk-div>
                          <idk-div>{albumData[index]?.username}</idk-div>
                        </idk-div>
                        <idk-div
                          class="zid-p-3 lg:zid-p-4 zid-text-slate-600 zid-hidden md:zid-block"
                          style={{ height: 'calc(90vh - 153px)', overflow: 'auto' }}
                        >
                          <idk-div class="zid-text-sm">
                            <ReadMoreReadLess totalWords={30}>{albumData[index]?.caption}</ReadMoreReadLess>
                          </idk-div>
                          {!isHotspot &&
                            products &&
                            layout?.gallerySettings?.showModalProducts &&
                            products?.length > 0 && (
                              <idk-div class="zid-w-[100%] zid-overflow-auto">
                                <ModalHeader
                                  modalData={albumData[index]}
                                  closeModal={onClose}
                                  show={!isHotspot}
                                  currency={data?.result?.currency}
                                  isWooCommerceSite={isWooCommerceSite}
                                  shopifyData={shopifyData}
                                  layout={layout}
                                />
                              </idk-div>
                            )}
                        </idk-div>
                      </idk-div>
                      <idk-div class="zid-hidden md:zid-flex zid-items-center zid-justify-between zid-border-t zid-border-t-slate-200 zid-p-3 lg:zid-p-4">
                        <idk-div>
                          <idk-div class="zid-flex zid-mb-2 zid-gap-4">
                            <idk-div class="zid-flex zid-gap-1 zid-items-center">
                              <img
                                src="https://assets.idukki.io/widget-assets/HeartOutlineFill.svg"
                                height="20px"
                                width="20px"
                                loading="lazy"
                              />
                              <idk-div>{albumData[index]?.like_count}</idk-div>
                            </idk-div>
                            <idk-div class="zid-flex zid-gap-1 zid-items-center">
                              <img
                                src="https://assets.idukki.io/widget-assets/CommentOutline.svg"
                                height="20px"
                                width="20px"
                                loading="lazy"
                              />
                              <idk-div>{albumData[index]?.comments_count}</idk-div>
                            </idk-div>
                          </idk-div>
                          <idk-p class="zid-text-slate-600 zid-text-sm">
                            {(window as any).moment &&
                              albumData[index]?.timestamp &&
                              (window as any).moment(albumData[index]?.timestamp).fromNow()}
                          </idk-p>
                        </idk-div>
                        <idk-div class="zid-flex zid-flex-col zid-items-end">
                          <idk-div class="zid-text-sky-800 hover:zid-underline zid-flex zid-gap-2">
                            {albumData[index]?.source == 'Youtube' && (
                              <img
                                height={25}
                                width={25}
                                src="https://assets.idukki.io/widget-assets/YoutubeLogo.svg"
                                loading="lazy"
                              ></img>
                            )}
                            {albumData[index]?.source == 'Shorts' && (
                              <img
                                height={25}
                                width={25}
                                src="https://assets.idukki.io/widget-assets/ShortsLogo.svg"
                                loading="lazy"
                              ></img>
                            )}
                            {albumData[index]?.source == 'Instagram_business' && (
                              <img
                                height={25}
                                width={25}
                                src="https://assets.idukki.io/widget-assets/InstagramLogo.svg"
                                loading="lazy"
                              ></img>
                            )}
                            {albumData[index]?.source == 'Twitter' && (
                              <img
                                height={25}
                                width={25}
                                src="https://assets.idukki.io/widget-assets/XTwitterLogo.svg"
                                loading="lazy"
                              ></img>
                            )}

                            <a href={albumData[index]?.permalink} target="_blank">
                              View Post
                            </a>
                          </idk-div>
                          {layout?.gallerySettings?.poweredBy && (
                            <PoweredBy onPost={true} guid={guid} className="zid-p-0 zid-mr-0" />
                          )}
                        </idk-div>
                      </idk-div>
                    </idk-div>
                  )}
                </idk-div>
              </idk-div>
            </idk-div>
          )}
        </idk-div>
        <idk-div
          class="zid-hidden md:zid-flex zid-items-center zid-text-[30px] zid-text-[#fff] zid-bg-transparent zid-min-w-[25px]"
          style={{ flexDirection: 'row', cursor: 'pointer' }}
          onClick={() => {
            if (index == albumData.length - 1) setIndex(0);
            else setIndex(index + 1);
          }}
        >
          <MdArrowForwardIos />
        </idk-div>
      </idk-div>
    </CustomModal>
  );
};

const ModalHeader = ({ modalData, closeModal, show, currency, isWooCommerceSite, shopifyData, layout }) => {
  const amountFormatter = new Intl.NumberFormat();

  const ShopBtnComponent = ({ product }) => {
    return (
      <>
        <style>{`
        .idk_shop_now_btn {
        ${layout?.productsSettings?.shopBtnCss}
        }
        `}</style>
        <idk-div
          onClick={() =>
            window.open(product?.product_url ? product.product_url : 'https://app.idukki.io/register', '_blank')
          }
          class="zid-rounded-md zid-bg-blue-600 zid-px-4 zid-py-1 zid-text-[12px] zid-text-white zid-cursor-pointer idk_shop_now_btn"
        >
          {layout?.productsSettings?.shopBtnText}
        </idk-div>
      </>
    );
  };

  return (
    <idk-div class="zid-flex zid-flex-col zid-justify-between zid-overflow-x-hidden zid-overflow-auto zid-w-full">
      <idk-div class="zid-flex zid-flex-col">
        <idk-div class="zid-w-full zid-flex zid-justify-end zid-absolute" style={{ top: '1%', right: '1%' }}>
          <idk-div
            class="zid-cursor-pointer zid-fixed zid-p-1 zid-bg-white zid-rounded-md"
            onClick={() => closeModal()}
          >
            <CloseIcon />
          </idk-div>
        </idk-div>

        <idk-div class="zid-w-full zid-h-[20px]" />
        {show && (
          <idk-div class="zid-flex zid-flex-col zid-text-gray-700 zid-text-[18px] zid-gap-4 zid-h-full">
            <idk-div class="zid-flex zid-justify-between zid-pr-[50px]">
              <idk-div class="zid-flex zid-gap-2">
                {modalData?.logo && (
                  <idk-div class="zid-border-2 zid-border-solid zid-h-[40px] zid-w-[40px] zid-overflow-hidden zid-rounded-[999px]">
                    <img src={modalData?.logo} className="zid-w-full" loading="lazy" />
                  </idk-div>
                )}
                {modalData?.product_name && (
                  <idk-div class="zid-flex zid-gap-[1px] zid-flex-col zid-text-[14px] zid-leading-[14px] zid-justify-center">
                    <idk-p>{modalData?.product_name}</idk-p>
                  </idk-div>
                )}
              </idk-div>
            </idk-div>

            <idk-div class="zid-flex zid-gap-4 zid-flex-wrap zid-w-full zid-flex-col zid-text-[12px] zid-break-words">
              <idk-p>{modalData?.description}</idk-p>
              <idk-div class="zid-flex zid-flex-col zid-w-full zid-gap-4">
                <>
                  {modalData?.products?.map((product, id1) => {
                    return (
                      <idk-div key={id1} class="zid-flex zid-w-full zid-justify-between zid-gap-2">
                        {product?.images && (
                          <idk-div
                            class=" zid-rounded zid-flex zid-items-center zid-white"
                            style={{ width: '80px', height: '80px', overflow: 'hidden', flex: '0 0 80px' }}
                          >
                            <img
                              src={product?.images[0]?.image_url}
                              className="zid-w-full zid-h-full zid-object-contain"
                              loading="lazy"
                            />
                          </idk-div>
                        )}
                        <idk-div
                          class="zid-flex zid-gap-[10px] zid-text-[12px] zid-flex-col zid-overflow-hidden"
                          style={{ flexGrow: 1 }}
                        >
                          <idk-p class="zid-font-bold">{product?.title}</idk-p>
                          {product?.description.trim() && layout?.productsSettings?.showProductDescription && (
                            <idk-p dangerouslySetInnerHTML={{ __html: product?.description }}></idk-p>
                          )}
                          {product?.variants && (
                            <idk-p class="zid-mt-1 zid-p-0 zid-mb-0" style={{ fontWeight: 500, fontSize: '12px' }}>
                              {currency &&
                                getCurrentSymbol(currency, navigator?.language || navigator['userLanguage'])?.replace(
                                  'US',
                                  '',
                                )}
                              {isWooCommerceSite() &&
                                !currency &&
                                document?.getElementsByClassName('woocommerce-Price-currencySymbol')?.[0]?.innerHTML}
                              {shopifyData?.currency?.active &&
                                !currency &&
                                getCurrentSymbol(shopifyData?.currency?.active, shopifyData?.locale)}
                              <span className="zid-font-bold">
                                <PriceDisplay price={product?.variants?.[0]?.price} />
                              </span>
                            </idk-p>
                          )}
                          <idk-div class="zid-flex  zid-items-center zid-mt-1">
                            <ShopBtnComponent product={product} />
                          </idk-div>
                        </idk-div>
                      </idk-div>
                    );
                  })}
                </>
              </idk-div>
            </idk-div>
          </idk-div>
        )}
      </idk-div>
    </idk-div>
  );
};

export default Modal;
