import React from 'react';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import Cover from '../Cover';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import ReadMoreReadLess from '../../common/ReadMoreReadLess';

export default function MasonryBox({ colData }) {
  const { showModal, clickMutate, setAlbumId, layout } = useDisplayWidgetContext();
  return (
    <idk-div
      class={`zid-flex zid-items-center zid-justify-center`}
      onClick={() => {
        clickMutate(colData.id);
      }}
    >
      <idk-div
        class="zid-overflow-hidden zid-w-[100%] zid-bg-white"
        style={{ borderRadius: layout?.cardSettings?.cardBorder }}
      >
        <idk-div
          class={`commonImageContainer zid-overflow-hidden zid-relative zid-flex-1 zid-justify-center zid-items-center`}
        >
          <idk-div
            onClick={() => {
              showModal(colData, 'slider');
              setAlbumId(colData.id);
            }}
          >
            <HotspotMedia
              layout={layout}
              row={colData}
              playFunctionality={false}
              autoPlay={layout?.videoSettings?.videoAutoPlay}
            />
          </idk-div>
          {/* <Cover
            onClick={() => {
              showModal(colData, 'slider');
              setAlbumId(colData.id);
            }}
            row={colData}
          /> */}
        </idk-div>
        <idk-div class="zid-bg-white zid-w-[100%] zid-text-[rgba(0,0,0,0.87)] zid-p-4 zid-pb-0 zid-flex zid-flex-col zid-justify-center zid-items-center zid-rounded-[15px] zid-z-10 zid-translate-y-[-25px] zid-relative zid-z-[9999]">
          {colData?.profilePic && (
            <idk-div class="zid-overflow-hidden zid-w-[70px] zid-h-[70px] zid-absolute zid-top-[-20px] zid-z-20 zid-border-[2px] zid-border-[#f5f5f5] zid-rounded-lg">
              <img width="100%" height={'100%'} src={colData?.profilePic} loading="lazy" />
            </idk-div>
          )}
          {colData?.username && <idk-p class="zid-mt-10 zid-text-sm">{colData?.username}</idk-p>}
          <idk-div>
            {colData?.product_name && <h5 className="zid-text-[24px] zid-text-center">{colData?.product_name}</h5>}
            <idk-div class="zid-my-4">
              <ReadMoreReadLess totalWords={30}>{colData?.caption}</ReadMoreReadLess>
            </idk-div>
            {colData?.timestamp && (
              <idk-p class="zid-text-[14px] zid-text-center">
                {(window as any).moment &&
                  colData?.timestamp &&
                  (window as any).moment(colData?.timestamp).format('MMM DD, YYYY')}
              </idk-p>
            )}
          </idk-div>
        </idk-div>
      </idk-div>
    </idk-div>
  );
}
