import axios from 'axios';
import { API_BASE_URL } from '../contants';

async function fetch(userId, ids, guid): Promise<any> {
  const endpoint = API_BASE_URL() + `/analytics/postviews/${ids}?userId=${userId}&guid=${guid}`;
  return await axios.get(endpoint).then(({ data }) => data as any);
}

export default function usePostView(userId: string, guid: string, isRedis: any) {
  const mutate = (postIds: string[]) => {
    const ids = postIds.join(',');
    if (!isRedis) {
      fetch(userId, ids, guid);
    }
  };

  return { mutate };
}
