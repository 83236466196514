import React, { useState, Fragment } from 'react';

export default function ReadMoreReadLess({ totalWords, children }) {
  const [readMore, setReadMore] = useState(true);

  const handleReadMore = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setReadMore(!readMore);
  };

  return (
    children && (
      <Fragment>
        {totalWords && readMore ? (
          <Fragment>
            {children?.split(' ')?.slice(0, totalWords).join(' ')}
            {children?.split(' ')?.length > parseInt(totalWords) && (
              <idk-div class="zid-text-right zid-mt-4">
                <a
                  className="zid-text-blue-500 zid-cursor-pointer zid-text-xs zid-px-3 zid-py-1 zid-border zid-border-blue-500 zid-rounded-full"
                  onClick={handleReadMore}
                >
                  Read More
                </a>
              </idk-div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {children}{' '}
            <idk-div class="zid-text-right zid-mt-4">
              {children?.split(' ')?.length > parseInt(totalWords) && (
                <a
                  className="zid-text-blue-500  zid-cursor-pointer zid-text-xs zid-px-3 zid-py-1 zid-border zid-border-blue-500 zid-rounded-full"
                  onClick={handleReadMore}
                >
                  Read Less
                </a>
              )}
            </idk-div>
          </Fragment>
        )}
      </Fragment>
    )
  );
}
