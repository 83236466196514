import React from 'react';
import Modal from 'react-responsive-modal';
import ReactDOM from 'react-dom';

interface IProp {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export default function CustomModal({ children, isOpen, onClose, className = '' }: IProp) {
  return ReactDOM.createPortal(
    <React.Fragment>
      <style>{`.swiper{ height: 100%; width: 100%}
      `}</style>
      {/* <style>{`
      .customOverlay {
        background: rgba(36, 123, 160, 0.7);
      }
      .customModal {
        width: 100%;
        padding:0
      }`}</style>
      <Modal
        open={isOpen}
        onClose={onClose}
        center
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
      > */}
      <idk-div
        class={` zid-w-[100vw] zid-h-[100vh] zid-top-0 zid-left-0 zid-fixed zid-opacity-[0.5] zid-bg-gray-900 ${
          isOpen ? 'zid-inherit' : 'zid-hidden'
        }`}
        style={{ zIndex: 9999999999 }}
        onClick={onClose}
      />

      <idk-div
        id="idk_modal_container"
        class={`idukki-modal-portal zid-flex zid-flex-col zid-opacity-[1] zid-rounded-md zid-overflow-hidden zid-justify-center zid-items-center zid-fixed zid-top-[50%] zid-left-[50%] ${
          isOpen ? 'zid-inherit' : 'zid-hidden'
        }  ${className} `}
        style={{ zIndex: 9999999999, transform: 'translate(-50%,-50%)' }}
      >
        {children}
      </idk-div>
      {/* </Modal> */}
    </React.Fragment>,
    document.body,
  );
}
