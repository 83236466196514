import React from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { useGuidPageContext } from './utils/context';
import { MdPreview } from 'react-icons/md';
import { IoMdOpen } from 'react-icons/io';
export default function GuidPageView() {
  const { data } = useGuidPageContext();
  if (data)
    return (
      <idk-div class="zid-flex zid-flex-col zid-gap-6 zid-p-[20px] zid-items-center">
        <idk-p class="zid-text-[24px]">List of guids</idk-p>
        <idk-div>
          <button
            onClick={() => {
              window.open('https://app.idukki.io/dashboard/ugc-flow/create-feeds', '_blank');
            }}
            type="button"
            className="zid-text-white zid-bg-gray-800 zid-hover:bg-gray-900 zid-focus:outline-none zid-focus:ring-4 zid-focus:ring-gray-300 zid-font-medium zid-rounded-lg zid-text-sm zid-px-5 zid-py-2.5 zid-mr-2 zid-mb-2 zid-dark:bg-gray-800 zid-dark:hover:bg-gray-700 zid-dark:focus:ring-gray-700 zid-dark:border-gray-700"
          >
            Add Collection
          </button>
        </idk-div>
        <idk-div class="zid-relative zid-overflow-x-auto zid-shadow-md zid-sm:rounded-lg zid-w-full ">
          <table className="zid-w-full zid-text-sm zid-text-left zid-text-gray-500 zid-dark:text-gray-400">
            <thead className="zid-text-xs zid-text-gray-700 zid-uppercase zid-bg-gray-50 zid-dark:bg-gray-700 zid-dark:text-gray-400">
              <tr>
                <th scope="col" className="zid-px-6 zid-py-3">
                  Collection
                </th>
                <th scope="col" className="zid-px-6 zid-py-3">
                  Copy Code
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, id) => (
                <tr key={id} className="zid-bg-white zid-border-b zid-dark:bg-gray-900 zid-dark:border-gray-700">
                  <th
                    scope="row"
                    className="zid-px-6 zid-py-4 zid-font-medium zid-text-gray-900 zid-whitespace-nowrap zid-dark:text-white"
                  >
                    <idk-div
                      class="zid-cursor-pointer zid-flex zid-items-center zid-gap-2"
                      onClick={() => window.open(`https://widget.idukki.io?guid=${row?.guid}`)}
                    >
                      <MdPreview size={30} color="#141414" />
                      <idk-div>
                        <idk-div class="zid-flex zid-items-center zid-gap-2">
                          <idk-p class="zid-text-[18px]"> {row?.name}</idk-p>
                          <IoMdOpen size={20} />
                        </idk-div>
                        <idk-p class="zid-text-[#757575] zid-text-[12px]">{row?.guid}</idk-p>
                      </idk-div>
                    </idk-div>
                  </th>
                  <td
                    className="zid-px-6 zid-py-4 zid-mx-auto zid-cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(row?.guid);
                    }}
                  >
                    <AiOutlineCopy size={20} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </idk-div>
      </idk-div>
    );
  return <></>;
}
