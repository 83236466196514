import React, { useState, useMemo } from 'react';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaInstagram, FaTiktok, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { SiTrustpilot } from 'react-icons/si';
import { FaXTwitter } from 'react-icons/fa6';
import axios from 'axios';
import { API_BASE_URL } from '../../contants';
function SpecialGrid() {
  const { layout, albumData, fetchNextPost, clickMutate, data, guid, showModal, setAlbumId } =
    useDisplayWidgetContext();
  const [activeTab, setActiveTab] = React.useState('all');
  const categoryList = useMemo(() => data?.result?.metaJson?.[0], [data]);

  const [page, setPage] = React.useState({
    currentPage: 1,
    totalPages: data?.totalPage || 1,
  });
  const [collectionData, setCollectionData] = useState(null);

  const handleTabChange = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      fetchPostData(tab);
    }
  };

  const fetchPostData = (tab) => {
    let url = API_BASE_URL() + `/widget/${guid}?page=${page.currentPage}`;
    if (tab && tab !== 'all') {
      url += `&category=${tab}`;
    }
    try {
      axios.get(url).then((res) => {
        if (data.status === true) {
          setCollectionData(res.data);
          setPage({ currentPage: 1, totalPages: res.data.totalPage });
        } else {
          setCollectionData(null);
          console.log('Error fetching post data:', res.data);
        }
      });
    } catch (e) {
      setCollectionData(null);
    }
  };

  const SourceIcon = ({ socialUrl }) => {
    console.log(socialUrl);
    if (socialUrl.toLowerCase().includes('instagram')) {
      return <FaInstagram className="icon_shadow" fontSize={22} />;
    }
    if (socialUrl.toLowerCase().includes('tiktok')) {
      return <FaTiktok className="icon_shadow" fontSize={22} />;
    }
    if (socialUrl.toLowerCase().includes('twitter')) {
      return <FaXTwitter className="icon_shadow" fontSize={22} />;
    }
    if (socialUrl.toLowerCase().includes('linkedin')) {
      return <FaLinkedin className="icon_shadow" fontSize={22} />;
    }
    if (socialUrl.toLowerCase().includes('youtube')) {
      return <FaYoutube className="icon_shadow" fontSize={22} />;
    }
    if (socialUrl.toLowerCase().includes('trustpilot')) {
      return <SiTrustpilot className="icon_shadow" fontSize={22} />;
    }
    return '';
  };

  const memoizedTemplate = useMemo(() => {
    const Template = ({ albumData }) => {
      return (
        <idk-div class="special_grid_container">
          {albumData?.length > 0 && (
            <idk-div
              class="special_small_img"
              onClick={() => {
                clickMutate(albumData[0].id);
                showModal(albumData[0], 'slider');
                setAlbumId(albumData[0].id);
              }}
            >
              <idk-div class="text_shadow zid-flex zid-gap-1 zid-items-center zid-absolute zid-text-[12px] zid-top-[15px] zid-text-white zid-font-bold zid-left-[15px] zid-z-10">
                <SourceIcon socialUrl={albumData[0].permalink} />
                {albumData[0]?.userid ? `@${albumData[0].userid}` : albumData[0]?.username}
              </idk-div>
              <HotspotMedia
                layout={layout}
                row={albumData[0]}
                playFunctionality={false}
                autoPlay={layout?.videoSettings?.videoAutoPlay}
              />
            </idk-div>
          )}
          {albumData?.length > 1 && (
            <idk-div
              class="special_small_img"
              onClick={() => {
                clickMutate(albumData[1].id);
                showModal(albumData[1], 'slider');
                setAlbumId(albumData[1].id);
              }}
            >
              <idk-div class="text_shadow zid-flex zid-gap-1 zid-items-center zid-absolute zid-text-[12px] zid-top-[15px] zid-text-white zid-font-bold zid-left-[15px] zid-z-10">
                <SourceIcon socialUrl={albumData[1].permalink} />
                {albumData[1]?.userid ? `@${albumData[1].userid}` : albumData[1]?.username}
              </idk-div>
              <HotspotMedia
                layout={layout}
                row={albumData[1]}
                playFunctionality={false}
                autoPlay={layout?.videoSettings?.videoAutoPlay}
              />
            </idk-div>
          )}
          {albumData?.length > 2 && (
            <idk-div
              class="special_big_img"
              onClick={() => {
                clickMutate(albumData[2].id);
                showModal(albumData[2], 'slider');
                setAlbumId(albumData[2].id);
              }}
            >
              <idk-div class="text_shadow zid-flex zid-gap-1 zid-items-center zid-absolute zid-text-[12px] zid-top-[15px] zid-text-white zid-font-bold zid-left-[15px] zid-z-10">
                <SourceIcon socialUrl={albumData[2].permalink} />
                {albumData[2]?.userid ? `@${albumData[2].userid}` : albumData[2]?.username}
              </idk-div>
              <HotspotMedia
                layout={layout}
                row={albumData[2]}
                playFunctionality={false}
                autoPlay={layout?.videoSettings?.videoAutoPlay}
              />
            </idk-div>
          )}
          {albumData?.length > 3 && (
            <idk-div
              class="special_small_img"
              onClick={() => {
                clickMutate(albumData[3].id);
                showModal(albumData[3], 'slider');
                setAlbumId(albumData[3].id);
              }}
            >
              <idk-div class="text_shadow zid-flex zid-gap-1 zid-items-center zid-absolute zid-text-[12px] zid-top-[15px] zid-text-white zid-font-bold zid-left-[15px] zid-z-10">
                <SourceIcon socialUrl={albumData[3].permalink} />
                {albumData[3]?.userid ? `@${albumData[3].userid}` : albumData[3]?.username}
              </idk-div>
              <HotspotMedia
                layout={layout}
                row={albumData[3]}
                playFunctionality={false}
                autoPlay={layout?.videoSettings?.videoAutoPlay}
              />
            </idk-div>
          )}
          {albumData?.length > 4 && (
            <idk-div
              class="special_small_img"
              onClick={() => {
                clickMutate(albumData[4].id);
                showModal(albumData[4], 'slider');
                setAlbumId(albumData[4].id);
              }}
            >
              <idk-div class="text_shadow zid-flex zid-gap-1 zid-items-center zid-absolute zid-text-[12px] zid-top-[15px] zid-text-white zid-font-bold zid-left-[15px] zid-z-10">
                <SourceIcon socialUrl={albumData[4].permalink} />
                {albumData[4]?.userid ? `@${albumData[4].userid}` : albumData[4]?.username}
              </idk-div>
              <HotspotMedia
                layout={layout}
                row={albumData[4]}
                playFunctionality={false}
                autoPlay={layout?.videoSettings?.videoAutoPlay}
              />
            </idk-div>
          )}
          {albumData?.length > 5 &&
            albumData.slice(5).map((media, index) => {
              return (
                <idk-div
                  class="special_small_img"
                  key={index}
                  onClick={() => {
                    clickMutate(media.id);
                    showModal(media, 'slider');
                    setAlbumId(media.id);
                  }}
                >
                  <idk-div class="text_shadow zid-flex zid-gap-1 zid-items-center zid-absolute zid-text-[12px] zid-top-[15px] zid-text-white zid-font-bold zid-left-[15px] zid-z-10">
                    <SourceIcon socialUrl={media.permalink} />
                    {media?.userid ? `@${media.userid}` : media?.username}
                  </idk-div>
                  <HotspotMedia
                    layout={layout}
                    row={media}
                    playFunctionality={false}
                    autoPlay={layout?.videoSettings?.videoAutoPlay}
                  />
                </idk-div>
              );
            })}
        </idk-div>
      );
    };
    return Template;
  }, [layout, collectionData?.result?.data]);

  const Template = memoizedTemplate;

  return (
    <React.Fragment>
      <style>{`
        .special_grid_container {
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr)); 
        grid-auto-rows:1fr;
        width: 100%;
        }
        .special_small_img  {
          position: relative;
          height:100%;
          width:100%;
          padding: 10px;
          aspect-ratio: 1/1;
          transform: scale(0.8);
          transform-origin: 0% 0%;
          animation: scaleUp 1s ease-in-out forwards;
          cursor: pointer;
        }
        .special_big_img {
          position: relative;
          padding: 10px;
          height:100%;
          width:100%;
          grid-column: span 2;
          grid-row: span 2;
          aspect-ratio: 1/1;
           transform: scale(0.8);
          transform-origin: 0% 0%;
          animation: scaleUp 1s ease-in-out forwards;
           cursor: pointer;
        }
        .text_shadow {
          text-shadow: rgb(0 0 0 / 30%)  0px 0 2px;
        }
        .icon_shadow{
        filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 30%) )
        }
         .category_btn.active {
          border: 1.5px solid #cbcbcb;
        }
        .category_btn:hover {
        scale: 1.1
        }

        @keyframes scaleUp {
          from {
            transform: scale(0.8);
          }
          to {
            transform: scale(1);
          }
        }
          .hide_scroll::-webkit-scrollbar {
            display: none;
          }
        @media only screen and (max-width: 767.98px) {
          .special_grid_container {
        grid-template-columns: repeat(2, minmax(150px, 1fr)); 
        width: 100%;
        }
        .special_small_img  {
          position: relative;
          height:100%;
          width:100%;
          padding: 10px
        }
        .special_big_img {
          position: relative;
          padding: 10px;
          height:100%;
          width:100%;
          grid-column: span 2;
          grid-row: span 2;
        }
        }
        `}</style>
      {categoryList?.length > 0 && (
        <idk-div class="zid-flex zid-gap-3 hide_scroll" style={{ padding: '10px', overflowX: 'auto' }}>
          <button
            onClick={() => handleTabChange('all')}
            className={`category_btn ${activeTab == 'all' ? 'active' : ''}`}
            style={{
              fontWeight: '700',
              textTransform: 'capitalize',
              padding: '10px 20px',
              borderRadius: '30px',
              cursor: 'pointer',
              minWidth: 'fit-content',
            }}
          >
            All
          </button>
          {categoryList.map((category, index) => {
            return (
              <button
                onClick={() => handleTabChange(category)}
                className={`category_btn ${activeTab == category ? 'active' : ''}`}
                style={{
                  fontWeight: '700',
                  textTransform: 'capitalize',
                  padding: '10px 20px',
                  borderRadius: '30px',
                  cursor: 'pointer',
                  minWidth: 'fit-content',
                }}
                key={index}
              >
                {category}
              </button>
            );
          })}
        </idk-div>
      )}
      <InfiniteScroll
        dataLength={albumData.length}
        loader={<></>}
        next={fetchNextPost}
        hasMore={true}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        scrollableTarget="scrollable"
      >
        {collectionData?.result?.data ? (
          collectionData.result.data.length === 0 ? (
            <idk-div class="zid-text-center zid-m-4">No Post Available for the selected category</idk-div>
          ) : (
            <Template albumData={collectionData.result.data} />
          )
        ) : (
          albumData?.length > 5 && <Template albumData={albumData} />
        )}
      </InfiniteScroll>
    </React.Fragment>
  );
}

export default React.memo(SpecialGrid);
