// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import Cover from '../Cover';
import { IData } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleBadgeDesign from '../GoogleBadgeDesign';
import GoogleView from '../GoogleView';
import { UseWindowDimensions } from '../collage/utils';
import ProductsOverlay from '../Modals/components/ProductsOverlay';
import LinkedinView from '../LinkedinView';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const CarouselView = () => {
  const { showModal, albumData, clickMutate, setAlbumId, isGoogle, layout, shopifyData, isLinkedIn } =
    useDisplayWidgetContext();
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryData = data?.result?.layout;
  const column = galleryData.galleryInformation.columns;
  const width = parseInt(column);

  const responsive = {
    mobile: {
      breakpoint: { max: 40000, min: 0 },
      items: width,
    },
    desktop: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
    super: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  const { width: screenWidth } = UseWindowDimensions();
  const mobileView = screenWidth < 500;

  if (isGoogle) {
    return (
      <React.Fragment>
        <style>{`
      .idk-carousel-item{
        padding: 0 ${parseInt(layout?.galleryInformation?.postSpacing) / 2}px;
      }`}</style>
        <idk-div
          style={{
            gap: `${layout?.galleryInformation?.postSpacing}px`,
          }}
        >
          {isGoogle && (
            <idk-div
              style={{
                flexGrow: 1,
                height: '100%',
              }}
            >
              <GoogleBadgeDesign metaJson={data?.result?.metaJson} />
            </idk-div>
          )}
          <idk-div
            style={{
              maxWidth:
                isGoogle && layout?.googleBadges?.showBadge
                  ? !mobileView
                    ? `calc(100vw - 350px)`
                    : `calc(100vw - 32px)`
                  : '100vw',
              height: '100%',
            }}
          >
            <Carousel
              responsive={responsive}
              containerClass={'carousel-multi-container'}
              autoPlay={galleryData.sliderSettings.autoPlay}
              autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
              customLeftArrow={
                <LeftArrow
                  arrowColor={layout?.sliderSettings?.arrowColor}
                  arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
                />
              }
              customRightArrow={
                <RightArrow
                  arrowColor={layout?.sliderSettings?.arrowColor}
                  arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
                />
              }
              itemClass="idk-carousel-item"
            >
              {albumData.map((row, id) => {
                return (
                  <idk-div
                    key={id}
                    class="zid-flex zid-items-center zid-justify-center"
                    style={{
                      height: '100%',
                      flexGrow: 1,
                      borderRadius: layout?.cardSettings?.cardBorder,
                    }}
                  >
                    <GoogleView data={row} />
                  </idk-div>
                );
              })}
            </Carousel>
          </idk-div>
        </idk-div>
      </React.Fragment>
    );
  }

  if (isLinkedIn) {
    return (
      <React.Fragment>
        <style>{`
      .idk-carousel-item{
        padding: 0 ${parseInt(layout?.galleryInformation?.postSpacing) / 2}px;
      }`}</style>
        <idk-div
          style={{
            gap: `${layout?.galleryInformation?.postSpacing}px`,
          }}
        >
          <idk-div
            style={{
              maxWidth:
                isGoogle && layout?.googleBadges?.showBadge
                  ? !mobileView
                    ? `calc(100vw - 350px)`
                    : `calc(100vw - 32px)`
                  : '100vw',
              height: '100%',
            }}
          >
            <Carousel
              responsive={responsive}
              containerClass={'carousel-multi-container'}
              autoPlay={galleryData.sliderSettings.autoPlay}
              autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
              customLeftArrow={
                <LeftArrow
                  arrowColor={layout?.sliderSettings?.arrowColor}
                  arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
                />
              }
              customRightArrow={
                <RightArrow
                  arrowColor={layout?.sliderSettings?.arrowColor}
                  arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
                />
              }
              itemClass="idk-carousel-item"
            >
              {albumData.map((row, id) => {
                return (
                  <idk-div
                    key={id}
                    class="zid-flex zid-items-center zid-justify-center"
                    style={{
                      height: '100%',
                      flexGrow: 1,
                      borderRadius: layout?.cardSettings?.cardBorder,
                    }}
                  >
                    <LinkedinView data={row} />
                  </idk-div>
                );
              })}
            </Carousel>
          </idk-div>
        </idk-div>
      </React.Fragment>
    );
  }

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <>
      <style>
        {`.swiper-container {
                width: 100%;
                padding: 0 10px;
              }

              .swiper-slide {
                transition: transform 0.5s ease-in-out;
              }

              .swiper-slide.center {
                z-index: 1;
                transition: transform 0.5s ease-in-out;
              }

              .swiper-slide:not(.center) {
                opacity: ${!layout?.sliderSettings?.specialCarousel ? '1' : '0.7'};
                transform: ${!layout?.sliderSettings?.specialCarousel ? 'scale(1)' : 'scale(0.8)'};
                transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
              }
              }`}
      </style>

      <div className="swiper-container">
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          spaceBetween={!layout?.sliderSettings?.specialCarousel ? 20 : 0}
          centeredSlides={true}
          slidesPerView={1}
          autoplay={{
            delay: galleryData.sliderSettings.autoPlaySpeed,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={false}
          onSlideChange={handleSlideChange}
          onInit={(swiper) => setActiveIndex(swiper.realIndex)}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
            disabledClass: 'swiper-button-disabled',
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: layout?.galleryInformation?.columns || 3,
            },
          }}
        >
          {albumData.map((row: any, id: number) => (
            <SwiperSlide
              style={{
                borderRadius: layout?.cardSettings?.cardBorder,
              }}
              key={id}
              className={`zid-relative carousel-item ${
                layout?.sliderSettings?.specialCarousel && activeIndex === id ? 'center' : ''
              }`}
            >
              <idk-div
                class="carousel-item-inner"
                onClick={() => {
                  showModal(row, 'slider');
                  setAlbumId(row.id);
                  clickMutate(row.id);
                }}
                style={{
                  borderRadius: layout?.cardSettings?.cardBorder,
                  height: window.innerWidth < 576.98 ? '100%' : galleryData.cardSettings.cardHeight,
                  border: `1px solid ${layout?.cardSettings?.cardBorderColor}`,
                }}
              >
                <HotspotMedia
                  row={row}
                  playFunctionality={false}
                  autoPlay={id < activeIndex + 3 ? layout?.videoSettings?.videoAutoPlay : false}
                  preload={id < activeIndex + 3 ? 'auto' : 'none'}
                  layout={layout}
                  style={{ borderRadius: layout?.cardSettings?.cardBorder }}
                />
                {row?.products?.length > 0 && layout.gallerySettings.showProducts && (
                  <ProductsOverlay shopifyData={shopifyData} row={row} isOpen={true}></ProductsOverlay>
                )}
              </idk-div>
              {layout?.sliderSettings?.specialCarousel && (
                <idk-div
                  class="zid-absolute zid-z-[100] zid-flex zid-gap-3 zid-flex-col zid-right-[10px]"
                  style={{ top: '50%', transform: 'translateY(-50%)' }}
                >
                  <idk-div
                    style={{ backdropFilter: 'blur(10px)' }}
                    class="image-swiper-button-prev zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                  >
                    <MdKeyboardArrowUp color="#fff" fontSize={30} />
                  </idk-div>
                  <idk-div
                    style={{ backdropFilter: 'blur(10px)' }}
                    class="image-swiper-button-next zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                  >
                    <MdKeyboardArrowDown color="#fff" fontSize={30} />
                  </idk-div>
                </idk-div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {!layout?.sliderSettings?.specialCarousel && (
          <>
            <idk-div
              class="zid-absolute zid-z-[100] zid-flex zid-gap-3 zid-flex-col zid-left-[25px]"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <idk-div
                style={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgb(0, 0, 0)' }}
                class="image-swiper-button-prev zid-cursor-pointer zid-bg-[#090a0b33] zid-opacity-70 zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
              >
                <MdKeyboardArrowLeft color="#fff" fontSize={30} />
              </idk-div>
            </idk-div>
            <idk-div
              class="zid-absolute zid-z-[100] zid-flex zid-gap-3 zid-flex-col zid-right-[25px]"
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <idk-div
                style={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgb(0, 0, 0)' }}
                class="image-swiper-button-next zid-cursor-pointer zid-bg-[#090a0b33] zid-opacity-70 zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
              >
                <MdKeyboardArrowRight color="#fff" fontSize={30} />
              </idk-div>
            </idk-div>
          </>
        )}
      </div>
    </>
  );

  return (
    <React.Fragment>
      <style>{`
      .idk-carousel-item{
        padding: 0 ${parseInt(layout?.galleryInformation?.postSpacing) / 2}px;
      }`}</style>
      <idk-div class="zid-mx-auto">
        <Carousel
          responsive={responsive}
          containerClass={'carousel-multi-container'}
          autoPlay={galleryData.sliderSettings.autoPlay}
          infinite={true}
          autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
          customLeftArrow={
            <LeftArrow
              arrowColor={layout?.sliderSettings?.arrowColor}
              arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
            />
          }
          customRightArrow={
            <RightArrow
              arrowColor={layout?.sliderSettings?.arrowColor}
              arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
            />
          }
          itemClass="idk-carousel-item"
        >
          {albumData.map((row, id) => {
            return (
              <idk-div
                class={`zid-w-[100%] zid-flex zid-items-center zid-justify-center zid-h-full`}
                key={id}
                onClick={() => {
                  showModal(row, 'slider');
                  setAlbumId(row.id);
                  clickMutate(row.id);
                }}
                style={{
                  borderRadius: layout?.cardSettings?.cardBorder,
                }}
              >
                <idk-div
                  class="zid-relative zid-bg-white zid-rounded zid-overflow-hidden zid-rounded-lg zid-relative zid-w-full zid-h-full"
                  style={{ height: window.innerWidth < 576.98 ? '100%' : galleryData.cardSettings.cardHeight }}
                  onClick={() => {
                    // showModal(row, 'slider');
                    // setAlbumId(id);
                  }}
                >
                  {/* {isShopify && row.products.length > 0 && (
                  <idk-div class="">
                    <img src=""></img>
                    <div dangerouslySetInnerHTML={{ __html: row?.products?.[0]?.description }}></div>
                  </idk-div>
                )} */}
                  <HotspotMedia
                    row={row}
                    playFunctionality={false}
                    autoPlay={layout?.videoSettings?.videoAutoPlay}
                    layout={layout}
                  />
                  {row?.products?.length > 0 && layout.gallerySettings.showProducts && (
                    <ProductsOverlay shopifyData={shopifyData} row={row} isOpen={true}></ProductsOverlay>
                  )}

                  {/* <Cover
                    onClick={() => {
                      showModal(row, 'slider');
                      setAlbumId(row.id);
                      clickMutate(row.id);
                    }}
                    row={row}
                  /> */}
                </idk-div>
              </idk-div>
            );
          })}
        </Carousel>
      </idk-div>
    </React.Fragment>
  );
};

export function LeftArrow({ ...props }) {
  const { onClick } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == 0) return <></>;

  return (
    <idk-div
      onClick={onClick}
      style={{
        backgroundColor: props.arrowBackgroundColor,
      }}
      class={`zid-absolute zid-translate-y-[-50%] zid-top-[50%] zid-translate-x-[20px] zid-cursor-pointer zid-rounded-full zid-opacity-[0.7] zid-h-[36px] zid-w-[36px] zid-flex zid-items-center zid-justify-center ${props?.className}`}
    >
      <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px" fill={props.arrowColor}>
        <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
      </svg>
    </idk-div>
  );
}

export function RightArrow({ ...props }) {
  const { onClick, totalSlides } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == totalSlides - 1) return <></>;
  return (
    <idk-div
      onClick={onClick}
      style={{
        backgroundColor: props.arrowBackgroundColor,
      }}
      class={`zid-absolute zid-translate-y-[-50%] zid-right-0 zid-top-[50%] zid-translate-x-[-20px] zid-z-[100] zid-cursor-pointer zid-rounded-[99px] zid-opacity-[0.7] zid-h-[36px] zid-w-[36px] zid-flex zid-items-center zid-justify-center ${props?.className}`}
    >
      <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px" fill={props.arrowColor}>
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg>
    </idk-div>
  );
}

export default CarouselView;
