import axios from 'axios';
import React from 'react';
import { API_BASE_URL } from '../../../contants';

export default function useShopifyData(setGetSubsError, oguid) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const fetch = () => {
    setIsLoading(true);
    if (oguid) {
      const endpoint = API_BASE_URL() + '/shopify/order/detail/' + oguid;
      axios
        .get(endpoint)
        .then(({ data }) => {
          setIsLoading(false);
          setData(data?.data);
        })
        .catch((err) => {
          setIsLoading(false);
          const { response } = err;
          setGetSubsError(response?.data?.message);
        });
    }
  };
  React.useEffect(() => {
    fetch();
  }, []);
  return { isLoading, data };
}
