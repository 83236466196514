import HotspotMedia from '../common/ImageHotspot/HotspotMedia';
import Cover from './Cover';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';
import React from 'react';
import Carousel3D from 'react-spring-3d-carousel';
import LinkedinView from './LinkedinView';

const Carousel3d = () => {
  let key = 0;
  const { showModal, clickMutate, albumData, setAlbumId, layout, fetchNextPost, isLinkedIn } =
    useDisplayWidgetContext();
  const [slideIndex, setSlideIndex] = React.useState(0);
  const convertMedia = (media: any) => {
    const convertedMedia: any = [];
    media?.map((data, id) => {
      if (data?.media_type == 'CAROUSEL_ALBUM') {
        data?.children?.data?.map((row) => {
          convertedMedia.push({
            key: key,
            content: (
              <idk-div
                class={`commonImageContainer zid-w-full zid-h-full zid-overflow-hidden zid-relative ${
                  data?.media_type == 'CAROUSEL_ALBUM' ? '' : 'zid-flex zid-items-center zid-justify-center'
                }`}
                onClick={() => {
                  clickMutate(data.id);
                }}
                style={{
                  margin: 'auto auto',
                  borderRadius: layout?.cardSettings?.cardBorder,
                  width: '400px',
                  objectFit: 'contain',
                }}
              >
                <idk-div
                  onClick={() => {
                    if (id == slideIndex) {
                      showModal(data, 'slider');
                      setAlbumId(data?.id);
                    }
                  }}
                >
                  {isLinkedIn ? (
                    <LinkedinView data={data} key={id} />
                  ) : (
                    <HotspotMedia
                      row={row}
                      style={{ width: '100%', objectFit: 'cover' }}
                      playFunctionality={false}
                      layout={layout}
                    />
                  )}
                </idk-div>
                {/* <Cover
                  onClick={() => {
                    if (id == slideIndex) {
                      showModal(data, 'slider');
                      setAlbumId(data?.id);
                    }
                  }}
                  row={data}
                /> */}
              </idk-div>
            ),
          });
          key += 1;
        });
      } else {
        convertedMedia.push({
          key: key,
          content: (
            <idk-div
              class={`commonImageContainer zid-w-full zid-h-full  zid-overflow-hidden zid-relative ${
                data?.media_type == 'CAROUSEL_ALBUM' ? '' : 'zid-flex zid-items-center zid-justify-center'
              }`}
              onClick={() => {
                clickMutate(data.id);
                if (id == slideIndex) {
                  showModal(data, 'slider');
                  setAlbumId(data?.id);
                }
              }}
              style={{
                margin: 'auto auto',
                borderRadius: layout?.cardSettings?.cardBorder,
                maxWidth: '60vw',
                width: '400px',
              }}
            >
              {isLinkedIn ? (
                <LinkedinView data={data} key={id} />
              ) : (
                <HotspotMedia
                  row={data}
                  style={{ width: '100%', objectFit: 'cover' }}
                  playFunctionality={false}
                  autoPlay={layout?.videoSettings?.videoAutoPlay}
                  layout={layout}
                />
              )}
              {/* <Cover
                onClick={() => {
                  if (id == slideIndex) {
                    showModal(data, 'slider');
                    setAlbumId(data?.id);
                  }
                }}
                row={data}
              /> */}
            </idk-div>
          ),
        });
        key += 1;
      }
    });
    return convertedMedia.map((slide, index) => {
      return {
        ...slide,
        onClick: () => {
          if (convertedMedia.length - 2 == index) {
            fetchNextPost();
          }
          setSlideIndex(index);
        },
      };
    });
  };

  return (
    <idk-div
      class="zid-flex zid-justify-center zid-items-center zid-overflow-hidden"
      style={{ height: layout?.cardSettings?.cardHeight, margin: '0 auto' }}
    >
      <Carousel3D
        slides={convertMedia(albumData)}
        goToSlide={slideIndex}
        showNavigation={false}
        offsetRadius={2}
        animationConfig={{ tension: 120, friction: 14 }}
      />
    </idk-div>
  );
};

export default Carousel3d;
