import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { IData, IView } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleRating from '../GoogleRating';
const Badge2 = ({ rating, reviews }) => {
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;
  const style = galleryLayout?.googleBadges;
  return (
    <idk-div
      style={{
        border: `${style?.border} solid #515A5A`,
        padding: '30px 20px',
        borderRadius: '4px',
        backgroundColor: style.bgColor,
        color: style.fontColor,
        maxWidth: 400,
        height: '100%',
      }}
      class="zid-flex zid-flex-col zid-justify-center zid-items-center zid-gap-3"
    >
      <idk-div class="zid-mx-auto">
        <idk-div style={{ padding: '5px', borderRadius: '8px' }}>
          <FcGoogle size={100} />
        </idk-div>
      </idk-div>
      <idk-div>
        <idk-p class="zid-m-0" style={{ fontSize: '25px', fontWeight: 600, textAlign: 'center' }}>
          Google Reviews
        </idk-p>
        <idk-p
          class="zid-m-0 zid-mx-auto"
          style={{ fontSize: '60px', fontWeight: 500, textAlign: 'center', lineHeight: '60px' }}
        >
          {rating}
        </idk-p>
      </idk-div>
      <idk-div>
        <GoogleRating rating={rating} size={'40px'} color={style.starColor} />
        <idk-p class="zid-m-0" style={{ fontSize: '20px', fontWeight: 500, textAlign: 'center' }}>
          {reviews} reviews
        </idk-p>
      </idk-div>
    </idk-div>
  );
};

export default Badge2;
