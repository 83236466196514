import axios from 'axios';
import React, { useEffect } from 'react';
import { BiError } from 'react-icons/bi';
import { useSpeechRecognition } from 'react-speech-recognition';
import Loader from '../../common/Loader';
import useGetBusiness from '../../hooks/useGetBusiness';
import useNotifyUpload from '../../hooks/useNotifyUpload';
import useUploadMedia from '../../hooks/useUploadMedia';
import { validateEmail } from '../../utils';
import UploadMediaView from './UploadMedia.view';
import SucessModal from './components/SuccessModal';
import useShopifyData from './hooks/useShopifyData';
import { UploadMediaContext } from './utils/context';
import EventBus from '../../common/EventBus';
import GenerateMeta from './components/GenerateMeta';

function setDocumentTitle(title: string) {
  document.title = title;
}

function setFavicon(faviconUrl: string) {
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    favicon['href'] = faviconUrl;
  } else {
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'icon';
    newFavicon.href = faviconUrl;
    document.head.appendChild(newFavicon);
  }
}

export default function UploadMediaContainer({ uploadMediaClose = null, bguid, modalRef = null, oguid = null }) {
  setDocumentTitle('Collect UGC');

  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [getSubsError, setGetSubsError] = React.useState('');
  const [rating, setRating] = React.useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const query_name = urlParams.get('name');
  const query_email = urlParams.get('email');
  const guid = urlParams.get('guid');
  const { data: businessData, isLoading: isBusinessLoading } = useGetBusiness(setGetSubsError, bguid);
  const { data: shopifyData } = useShopifyData(setGetSubsError, oguid);
  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);
  const ref4 = React.useRef(null);
  const ref5 = React.useRef(null);
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const onSuccessClose = () => {
    setIsSuccessOpen(false);
  };
  const onSuccessOpen = () => {
    setIsSuccessOpen(true);
  };

  const [error, setError] = React.useState<string>('');
  const [files, setFiles] = React.useState([]);

  const handleDelete = (image) => {
    let allFiles = [...files];
    allFiles = allFiles.filter((file) => {
      return file.name !== image.name;
    });
    setFiles(allFiles);
  };

  const handleChange = (e: any) => {
    if (modalRef) {
      modalRef.current.scrollTo({ top: ref3?.current?.offsetTop, behavior: 'smooth' });
    } else {
      window.scrollTo(0, ref3?.current?.offsetTop);
    }
    const dropFiles = e;
    dropFiles.map((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'png' ||
        (businessData?.plan?.videosAllowed && (fileExtension === 'mp4' || fileExtension === 'mov'))
      ) {
        setFiles((prevState) => [...prevState, file]);
        setError('');
      } else {
        let errorMessage = 'Invalid file format. Supported formats are: JPG, JPEG, PNG';

        if (businessData?.plan?.videosAllowed) {
          errorMessage += ', MP4, MOV';
        }

        setError(errorMessage);
      }
    });
  };

  const [captcha, setCaptcha] = React.useState({ value: '', error: '' });
  const [id, setId] = React.useState('');
  const { mutate, isLoading, data } = useUploadMedia(setError, setUploadLoading, setId);
  const [email, setEmail] = React.useState({
    value: query_email ? query_email : '',
    error: query_email ? (validateEmail(query_email) ? '' : 'Invalid Email') : '',
  });
  const [name, setName] = React.useState(query_name ? query_name : '');
  const [comment, setComment] = React.useState('');
  const { mutate: notifyUploadMutate } = useNotifyUpload();

  React.useEffect(() => {
    if (shopifyData) {
      if (!name) {
        setName(shopifyData?.customer_first_name + ' ' + shopifyData?.customer_last_name);
      }
      if (!email.value) {
        setEmail({
          value: shopifyData?.customer_email ? shopifyData?.customer_email : '',
          error: shopifyData?.customer_email ? (validateEmail(shopifyData?.customer_email) ? '' : 'Invalid Email') : '',
        });
      }
    }
  }, [shopifyData]);

  const reset = () => {
    setEmail({ value: '', error: '' });
    setCaptcha({ value: '', error: '' });
    setName('');
    setComment('');
    setFiles([]);
    setRating(0);
    EventBus.getInstance().fireEvent('resetCaptcha');
  };

  React.useEffect(() => {
    if (!isLoading && data) {
      const links = data;
      for (let i = 0; i < links.length; i++) {
        axios
          .put(links[i].uploadURL, files[i])
          .then(() => {
            if (i == links.length - 1) {
              onSuccessOpen();
              notifyUploadMutate({ id: id });
            }
          })
          .catch(() => {
            console.log('Something went wrong', 'Error');
            // toastr.error('Something went wrong', 'Error');
          })
          .finally(() => {
            setUploadLoading(false);
            reset();
          });
      }
    }
  }, [data]);

  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  const businessLogoUrl = shopifyData ? shopifyData?.storeLogo : businessData?.business?.storeLogo;
  const businessName = shopifyData ? shopifyData?.name : businessData?.business?.name;

  useEffect(() => {
    if (businessLogoUrl) {
      setFavicon(businessLogoUrl);
    }
  }, [shopifyData]);

  const handleSubmit = () => {
    if (!uploadLoading && name && email && comment && files?.length > 0) {
      if (!validateEmail(email?.value)) setEmail({ ...email, error: 'Invalid Email' });
      else if (captcha.value == '') setCaptcha({ ...captcha, error: 'Verify Captcha' });
      else {
        setUploadLoading(true);
        let filesSize = 0;
        const payloadFiles = [];
        files.map((row) => {
          payloadFiles.push({
            contentType: row.type,
            filename: row.name,
          });
          filesSize += row.size / 1024 / 1024;
        });
        const payload = {
          files: payloadFiles,
          bid: businessData ? businessData?.subscription?.businessId : '',
          totalSizeOfMediaPayload: filesSize,
          name: name,
          email: email?.value,
          comment: comment || transcript,
          rating: rating,
          guid: guid ? guid : '',
          captchToken: captcha.value,
          oguid: oguid ? oguid : '',
        };

        mutate(payload);
      }
    }
  };

  if (getSubsError || (!oguid && !bguid)) {
    return (
      <idk-div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          padding: '60px',
        }}
      >
        <idk-div class="zid-flex zid-flex-col zid-gap-2 zid-justify-center zid-items-center">
          <BiError size={40} />
          <idk-p class="zid-text-center">{getSubsError ? getSubsError : 'Oguid/Bguid Missing'}</idk-p>
        </idk-div>
      </idk-div>
    );
  }

  if (!businessData && !shopifyData) {
    return (
      <idk-div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          padding: '60px',
        }}
      >
        <Loader show={true} />
      </idk-div>
    );
  }

  const ogProperties = [
    { name: 'og:image', content: 'https://idukki.io/image of review form.jpg' },
    { name: 'og:image:type', content: 'image/jpg' },
    { name: 'og:image:width', content: '240' },
    { name: 'og:image:height', content: '240' },
    { name: 'og:image:alt', content: 'Display Picture' },
  ];

  return (
    <UploadMediaContext.Provider
      value={{
        uploadMediaClose,
        setUploadLoading,
        uploadLoading,
        onSuccessOpen,
        businessData,
        isBusinessLoading,
        getSubsError,
        rating,
        setRating,
        handleDelete,
        handleChange,
        error,
        files,
        captcha,
        setCaptcha,
        email,
        setEmail,
        name,
        setName,
        comment,
        setComment,
        handleSubmit,
        isLoading,
        transcript,
        listening,
        resetTranscript,
        query_email,
        query_name,
        modalRef,
        ref1,
        ref2,
        ref3,
        ref4,
        ref5,
        businessLogoUrl,
        businessName,
        shopifyData,
      }}
    >
      <GenerateMeta ogProperties={ogProperties} />
      <UploadMediaView guid={guid} oguid={oguid} />
      {isSuccessOpen && <SucessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />}
    </UploadMediaContext.Provider>
  );
}
