import React from 'react';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';

interface IProp {
  children: React.ReactNode;
  style?: any;
}

export default function TextTruncate({ children, style = {} }: IProp) {
  const { layout } = useDisplayWidgetContext();
  return (
    <idk-p
      class="truncate"
      style={{
        wordBreak: 'break-word',
        fontSize: `${layout?.cardSettings?.cardTextSize}px`,
        color: `${layout?.cardSettings?.cardTextColor}`,
        textAlign: `${layout?.cardSettings?.cardTextAlign}`,
        ...style,
      }}
    >
      {children}
    </idk-p>
  );
}
