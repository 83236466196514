import React from 'react';
import { BsPlus } from 'react-icons/bs';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import TagProductBox from '../../components/Modals/TagProductBox';
import { UseWindowDimensions } from '../../components/collage/utils';
import './ImageHotspot.scss';

export default function ImageHotspot({
  getClickedHotspot = null,
  hotspots,
  src,
  id,
  style = {},
  alt = '',
  layout,
}: {
  getClickedHotspot?: any;
  hotspots: { x: number; y: number; content: React.ReactNode }[] | [];
  src: string;
  id: number | string;
  style?: object;
  alt?: string;
  layout?: any;
}) {
  const [open, setOpen] = React.useState(-1);
  const hotSpotid = `image-hotspot-${src}-${id}-${new Date().getMilliseconds()}`;

  const getCords = (event) => {
    setOpen(-1);
    const ele = document.getElementById(hotSpotid);
    const topPos = ele.getBoundingClientRect().top + window.scrollY;
    const leftPos = ele.getBoundingClientRect().left + window.scrollX;
    const x = ((event.pageX - leftPos) / ele.offsetWidth) * 100;
    const y = ((event.pageY - topPos) / ele.offsetHeight) * 100;
    if (getClickedHotspot)
      getClickedHotspot({
        x: parseFloat(x.toFixed(1)),
        y: parseFloat(y.toFixed(1)),
      });
  };

  return (
    <idk-div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        background: 'white',
        ...style,
      }}
      onClick={getCords}
      class="circle-hotspot"
    >
      <img
        src={src}
        alt={alt}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          ...style,
        }}
        loading="lazy"
        id={hotSpotid}
      />
      {layout?.gallerySettings?.showHotspotProducts &&
        hotspots?.map((row: any) => {
          const hotspotId = row?.i ? row?.i : 0;
          const index = row?.id_from_product;
          if (hotspotId == id) {
            return (
              <idk-div key={index}>
                <Popover
                  isOpen={open == index}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={'#fff'}
                      arrowSize={6}
                      className="popover-arrow-container"
                      arrowClassName="popover-arrow"
                    >
                      <TagProductBox data={row.data} />
                    </ArrowContainer>
                  )}
                >
                  <idk-div
                    class="zid-flex zid-items-center zid-justify-center"
                    style={{
                      position: 'absolute',
                      top: `calc(${row.y}% - 9px)`,
                      left: `calc(${row.x}% - 9px)`,
                      zIndex: 10,
                      cursor: 'pointer',
                      width: '18px',
                      height: '18px',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (open != index) setOpen(index);
                      else setOpen(-1);
                    }}
                  >
                    <idk-div class="pulsating-circle">
                      <BsPlus
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transition: 'all 0.4s',
                          transform:
                            open == index ? 'translate(-50%,-50%) rotate(120deg)' : 'translate(-50%,-50%) rotate(0deg)',
                          zIndex: 100,
                        }}
                      />
                    </idk-div>
                  </idk-div>
                </Popover>
              </idk-div>
            );
          }
          return null;
        })}
    </idk-div>
  );
}
