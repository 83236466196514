// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';
import Carousel from 'react-multi-carousel';
import { LeftArrow, RightArrow } from './carousel/carousel';
import { UseWindowDimensions } from './collage/utils';
import { VideoPlayer } from './VideoPlayer/VideoPlayer';

export default function Media({
  row,
  album = false,
  isModal = false,
  alt = '',
  playFunctionality,
  autoPlay,
  preload,
  showMute,
  ...props
}: any) {
  const { width } = UseWindowDimensions();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  if (row?.media_type == 'IMAGE' || (album == true && row?.media_type == 'CAROUSEL_ALBUM')) {
    return (
      <img
        loading="lazy"
        src={row.media_url}
        alt={alt}
        {...props}
        style={{ ...props?.style, width: '100%', margin: 'auto auto', objectFit: 'cover' }}
      />
    );
  } else if (row?.media_type == 'VIDEO' || row?.meta?.isVideo) {
    return (
      <idk-div style={{ position: 'relative' }} class="zid-h-full zid-w-full zid-rounded">
        {row.source === 'Youtube' || row.source === 'Shorts' ? (
          <iframe
            width="100%"
            height="100%"
            className="card-img-top"
            src={`https://www.youtube.com/embed/${row.source_id}?autoplay=1&mute=1`}
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;height: 100%; object-fit:cover;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${
              row.source_id
            }?autoplay=1&mute=1><img src=https://img.youtube.com/vi/${row.source_id}/hqdefault.jpg><span>${
              row.source == 'Youtube'
                ? '<svg height="40px" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>'
                : '<svg height="40px" version="1.0" xmlns="http://www.w3.org/2000/svg" width="201.000000pt" height="250.000000pt" viewBox="0 0 201.000000 250.000000"><g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)" fill="#ff0000" stroke="none"><path d="M1414 2485 c-71 -15 -87 -23 -704 -347 -261 -138 -499 -269 -529 -291 -218 -168 -237 -502 -40 -700 70 -69 180 -125 263 -134 31 -3 56 -9 56 -12 0 -3 -52 -33 -116 -65 -241 -124 -334 -265 -322 -491 5 -85 9 -104 42 -171 50 -100 124 -174 218 -219 150 -71 287 -68 446 10 238 116 1054 552 1101 588 171 131 225 375 129 577 -62 130 -217 243 -352 257 -31 3 -56 9 -56 12 0 3 53 33 117 66 175 89 262 179 304 316 23 72 25 191 5 265 -46 174 -205 316-386 344 -80 12 -100 12 -176 -5z m-379 -1035 c154 -95 290 -180 303 -188 21 -14 16 -18 -160 -127 -101 -61 -243 -149 -315 -193 l-133 -82 0 386 c0 312 2 385 13 381 7 -3 139 -82 292 -177z"/></g></svg>'
            }</span></a>`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            {...props}
            style={{
              margin: 'auto auto',
              width: width > 650 ? (isModal ? '40vw' : '100%') : '80vw',
              height: isModal ? (width > 650 ? '70vh' : '40vh') : '100%',
              ...props?.style,
            }}
          ></iframe>
        ) : (
          <VideoPlayer
            preload={preload}
            autoPlay={autoPlay}
            showMute={showMute}
            videoSource={row.media_url + '#t=0.1'}
            poster={row?.thumbnail_url}
            playFunctionality={playFunctionality}
            {...props}
          />
        )}
      </idk-div>
    );
  } else if (row?.media_type == 'CAROUSEL_ALBUM') {
    return (
      <idk-div {...props}>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          customLeftArrow={<LeftArrow totalSlides={row?.children.data?.length} />}
          customRightArrow={<RightArrow totalSlides={row?.children.data?.length} />}
          {...props}
        >
          {row?.children.data.map((image, i) => {
            return (
              <idk-div key={i} class="zid-flex zid-items-center zid-justify-center zid-h-full">
                {image.media_type === 'VIDEO' && (
                  <VideoPlayer
                    preload={preload}
                    autoPlay={autoPlay}
                    showMute={showMute}
                    videoSource={row.media_url + '#t=0.1'}
                    poster={image.thumbnail_url}
                    playFunctionality={playFunctionality}
                    style={{ margin: 0 }}
                  />
                )}
                {image.media_type === 'IMAGE' && <img src={image.media_url} width="100%" className="zid-rounded"></img>}
              </idk-div>
            );
          })}
        </Carousel>
      </idk-div>
    );
  }
  return <></>;
}
