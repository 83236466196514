// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { BsStar, BsStarFill } from 'react-icons/bs';
import Rating from 'react-rating';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { SiTrustpilot } from 'react-icons/si';
import ReadMoreReadLess from '../../common/ReadMoreReadLess';

const GoogleView = ({ data }) => {
  const { clickMutate, layout } = useDisplayWidgetContext();
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(1000);
  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref?.current?.clientWidth);
    }
  }, [ref]);
  return (
    <idk-div
      ref={ref}
      style={{
        display: 'flex',
        justifyContent: width > 600 ? 'space-between' : 'flex-start',
        height: '100%',
        backgroundColor: '#fff',
        padding: `${width <= 600 ? '20px' : '30px'} 10px 30px 10px`,
        width: '100%',
        flexGrow: 1,
        rowGap: 10,
        flexDirection: width <= 600 ? 'column' : 'row',
        position: 'relative',
        borderRadius: layout?.cardSettings?.cardBorder,
      }}
      onClick={() => {
        clickMutate(data.id);
      }}
    >
      <idk-div
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          minWidth: '200px',
          maxWidth: '200px',
        }}
      >
        <idk-div class="zid-flex zid-items-center zid-gap-2">
          <idk-div
            class="zid-bg-slate-300 zid-items-center zid-justify-center zid-text-lg"
            style={{
              borderRadius: 999,
              overflow: 'hidden',
              minWidth: '32px',
              maxWidth: '32px',
              width: '32px',
              height: '32px',
              display: 'flex',
            }}
          >
            {data.source === 'Google_Review' &&
              (data?.profilePic ? (
                <a href={data?.author_link} target="_blank" style={{ height: 'inherit' }}>
                  <img src={data?.profilePic} style={{ objectFit: 'cover', width: '100%' }} loading="lazy" />
                </a>
              ) : (
                <b>{data?.username?.charAt(0)?.toUpperCase()}</b>
              ))}
            {data.source === 'TrustPilot' &&
              (data?.profilePic ? (
                <img src={data?.profilePic} style={{ objectFit: 'contain', width: '100%' }} loading="lazy" />
              ) : (
                <b>{data?.username?.charAt(0)?.toUpperCase()}</b>
              ))}
          </idk-div>
          <idk-div>
            <a href={data?.author_link} target="_blank">
              <idk-p style={{ margin: 0, textAlign: 'start', fontSize: '14px', fontWeight: 600, color: '#141414' }}>
                {data?.author_name || data?.username}
              </idk-p>
            </a>
            {data?.author_review_count && <idk-p class="zid-text-xs">{data?.author_review_count} reviews</idk-p>}
          </idk-div>
        </idk-div>
        {data?.relative_time_description && (
          <idk-div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <idk-p style={{ margin: 0, textAlign: 'start', fontSize: '10px', color: '#141414' }}>
              {data?.relative_time_description}
            </idk-p>
          </idk-div>
        )}
        {width > 600 && data.source === 'Google_Review' && (
          <Rating
            {...{
              emptySymbol: (
                <BsStar style={{ fontSize: '20px', marginRight: '5px', color: '#fbbc04' }}>star_border</BsStar>
              ),
              fullSymbol: (
                <BsStarFill style={{ fontSize: '20px', marginRight: '5px', color: '#fbbc04' }}>star_rate</BsStarFill>
              ),
              fractions: 2,
            }}
            initialRating={data?.rating}
            readonly
          />
        )}
      </idk-div>

      <idk-div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 4,
          width: width <= 600 ? '100%' : '80%',
        }}
      >
        {width <= 600 && data.source === 'Google_Review' && (
          <idk-div style={{ position: 'absolute', right: 5, top: 30 }}>
            <Rating
              {...{
                emptySymbol: (
                  <BsStar style={{ fontSize: '20px', marginRight: '5px', color: '#fbbc04' }}>star_border</BsStar>
                ),
                fullSymbol: (
                  <BsStarFill style={{ fontSize: '20px', marginRight: '5px', color: '#fbbc04' }}>star_rate</BsStarFill>
                ),
                fractions: 2,
              }}
              initialRating={data?.rating}
              readonly
            />
          </idk-div>
        )}
        {width <= 600 && data.source === 'TrustPilot' && (
          <idk-div>
            {data.rating == '1' && (
              <img
                src="https://assets.idukki.io/widget-assets/TrustPilot1Star.svg"
                style={{ height: '30px' }}
                loading="lazy"
              />
            )}
            {data.rating == '2' && (
              <img
                src="https://assets.idukki.io/widget-assets/TrustPilot2Star.svg"
                style={{ height: '30px' }}
                loading="lazy"
              />
            )}
            {data.rating == '3' && (
              <img
                src="https://assets.idukki.io/widget-assets/TrustPilot3Star.svg"
                style={{ height: '30px' }}
                loading="lazy"
              />
            )}
            {data.rating == '4' && (
              <img
                src="https://assets.idukki.io/widget-assets/TrustPilot4Star.svg"
                style={{ height: '30px' }}
                loading="lazy"
              />
            )}
            {data.rating == '5' && (
              <img
                src="https://assets.idukki.io/widget-assets/TrustPilot5Star.svg"
                style={{ height: '30px' }}
                loading="lazy"
              />
            )}
          </idk-div>
        )}
        {data.source === 'TrustPilot' && (
          <idk-div>
            <b>{data?.title}</b>
          </idk-div>
        )}
        <idk-div>
          {data.source === 'TrustPilot' && <ReadMoreReadLess totalWords={30}>{data.caption}</ReadMoreReadLess>}
          {data.source !== 'TrustPilot' && (
            <>
              <a href={data?.review_link} target="_blank">
                <ReadMoreReadLess totalWords={30}>{data?.caption}</ReadMoreReadLess>
              </a>
            </>
          )}
        </idk-div>
      </idk-div>

      <idk-div
        class="zid-gap-2"
        style={{
          fontSize: '25px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          bottom: 5,
          right: 5,
        }}
      >
        {layout?.googleCard?.showTimeline && (
          <idk-p class="zid-text-sm zid-text-[#707070]">
            {(window as any).moment && (window as any).moment(data.review_datetime_utc).fromNow()}
          </idk-p>
        )}
        {data.source === 'Google_Review' && <FcGoogle />}
        {data.source === 'TrustPilot' && <SiTrustpilot color="#00b67a" />}
      </idk-div>
    </idk-div>
  );
};

export default GoogleView;
