import React from 'react';
import { grouper } from '../collage/utils';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleView from '../GoogleView';
import MasonryBox from './MasonryBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import LinkedinView from '../LinkedinView';

const Masonry = () => {
  const { albumData, postSpacing, gridTemplateColumns, columns, isGoogle, isLinkedIn, fetchNextPost } =
    useDisplayWidgetContext();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(grouper(albumData, columns));
  }, [columns, albumData]);

  return (
    <idk-div>
      <InfiniteScroll
        dataLength={albumData.length}
        loader={<></>}
        next={fetchNextPost}
        hasMore={true}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        scrollableTarget="scrollable"
        style={{
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns,
          gap: `${postSpacing}px`,
        }}
      >
        {data?.map((col, id) => {
          if (col?.length == 0) return <></>;
          return (
            <idk-div class="zid-flex zid-flex-col zid-overflow-hidden" key={id} style={{ gap: `${postSpacing}px` }}>
              {col?.map((colData, ids) => {
                if (isGoogle) return <GoogleView key={ids} data={colData} />;
                if (isLinkedIn) return <LinkedinView key={ids} data={colData} />;
                return <MasonryBox colData={colData} key={ids} />;
              })}
            </idk-div>
          );
        })}
      </InfiniteScroll>
    </idk-div>
  );
};

export default Masonry;
