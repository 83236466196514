import React, { useState, useEffect, useRef } from 'react';
import { GoMute, GoUnmute } from 'react-icons/go';
import { FaPlay } from 'react-icons/fa';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';

export const VideoPlayer = ({ videoSource, showMute, autoPlay, playFunctionality, preload, ...props }: any) => {
  const [isMuted, setIsMuted] = useState(true);
  const [videoPlaying, setVideoPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { layout } = useDisplayWidgetContext();

  const toggleMute = () => {
    setIsMuted((prevState) => !prevState);
  };

  const updateProgressBar = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
      setDuration(videoRef.current.duration);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', updateProgressBar);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', updateProgressBar);
      }
    };
  }, []);

  const calculateProgress = () => {
    if (duration > 0) {
      return (currentTime / duration) * 100;
    }
    return 0;
  };

  const handlePlay = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (videoPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setVideoPlaying((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <style>{`
        .idk_play_icon path {
          stroke-width: 1;
        }
      `}</style>
      <idk-div className="relative" style={{ ...props.style }}>
        {showMute && (
          <idk-div
            class="zid-bg-[#0000004d] zid-rounded zid-flex zid-items-center zid-justify-center zid-h-[35px] zid-w-[35px]"
            style={{ position: 'absolute', top: '13px', right: '8px', zIndex: 99999 }}
            onClick={toggleMute}
          >
            {isMuted ? <GoMute fontSize="18px" color="#ffffff" /> : <GoUnmute fontSize="18px" color="#ffffff" />}
          </idk-div>
        )}

        {layout.galleryView != 'stories' &&
          layout?.videoSettings?.showPlayBtn &&
          (!videoPlaying || playFunctionality == false) && (
            <idk-div
              style={{ zIndex: 1, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
              onClick={(e: React.SyntheticEvent) => {
                if (playFunctionality == true) {
                  handlePlay(e);
                }
              }}
            >
              {layout?.videoSettings?.playBtnDesign == 'coloredBorderPlay' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  fill="none"
                  viewBox="0 0 120 120"
                  className="mx-auto text-2xl text-gray-500"
                >
                  <path
                    fill="#090A0B99"
                    d="M60 114.546c30.125 0 54.546-24.421 54.546-54.546S90.125 5.455 60 5.455 5.455 29.875 5.455 60 29.875 114.546 60 114.546Z"
                  ></path>
                  <path stroke="url(#a)" strokeWidth="5.986" d="M60 5.455a54.546 54.546 0 0 1 47.236 27.272"></path>
                  <path stroke="url(#b)" strokeWidth="5.986" d="M107.236 32.727a54.54 54.54 0 0 1 0 54.546"></path>
                  <path stroke="url(#c)" strokeWidth="5.986" d="M107.236 87.273A54.548 54.548 0 0 1 60 114.546"></path>
                  <path stroke="url(#d)" strokeWidth="5.986" d="M60 114.546a54.55 54.55 0 0 1-47.236-27.273"></path>
                  <path stroke="url(#e)" strokeWidth="5.986" d="M12.764 87.273a54.545 54.545 0 0 1 0-54.545"></path>
                  <path stroke="url(#f)" strokeWidth="5.986" d="M12.764 32.727A54.546 54.546 0 0 1 60 5.455"></path>
                  <path
                    fill="#fff"
                    d="M50.387 85.167c-3.142 0-5.387-2.47-5.387-5.387V40.275C45 37.469 47.245 35 50.387 35c.898 0 1.908.224 2.806.786l32.322 19.752c1.571.898 2.581 2.694 2.581 4.49 0 1.907-1.01 3.703-2.581 4.6L53.193 84.382a5.33 5.33 0 0 1-2.806.786Z"
                  ></path>
                  <defs>
                    <linearGradient id="a" x1="60" x2="83.619" y1="5.455" y2="46.363" gradientUnits="userSpaceOnUse">
                      <stop offset="0" stopColor="#198DEE"></stop>
                      <stop offset="0.5" stopColor="#2A3CEF"></stop>
                    </linearGradient>
                    <linearGradient
                      id="b"
                      x1="107.236"
                      x2="107.236"
                      y1="32.727"
                      y2="87.273"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#2A3CEF"></stop>
                      <stop offset="0.7" stopColor="#E124D9"></stop>
                    </linearGradient>
                    <linearGradient
                      id="c"
                      x1="107.236"
                      x2="83.617"
                      y1="87.273"
                      y2="128.181"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#E124D9"></stop>
                      <stop offset="1" stopColor="#E52E56"></stop>
                    </linearGradient>
                    <linearGradient id="d" x1="60" x2="36.381" y1="114.546" y2="73.637" gradientUnits="userSpaceOnUse">
                      <stop offset="0.2" stopColor="#FFB400"></stop>
                      <stop offset="1" stopColor="#C4FF29"></stop>
                    </linearGradient>
                    <linearGradient id="e" x1="5.456" x2="5.456" y1="87.273" y2="32.727" gradientUnits="userSpaceOnUse">
                      <stop offset="0.1" stopColor="#C4FF29"></stop>
                      <stop offset="1" stopColor="#198DEE"></stop>
                    </linearGradient>
                    <linearGradient
                      id="f"
                      x1="12.764"
                      x2="36.383"
                      y1="32.727"
                      y2="-8.181"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#198DEE"></stop>
                      <stop offset="1" stopColor="#198DEE"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <FaPlay fontSize={50} color="#ffffff" />
              )}
            </idk-div>
          )}
        <video
          ref={videoRef}
          playsInline
          loop
          preload={preload}
          disablePictureInPicture
          muted={isMuted}
          poster={props?.poster}
          autoPlay={autoPlay}
          onClick={(e: React.SyntheticEvent) => {
            if (playFunctionality == true) {
              handlePlay(e);
            }
          }}
          src={videoSource + '#t=0.1'}
          style={{ position: 'relative', objectFit: 'cover', margin: 0, ...props.style }}
        />
        {duration > 0 && (
          <idk-div
            class="zid-bg-[#090a0b33]"
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              borderRadius: '20px 20px 5px 5px',
              backdropFilter: 'blur(10px)',
            }}
          >
            <idk-div
              class="zid-transition"
              style={{ width: `${calculateProgress()}%`, height: '5px', backgroundColor: 'white' }}
            ></idk-div>
          </idk-div>
        )}
      </idk-div>
    </React.Fragment>
  );
};
