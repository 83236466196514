// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';
import Carousel from 'react-multi-carousel';
import { LeftArrow, RightArrow } from './carousel/carousel';
import { UseWindowDimensions } from './collage/utils';
import { IData, IView } from './DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';
import TextTruncate from './TextTruncate';
import { VideoPlayer } from './VideoPlayer/VideoPlayer';

export default function GoogleCarousel() {
  const [responsive, setResponsive] = React.useState({});
  const { width } = UseWindowDimensions();
  const mobileView = width < 500;
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;

  React.useEffect(() => {
    setResponsive({
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: mobileView ? 1 : 3,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: mobileView ? 1 : 3,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
      },
    });
  }, [galleryLayout]);

  const [photos, setPhotos] = React.useState([]);
  React.useEffect(() => {
    const photos = [];
    data?.result?.metaJson?.map((row) => {
      row?.photos?.map((col) => {
        photos.push({
          height: '200px',
          width: '100%',
          url: col?.media_url,
          address: row?.vicinity,
          name: row?.name,
          website: row?.website,
          type: col?.media_type?.toLowerCase(),
          thumbnail: row?.video_thumbnail_url,
        });
      });
    });
    setPhotos(photos);
  }, [data]);
  const padding = galleryLayout?.googlePhoto?.padding;
  return (
    <React.Fragment>
      <Carousel
        containerClass={'carousel-multi-container'}
        responsive={responsive}
        customLeftArrow={<LeftArrow />}
        customRightArrow={<RightArrow />}
      >
        {photos?.map((row, id) => (
          <idk-div
            key={id}
            class="zid-relative zid-flex zid-items-center zid-justify-center"
            style={{
              height: '100%',
              cursor: 'pointer',
              margin: `0px ${photos?.length - 1 == id ? 0 : padding} 0px ${0 == id ? 0 : padding}`,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url('${row?.url}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'grayscale(50%) blur(2px)',
                zIndex: -1,
              }}
            />
            {(row.type === 'photo' || row.type === 'street_view') && (
              <img
                loading="lazy"
                src={row?.url}
                width="100%"
                height="100%"
                style={{ objectFit: 'contain', maxHeight: '300px', minHeight: '300px' }}
                className="zid-rounded "
              />
            )}

            {row.type === 'video' && (
              <VideoPlayer
                autoPlay={true}
                showMute={false}
                videoSource={row?.url + '#t=0.1'}
                poster={row?.thumbnail_url}
                style={{ objectFit: 'contain', minHeight: '300px', maxHeight: '300px', margin: 0 }}
              />

              // <video
              //   loop
              //   width="100%"
              //   height="100%"
              //   style={{ objectFit: 'contain', minHeight: '300px', maxHeight: '300px', margin: 0 }}
              //   className="zid-rounded zid-bg-white"
              //   src={row?.url}
              //   controls
              //   poster={row?.thumbnail_url}
              //   playsInline
              // >
              //   <source src={row?.url} type="video/mp4" />
              // </video>
            )}

            {galleryLayout?.googlePhoto?.showNameAndAddress && (
              <idk-div class="card-text-container zid-rounded" style={{ height: '120px' }}>
                <idk-div class="card-text-container-inner" style={{ color: 'white' }}>
                  <idk-div>
                    <TextTruncate style={{ color: '#fff' }}>{row?.name}</TextTruncate>
                    <TextTruncate style={{ color: '#fff' }}>{row?.address}</TextTruncate>
                  </idk-div>
                </idk-div>
              </idk-div>
            )}
          </idk-div>
        ))}
      </Carousel>
    </React.Fragment>
  );
}
