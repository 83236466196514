import InfiniteScroll from 'react-infinite-scroll-component';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import './style.scss';
import GoogleView from '../GoogleView';
import LinkedinView from '../LinkedinView';

const Vertical = () => {
  const {
    showModal,
    clickMutate,
    albumData,
    postSpacing,
    gridTemplateColumns,
    setAlbumId,
    isGoogle,
    isLinkedIn,
    layout,
    fetchNextPost,
  } = useDisplayWidgetContext();

  return (
    <idk-div>
      <InfiniteScroll
        dataLength={albumData.length}
        loader={<></>}
        next={fetchNextPost}
        hasMore={true}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        scrollableTarget="scrollable"
        style={{
          minWidth: '300px',
          display: 'grid',
          gap: `${postSpacing}px`,
          placeContent: 'center',
        }}
      >
        {albumData.map((row, id) => {
          if (isGoogle) return <GoogleView key={id} data={row} />;
          if (isLinkedIn) return <LinkedinView key={id} data={row} />;
          return (
            <idk-div
              class={`commonImageContainer zid-w-full zid-overflow-hidden zid-relative zid-drop-shadow ${
                row?.media_type == 'CAROUSEL_ALBUM' ? '' : 'zid-flex zid-items-center zid-justify-center'
              }`}
              key={id}
              onClick={() => {
                clickMutate(row.id);
                showModal(row, 'slider');
                setAlbumId(row?.id);
              }}
              style={{
                margin: 'auto auto',
                borderRadius: layout?.cardSettings?.cardBorder,
                height: layout?.cardSettings?.cardHeight,
              }}
            >
              <HotspotMedia
                layout={layout}
                row={row}
                playFunctionality={false}
                autoPlay={layout?.videoSettings?.videoAutoPlay}
              />
              {/* <Cover
              onClick={() => {
                showModal(row, 'slider');
                setAlbumId(row?.id);
              }}
              row={row}
            /> */}
            </idk-div>
          );
        })}
      </InfiniteScroll>
    </idk-div>
  );
};

export default Vertical;
