import React, { useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import EventBus from '../common/EventBus';

export const Captcha = ({ onVerify, isOpen }) => {
  const ref = useRef(null);

  React.useEffect(() => {
    const resetCaptcha = () => {
      ref?.current?.resetCaptcha();
    };
    EventBus.getInstance().addListener('resetCaptcha', resetCaptcha);
    return () => EventBus.getInstance().removeListener(resetCaptcha);
  }, [isOpen]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <HCaptcha sitekey="c82fbb7e-0666-49a9-8269-a0f1f247c284" onVerify={onVerify} ref={ref} />;
};
