import React, { useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Cover from '../Cover';
import { IData } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleView from '../GoogleView';
import { filterData } from './utils';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductsOverlay from '../Modals/components/ProductsOverlay';
import LinkedinView from '../LinkedinView';

const Collage = () => {
  const { albumData } = useDisplayWidgetContext();
  const { data, fetchNextPost }: any = useDisplayWidgetContext();

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const photos = filterData(albumData);
    setPhotos(photos);
  }, [albumData]);

  return (
    <idk-div>
      <InfiniteScroll
        dataLength={albumData.length}
        loader={<></>}
        next={fetchNextPost}
        hasMore={true}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        scrollableTarget="scrollable"
        className="zid-flex zid-flex-wrap  zid-mx-auto zid-px-4 zid-py-4"
      >
        <PhotoAlbum
          layout={data?.result?.layout?.collageSettings.collageLayout}
          photos={photos}
          targetRowHeight={data?.result?.layout?.collageSettings.collageRowHeight}
          columns={data?.result?.layout?.collageSettings.collageColumns}
          spacing={data?.result?.layout?.collageSettings.collageSpacing}
          padding={data?.result?.layout?.collageSettings.collagePadding}
          renderPhoto={(e) => {
            return renderCustomPhoto(e);
          }}
        />
      </InfiniteScroll>
    </idk-div>
  );
};

export default Collage;

const renderCustomPhoto = (props) => {
  const { style } = props.imageProps;

  const { clickMutate, showModal, setAlbumId, isGoogle, isLinkedIn, layout, shopifyData } = useDisplayWidgetContext();

  if (isGoogle)
    return (
      <idk-div class="common-image-container rounded" style={{ ...style, aspectRatio: 'auto' }}>
        <GoogleView data={props?.photo} />
      </idk-div>
    );

  if (isLinkedIn)
    return (
      <idk-div class="common-image-container rounded" style={{ ...style, aspectRatio: 'auto' }}>
        <LinkedinView data={props?.photo} />
      </idk-div>
    );

  return (
    <idk-div
      class={`commonImageContainer zid-relative zid-rounded-lg ${
        props?.photo?.media_type == 'CAROUSEL_ALBUM' ? 'zid-max-w-[300px]' : ''
      }`}
      onClick={() => {
        clickMutate(props?.photo?.id);
        showModal(props?.photo, 'slider');
        setAlbumId(props?.photo?.id);
      }}
      style={{ ...style, overflow: 'hidden', borderRadius: layout?.cardSettings?.cardBorder }}
    >
      <HotspotMedia
        layout={layout}
        row={props.photo}
        playFunctionality={false}
        autoPlay={layout?.videoSettings?.videoAutoPlay}
      />
      {props?.photo?.products?.length > 0 && layout.gallerySettings.showProducts && (
        <ProductsOverlay shopifyData={shopifyData} row={props.photo}></ProductsOverlay>
      )}
      {/* <Cover
        onClick={() => {
          showModal(props?.photo, 'slider');
          setAlbumId(props?.photo?.id);
        }}
        row={props?.photo}
      /> */}
    </idk-div>
  );
};
