import axios from 'axios';
import { API_BASE_URL } from '../contants';

async function fetch(postId: string, userId: string, guid: string): Promise<any> {
  const endpoint = API_BASE_URL() + `/analytics/clicks/${guid}?postId=${postId}&userId=${userId}`;
  return await axios.get(endpoint).then(({ data }) => data as any);
}

export default function useClick(userId: string, guid: string, isRedis: any) {
  const mutate = (postId: string) => {
    if (!isRedis) {
      fetch(postId, userId, guid);
    }
  };

  return { mutate };
}
