import React from 'react';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import Carousel from 'react-multi-carousel';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import ProductsOverlay from '../Modals/components/ProductsOverlay';
import ReadMoreReadLess from '../../common/ReadMoreReadLess';
import LinkedinView from '../LinkedinView';

export default function CarouselRightPost() {
  const { showModal, albumData, clickMutate, setAlbumId, isLinkedIn, layout, shopifyData } = useDisplayWidgetContext();

  const { data } = useDisplayWidgetContext();
  const galleryData = data?.result?.layout;

  const responsive = {
    mobile: {
      breakpoint: { max: 40000, min: 0 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
    super: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Carousel
        responsive={responsive}
        containerClass={'carousel-multi-container'}
        autoPlay={galleryData.sliderSettings.autoPlay}
        infinite={true}
        autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
        customLeftArrow={
          <LeftArrow
            arrowColor={layout?.sliderSettings?.arrowColor}
            arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
          />
        }
        customRightArrow={
          <RightArrow
            arrowColor={layout?.sliderSettings?.arrowColor}
            arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
          />
        }
        itemClass="idk-carousel-item"
      >
        {albumData.map((row, id) => {
          if (isLinkedIn) return <LinkedinView key={id} data={row} />;
          return (
            <idk-div
              class={`zid-w-[100%] zid-flex zid-items-center zid-justify-center zid-relative`}
              key={id}
              onClick={() => {
                showModal(row, 'slider');
                setAlbumId(row.id);
                clickMutate(row.id);
              }}
              style={{
                borderRadius: layout?.cardSettings?.cardBorder,
              }}
            >
              <idk-div class="zid-w-full zid-h-full zid-bg-stone-700 zid-rounded zid-flex zid-text-white zid-p-20 zid-items-center zid-justify-center zid-gap-20">
                <idk-div class="zid-flex-1 zid-h-full zid-flex zid-gap-3 zid-flex-col zid-justify-center">
                  <idk-div>
                    <idk-p class="zid-text-4xl zid-font-bold zid-mb-2">{row.username}</idk-p>
                    <idk-p class="zid-leading-relaxed zid-tracking-wide">
                      <ReadMoreReadLess totalWords={30}>{row.caption}</ReadMoreReadLess>
                    </idk-p>
                  </idk-div>
                  <img
                    src={row.profilePic}
                    className="zid-absolute zid-h-20 zid-w-20 zid-rounded-full"
                    style={{ top: '50px' }}
                  ></img>
                  <idk-p
                    class="zid-absolute zid-font-bold zid-text-2xl zid-flex zid-gap-2 zid-items-center"
                    style={{ bottom: '40px' }}
                  >
                    <idk-div
                      class="zid-text-[30px]"
                      style={{ color: 'rgb(228, 64, 95)', cursor: 'pointer' }}
                      onClick={() => window.open(row?.data?.permalink, '_blank')}
                    >
                      {row?.source == 'Youtube' && (
                        <img
                          height={25}
                          width={25}
                          src="https://assets.idukki.io/widget-assets/YoutubeLogo.svg"
                          loading="lazy"
                        ></img>
                      )}
                      {row.source === 'Shorts' && (
                        <img
                          height={25}
                          width={25}
                          src="https://assets.idukki.io/widget-assets/ShortsLogo.svg"
                          loading="lazy"
                        ></img>
                      )}
                      {row?.source == 'Instagram_business' && (
                        <img
                          height={25}
                          width={25}
                          src="https://assets.idukki.io/widget-assets/InstagramLogo.svg"
                          loading="lazy"
                        ></img>
                      )}
                      {row?.source == 'Twitter' && (
                        <img
                          height={25}
                          width={25}
                          src="https://assets.idukki.io/widget-assets/XTwitterLogo.svg"
                          loading="lazy"
                        ></img>
                      )}
                    </idk-div>
                    <i>{row.userid}</i>
                  </idk-p>
                </idk-div>
                <idk-div
                  class="zid-h-full zid-flex-1 zid-relative zid-bg-white zid-rounded zid-overflow-hidden zid-rounded-lg zid-relative zid-w-full zid-h-full zid-py-5 zid-px-5 zid-bg-white"
                  style={{ height: window.innerWidth < 576.98 ? '100%' : galleryData.cardSettings.cardHeight }}
                  onClick={() => {
                    // showModal(row, 'slider');
                    // setAlbumId(id);
                  }}
                >
                  {/* {isShopify && row.products.length > 0 && (
                  <idk-div class="">
                    <img src=""></img>
                    <div dangerouslySetInnerHTML={{ __html: row?.products?.[0]?.description }}></div>
                  </idk-div>
                )} */}
                  <HotspotMedia
                    row={row}
                    playFunctionality={false}
                    autoPlay={layout?.videoSettings?.videoAutoPlay}
                    layout={layout}
                    style={{ borderRadius: '5px' }}
                  />
                  {row?.products?.length > 0 && layout.gallerySettings.showProducts && (
                    <ProductsOverlay shopifyData={shopifyData} row={row}></ProductsOverlay>
                  )}

                  {/* <Cover
                    onClick={() => {
                      showModal(row, 'slider');
                      setAlbumId(row.id);
                      clickMutate(row.id);
                    }}
                    row={row}
                  /> */}
                </idk-div>
              </idk-div>
            </idk-div>
          );
        })}
      </Carousel>
    </>
  );
}
function LeftArrow({ ...props }) {
  const { onClick } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == 0) return <></>;

  return (
    <idk-div
      onClick={onClick}
      class={`zid-absolute zid-translate-y-[-50%] zid-top-[50%] zid-translate-x-[20px] zid-cursor-pointer zid-opacity-[0.7] zid-flex zid-items-center zid-justify-center ${props?.className}`}
    >
      <svg height="50px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="50px" fill={props.arrowColor}>
        <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
      </svg>
    </idk-div>
  );
}

function RightArrow({ ...props }) {
  const { onClick, totalSlides } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == totalSlides - 1) return <></>;
  return (
    <idk-div
      onClick={onClick}
      class={`zid-absolute zid-translate-y-[-50%] zid-right-0 zid-top-[50%] zid-translate-x-[-20px] zid-z-[100] zid-cursor-pointer zid-opacity-[0.7] zid-flex zid-items-center zid-justify-center ${props?.className}`}
    >
      <svg height="50px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="50px" fill={props.arrowColor}>
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg>
    </idk-div>
  );
}
