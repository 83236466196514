import axios from 'axios';
import React from 'react';
import { AuthHeaders } from '../../../components/collage/utils';
import { API_BASE_URL } from '../../../contants';

export default function useGalleryLayout(token) {
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetch = () => {
    setIsLoading(true);
    const endpoint = API_BASE_URL() + '/gallery-layouts';
    axios
      .get(endpoint, AuthHeaders(token))
      .then(({ data }) => {
        if (data?.status) setData(data?.result);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return { data, isLoading };
}
