import React from 'react';

export default function PoweredBy({ guid, className, onPost }: any) {
  return (
    <idk-div
      class={`zid-bg-white zid-w-max ${
        onPost ? '' : 'zid-p-2 zid-mr-2'
      } zid-rounded zid-flex zid-justify-end zid-cursor-pointer zid-mt-2 ${className}`}
      onClick={() =>
        window.open(
          `https://www.idukki.io?utm_source=${window?.location?.origin}&utm_medium=widget&utm_campaign=${guid}&utm_id=displaywidgets`,
          '_blank',
        )
      }
    >
      <idk-div class="zid-flex zid-gap-[4px] zid-items-center zid-text-black zid-text-[15px]">
        <idk-p>Powered by</idk-p>
        <img
          loading="lazy"
          alt="https://idukki.io"
          // onClick={() => {
          //    if (businessData?.business?.webstore_url) window.open(businessData?.business?.webstore_url, '_blank');
          // }}
          src={'https://assets.idukki.io/logo.svg'}
          style={{ maxHeight: '16px' }}
        />
      </idk-div>
    </idk-div>
  );
}
