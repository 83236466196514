// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';
import Dropzone from 'react-dropzone';
import { AiFillInfoCircle } from 'react-icons/ai';
import { BiMicrophone } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';
import { IoCloseCircle } from 'react-icons/io5';
import SpeechRecognition from 'react-speech-recognition';
import PoweredBy from '../../common/PoweredBy';
import { Captcha } from '../../components/Captcha';
import { halfStarImage, transparentLoderImage, uploadImage } from '../../contants';
import { validateEmail } from '../../utils';
import { useUploadMediaContext } from './utils/context';

const getColor = (rating) => {
  if (rating == 0) return '#D7DBDD';
  if (rating >= 0 && rating <= 1) return '#ffcd4a';
  if (rating >= 1 && rating <= 2) return '#feaf48';
  if (rating >= 2 && rating <= 3) return '#ff8643';
  if (rating >= 3 && rating <= 4) return '#fe643c';
  return '#fc433a';
};

const getRatingText = (rating) => {
  if (rating <= 0) return '';
  if (rating >= 0 && rating <= 1) return 'Not good';
  if (rating >= 1 && rating <= 2) return `Could've been better`;
  if (rating >= 2 && rating <= 3) return 'Ok';
  if (rating >= 3 && rating <= 4) return 'Good';
  return 'Great';
};

export default function UploadMediaView({ guid, oguid }) {
  const {
    businessData,
    rating,
    setRating,
    handleDelete,
    handleChange,
    error,
    files,
    captcha,
    setCaptcha,
    email,
    setEmail,
    name,
    setName,
    comment,
    setComment,
    handleSubmit,
    isLoading,
    transcript,
    listening,
    resetTranscript,
    query_name,
    query_email,
    uploadMediaClose,
    modalRef,
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    businessLogoUrl,
    businessName,
    shopifyData,
  } = useUploadMediaContext();
  React.useEffect(() => {
    resetTranscript();
  }, [comment]);
  const [ratingHover, setRatingHover] = React.useState(-1);
  const extraImg = shopifyData ? shopifyData?.product?.length - 2 : 0;
  const productImg = shopifyData ? shopifyData?.product : [];
  return (
    <idk-div class="zid-max-w-[100vw] zid-w-[500px] zid-flex zid-flex-col zid-justify-center zid-items-center zid-mt-1">
      <idk-div
        class={`zid-w-full zid-p-4 zid-flex zid-flex-col zid-justify-center zid-items-center zid-relative zid-mb-[20px] zid-pb-[70px]`}
        style={{
          background: '#F8F9F9',
        }}
      >
        <idk-div class="zid-flex zid-justify-between zid-items-center zid-w-full">
          <idk-div class="zid-w-[20px] zid-h-[1px]" />
          <idk-p class="zid-p-0 zid-m-0 zid-text-[24px] zid-font-bold">{businessName}</idk-p>
          {uploadMediaClose ? (
            <idk-div style={{ transform: 'rotate(45deg)' }} onClick={uploadMediaClose}>
              <BsPlus size={40} color="#000" />
            </idk-div>
          ) : (
            <idk-div class="zid-w-[20px] zid-h-[1px]" />
          )}
        </idk-div>
        <idk-div class="zid-text-[18px] zid-font-[500] zid-mt-3 zid-mx-auto">
          <idk-div class="zid-w-full zid-flex zid-justify-center zid-mb-4">
            <img
              src={businessLogoUrl}
              style={{ objectFit: 'contain', width: '200px', maxHeight: '100px' }}
              loading="lazy"
            />
          </idk-div>
          <idk-p class="zid-p-0 zid-m-0 zid-text-center">Hey, {shopifyData?.customer_first_name}</idk-p>
          <idk-p class="zid-p-0 zid-m-0 zid-w-[80%] zid-mx-auto zid-text-center">{`We would love to know we're doing and your experince with us`}</idk-p>
          {oguid && <idk-p class="zid-p-0 zid-m-0 zid-text-center zid-mt-2">You recently purchased</idk-p>}
        </idk-div>
      </idk-div>

      <idk-div class=" zid-flex zid-items-center zid-flex-row zid-gap-2 ">
        {productImg?.map((row, id) => {
          if (id > 1) return null;
          return (
            <idk-div
              key={id}
              class="zid-rounded zid-p-[5px] zid-overflow-hidden"
              style={{ border: '2px solid #E5E7E9' }}
            >
              <img
                loading="lazy"
                src={row?.image?.image_url}
                style={{
                  objectFit: 'contain',
                  height: '100%',
                  maxWidth: '100px',
                  maxHeight: '100px',
                }}
              />
            </idk-div>
          );
        })}
        {extraImg > 0 ? (
          <idk-div class="zid-w-[30px] zid-font-bold zid-h-[30px] zid-bg-[#098] zid-rounded-full zid-flex zid-justify-center zid-items-center zid-text-[#fff] zid-text-[10px]">
            +{extraImg}
          </idk-div>
        ) : null}
      </idk-div>

      <idk-div>
        <idk-p class="zid-mt-2 zid-p-0 zid-mb-[15px] zid-text-[18px] zid-text-[#0E1311] zid-font-bold zid-leading-[24px] zid-text-center">
          Write Your Review
        </idk-p>
        <idk-div class="zid-flex">
          {Array.from({ length: 10 }, (_, id) => {
            const starId = id + 1;
            return (
              <idk-div
                onMouseOver={() => {
                  setRatingHover(starId / 2);
                }}
                onMouseOut={() => setRatingHover(-1)}
                key={id}
                class="zid-py-3"
                onClick={() => {
                  setRating(starId / 2);
                  if (modalRef) {
                    modalRef.current.scrollTo({ top: ref1?.current?.offsetTop, behavior: 'smooth' });
                  } else {
                    window.scrollTo(0, ref1?.current?.offsetTop);
                  }
                }}
                style={{
                  borderTopLeftRadius: starId % 2 != 0 ? '5px' : '0px',
                  borderTopRightRadius: starId % 2 == 0 ? '5px' : '0px',
                  borderBottomLeftRadius: starId % 2 != 0 ? '5px' : '0px',
                  borderBottomRightRadius: starId % 2 == 0 ? '5px' : '0px',
                  paddingLeft: starId % 2 != 0 ? '12px' : '0px',
                  paddingRight: starId % 2 == 0 ? '12px' : '0px',
                  marginRight: starId % 2 == 0 ? '8px' : '0px',
                  backgroundColor:
                    ratingHover < 0
                      ? rating >= starId / 2
                        ? getColor(rating)
                        : '#D7DBDD'
                      : ratingHover >= starId / 2
                      ? getColor(ratingHover)
                      : '#D7DBDD',
                }}
              >
                <idk-div>
                  <img
                    loading="lazy"
                    src={halfStarImage}
                    width={'10px'}
                    style={{
                      transform: starId % 2 == 0 ? 'scaleX(-1)' : 'scaleX(1)',
                    }}
                  />
                </idk-div>
              </idk-div>
            );
          })}
        </idk-div>
        <idk-p class="zid-p-0 zid-h-[16px] zid-mt-1 zid-text-[16px]">{`${getRatingText(ratingHover)}`}</idk-p>
      </idk-div>

      <idk-div
        class="zid-w-full zid-h-[200px] zid-rounded-t-md zid-mt-[30px] zid-flex zid-flex-col"
        style={{
          background: `linear-gradient(to bottom,#f3fbff 0,#fff 60%)`,
        }}
      >
        <idk-div ref={ref1} class="zid-py-[30px] zid-px-[20px]" style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
          <idk-p class="zid-mb-[10px] zid-p-0 zid-font-bold zid-text-[16px]">{`Tell us how ${businessName} made you happy?`}</idk-p>
          <idk-p class="zid-mb-[15px] zid-p-0 zid-font-[400] zid-text-[15px]">
            Help future customers by talking about customer service, price, delivery, returns & refunds.
          </idk-p>
          <idk-div class="zid-relative">
            <textarea
              className="zid-p-[8px] zid-w-full zid-min-h-[150px] zid-mb-[5px]"
              style={{ border: '2px dashed #bac4c7' }}
              value={comment || transcript}
              onChange={(e) => {
                SpeechRecognition.stopListening();
                setComment(e.target.value);
              }}
              onBlur={() => {
                if (modalRef) {
                  modalRef.current.scrollTo({ top: ref2?.current?.offsetTop, behavior: 'smooth' });
                } else {
                  window.scrollTo(0, ref2?.current?.offsetTop);
                }
              }}
            />
            <idk-div
              class="zid-absolute zid-bottom-[15px] zid-right-0 zid-cursor-pointer"
              style={{ borderRadius: 999, padding: '2px', border: listening ? '1px solid #5DADE2' : 'none' }}
              onClick={() => {
                if (!listening) {
                  setComment('');
                  SpeechRecognition.startListening({ continuous: true });
                } else {
                  SpeechRecognition.stopListening();
                }
              }}
            >
              <BiMicrophone size={'24px'} color={listening ? '#5DADE2' : '#757575'} />
            </idk-div>
          </idk-div>
        </idk-div>

        <idk-div ref={ref2} class="zid-py-[30px] zid-px-[20px]" style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
          <idk-p class="zid-mb-[10px] zid-p-0 zid-font-bold zid-text-[16px]">
            Would you like to add a photos{businessData?.plan?.videosAllowed ? ' or videos' : ''}?
          </idk-p>
          <idk-p class="zid-mb-[15px] zid-p-0 zid-font-[400] zid-text-[15px]">
            Strengthen your review by uploading{businessData?.plan?.videosAllowed ? ' photos & videos' : ''}.
          </idk-p>
          <idk-div
            class="zid-w-full zid-h-[150px] zid-rounded-md zid-flex zid-flex-col zid-justify-center zid-items-center zid-bg-[#ffff]"
            style={{ border: '2px dashed #bac4c7' }}
          >
            <Dropzone onDrop={handleChange}>
              {({ getRootProps, getInputProps }) => (
                <idk-div class="dropzone">
                  <idk-div class=" zid-flex zid-justify-center zid-items-center zid-flex-col" {...getRootProps()}>
                    <input {...getInputProps()} name="postImage" />
                    <img src={uploadImage} loading="lazy" />
                    {/* <FaCloudUploadAlt class="m-auto" color="#00000080" fontSize={32} /> */}
                    <idk-p class="zid-m-0 text-center zid-text-[14px]">Drop files here or click to upload.</idk-p>
                    <idk-p class="zid-m-0 zid-text-[11px]">You can drop or also select multiple files here</idk-p>
                    <idk-p class="zid-m-0 zid-text-[11px]">
                      Supported formats: .jpg, .jpeg, .png {businessData?.plan?.videosAllowed ? '.mov. mp4' : ''}
                    </idk-p>
                  </idk-div>
                </idk-div>
              )}
            </Dropzone>
          </idk-div>
          <idk-div class="zid-flex zid-flex-wrap zid-items-center">
            {error && (
              <>
                <idk-div class="zid-flex zid-mt-2 zid-text-red-500">
                  <AiFillInfoCircle fontSize={32} />
                  <idk-div class="zid-ml-2">
                    <idk-p class="zid-m-0">{error}</idk-p>
                  </idk-div>
                </idk-div>
              </>
            )}
            <idk-div class="zid-px-4 zid-flex">
              {files.map((image, i) => {
                return (
                  <idk-div class="preview-image-container" key={i}>
                    <idk-div class="position-relative zid-m-2">
                      <idk-div class="zid-flex zid-w-100 zid-justify-between">
                        <idk-div />
                        <IoCloseCircle
                          class="position-absolute zid-top-0 zid-start-100 translate-middle common-hover zid-cursor-pointer"
                          fontSize={24}
                          color="#00000080"
                          onClick={() => handleDelete(image)}
                        />
                      </idk-div>
                      <img
                        loading="lazy"
                        className="preview-image"
                        src={URL.createObjectURL(image)}
                        style={{ width: '80px', height: '80px', borderRadius: '4px' }}
                      />
                    </idk-div>
                  </idk-div>
                );
              })}
            </idk-div>
          </idk-div>
        </idk-div>

        {query_name ? null : (
          <idk-div ref={ref3} class="zid-py-[30px] zid-px-[20px]" style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
            <idk-p class="zid-mb-[10px] zid-p-0 zid-font-bold zid-text-[16px]">{`What's your name?`}</idk-p>
            <idk-p class="zid-mb-[15px] zid-p-0 zid-font-[400] zid-text-[15px]">We need your name</idk-p>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="zid-p-[8px] zid-w-full zid-mb-[5px]"
              style={{ border: '2px dashed #bac4c7' }}
              onBlur={() => {
                if (modalRef) {
                  modalRef.current.scrollTo({ top: ref4?.current?.offsetTop, behavior: 'smooth' });
                } else {
                  window.scrollTo(0, ref4?.current?.offsetTop);
                }
              }}
            />
          </idk-div>
        )}

        {query_email && validateEmail(query_email) ? null : (
          <idk-div ref={ref4} class="zid-py-[30px] zid-px-[20px]">
            <idk-p class="zid-mb-[10px] zid-p-0 zid-font-bold zid-text-[16px]">{`What's your email?`}</idk-p>
            <idk-p class="zid-mb-[15px] zid-p-0 zid-font-[400] zid-text-[15px]">
              We need your email address to verify that your review is genuine
            </idk-p>
            <input
              value={email?.value}
              onChange={(e) => {
                setEmail({ value: e.target.value, error: '' });
              }}
              className="zid-p-[8px] zid-w-full zid-mb-[5px]"
              style={{ border: '2px dashed', borderColor: email?.error ? 'red' : '#bac4c7' }}
              onBlur={() => {
                if (modalRef) {
                  modalRef.current.scrollTo({ top: ref5?.current?.offsetTop, behavior: 'smooth' });
                } else {
                  window.scrollTo(0, ref5?.current?.offsetTop);
                }
              }}
            />
            {email?.error && (
              <idk-p class="zid-mb-[5px] zid-p-0 zid-text-[red] zid-font-[400] zid-text-[11px]">{email?.error}</idk-p>
            )}
          </idk-div>
        )}

        <idk-div ref={ref5} class="zid-pb-[30px] zid-px-[20px]">
          <idk-div>
            <Captcha
              onVerify={(token) => {
                setCaptcha({ value: token, error: '' });
              }}
            />
            {captcha.error && (
              <idk-div class="zid-text-[#F04438] zid-font-[500] zid-leading-[20px] zid-text-[10px]">
                {captcha.error}
              </idk-div>
            )}
          </idk-div>
          <idk-div class="zid-flex zid-justify-center zid-mt-[20px]">
            <idk-div
              onClick={handleSubmit}
              class="zid-px-[30px] zid-py-[10px] zid-mb-[20px] zid-rounded-sm zid-cursor-pointer"
              style={{
                color: businessData?.business?.primary_color || 'white',
                backgroundColor: businessData?.business?.secondary_color || 'black',
              }}
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </idk-div>
          </idk-div>
          <idk-p class="zid-mb-[5px] zid-p-0 zid-text-[#757575] zid-font-[400] zid-text-[11px]">
            By submitting your review you agree to the {businessData?.business?.name}{' '}
            <idk-span
              class="zid-underline zid-text-blue-600 zid-cursor-pointer "
              onClick={() => {
                if (businessData?.business?.privacyPolicy) window.open(businessData?.business?.privacyPolicy, '_blank');
              }}
            >
              terms & conditions
            </idk-span>
            .
          </idk-p>
          {businessData?.plan?.showPoweredByIdduki && <PoweredBy guid={guid} />}
        </idk-div>
      </idk-div>
      {isLoading ? (
        <idk-div class="zid-fixed zid-top-0 zid-left-0 zid-w-full zid-h-full zid-opacity-[0.6] zid-bg-black zid-z-50 zid-flex zid-justify-center zid-items-center">
          <img loading="lazy" src={transparentLoderImage} style={{ width: '200px' }} />
        </idk-div>
      ) : null}
    </idk-div>
  );
}
