import React, { useRef } from 'react';
import { UploadMedia } from '../pages/UploadMedia';
import CustomModal from './CustomModal';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';

export default function UploadModal({ isOpen, onClose }) {
  const { bguid } = useDisplayWidgetContext();
  const ref = useRef(null);
  if (isOpen) {
    return (
      <CustomModal isOpen={isOpen} onClose={onClose} className="zid-rounded-none">
        <idk-div ref={ref} class="zid-max-h-[95vh] zid-overflow-y-scroll">
          <UploadMedia modalRef={ref} bguid={bguid} uploadMediaClose={onClose} />
        </idk-div>
      </CustomModal>
    );
  }
  return null;
}
