import React from 'react';
import { IoCopy } from 'react-icons/io5';
import Media from '../../components/Media';
import Controls from './Controls';
import ImageHotspot from './ImageHotspot';
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';
import ReadMoreReadLess from '../ReadMoreReadLess';
import { IoPerson } from 'react-icons/io5';
import { FaLinkedin } from 'react-icons/fa';

export default function HotspotMedia({
  row,
  isModal = false,
  style = {},
  playFunctionality,
  view,
  autoPlay,
  preload,
  showMute,
  layout,
}: any) {
  const [postNumber, setPostNumber] = React.useState(0);
  const [hotspots, setHotspots] = React.useState([]);
  const products = row?.products;
  React.useEffect(() => {
    if (products) {
      const hotspotsTemp = [];
      products?.map((row) => {
        if (row.id && row?.coords?.x != -1) {
          hotspotsTemp.push({
            ...row?.coords,
            data: row,
            id_from_product: row?.id_from_product,
          });
        }
      });
      setHotspots(hotspotsTemp);
    }
  }, [products]);

  React.useEffect(() => {
    let timer;
    if (row?.media_type == 'CAROUSEL_ALBUM') {
      timer = setInterval(() => {
        setPostNumber((e) => {
          const totalPost = row?.children?.data?.length;
          if (e == totalPost - 1) return 0;
          return e + 1;
        });
      }, 4000);
    }
    return () => clearInterval(timer);
  });

  return (
    <>
      {row?.media_type == 'document' ? (
        <idk-div>
          <idk-div class="zid-flex zid-gap-[10px] zid-justify-between zid-items-center zid-p-[10px]">
            <idk-div class="zid-flex-1 zid-flex zid-items-center zid-gap-2">
              <idk-div class="zid-rounded-full zid-overflow-hidden zid-bg-slate-300 zid-flex zid-items-center zid-justify-center zid-w-[32px] zid-h-[32px]">
                <IoPerson size={25} />
              </idk-div>
              <idk-div class="zid-flex zid-flex-col">
                <idk-p class="zid-m-0 zid-text-start zid-text-[14px] zid-font-[600] zid-text-[#141414]"></idk-p>
                <idk-p class="zid-m-0 zid-text-start zid-text-[10px] zid-text-[#141414]">
                  {(window as any).moment && (window as any).moment(row?.timestamp).fromNow()}
                </idk-p>
              </idk-div>
            </idk-div>
            <idk-div>
              <FaLinkedin size={25} color="#0762C8" />
            </idk-div>
          </idk-div>
          <idk-p>
            <ReadMoreReadLess totalWords={30}>{row.caption}</ReadMoreReadLess>
          </idk-p>
        </idk-div>
      ) : row?.media_type == 'IMAGE' ? (
        <ImageHotspot
          hotspots={hotspots}
          alt={row?.caption}
          src={row?.src || row?.media_url}
          id={0}
          style={style}
          layout={layout}
        />
      ) : row?.media_type == 'CAROUSEL_ALBUM' ? (
        <idk-div class="zid-relative zid-h-full">
          {row?.children?.data?.length > 0 &&
            (row?.children?.data?.[postNumber]?.media_type === 'IMAGE' ? (
              <>
                <ImageHotspot
                  hotspots={hotspots}
                  src={row?.children?.data[postNumber]?.media_url}
                  id={postNumber}
                  style={style}
                  alt={row?.caption}
                  layout={layout}
                />
                {isModal && <Controls postNumber={postNumber} setPostNumber={setPostNumber} row={row} />}
              </>
            ) : (
              <>
                <VideoPlayer
                  preload={preload}
                  autoPlay={autoPlay}
                  showMute={showMute}
                  videoSource={row?.children?.data[postNumber]?.media_url + '#t=0.1'}
                  poster={row?.thumbnail_url}
                  style={{ margin: 0, width: '100%', height: '100%', objectFit: 'cover', ...style }}
                />
                {isModal && <Controls postNumber={postNumber} setPostNumber={setPostNumber} row={row} />}
              </>
            ))}
          {!isModal && view != 'stories' && view != 'premium2' && (
            <IoCopy
              style={{ position: 'absolute', top: '5px', right: '5px', transform: 'rotate(180deg)' }}
              size={20}
              color="#fff"
            />
          )}
        </idk-div>
      ) : (
        <Media
          autoPlay={autoPlay}
          preload={preload}
          showMute={showMute}
          alt={row?.caption}
          row={row}
          style={{ width: '100%', height: '100%', objectFit: 'cover', ...style }}
          isModal={isModal}
          playFunctionality={playFunctionality}
          view={view}
        />
      )}
    </>
  );
}
