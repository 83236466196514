import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { IData, IView } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleRating from '../GoogleRating';

const Badge1 = ({ rating, reviews }) => {
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;
  const style = galleryLayout?.googleBadges;

  return (
    <idk-div
      style={{
        padding: '20px 15px',
        borderRadius: '4px',
        border: `${style?.border} solid #515A5A`,
        backgroundColor: style.bgColor,
        color: style.fontColor,
        maxWidth: 400,
        height: '100%',
        width: '100%',
        flex: 1,
      }}
      class="zid-flex zid-items-center zid-justify-center zid-gap-2"
    >
      <idk-div class="zid-my-auto">
        <idk-div style={{ padding: '5px', borderRadius: '8px' }}>
          <FcGoogle size={40} />
        </idk-div>
      </idk-div>
      <idk-div>
        <idk-p class="zid-m-0" style={{ fontSize: '16px', fontWeight: 500 }}>
          Google Reviews
        </idk-p>
        <idk-div class="zid-flex zid-gap-2">
          <idk-p class="zid-m-0 zid-my-auto" style={{ fontSize: '25px' }}>
            {rating}
          </idk-p>
          <idk-div class="zid-my-auto">
            <GoogleRating rating={rating} color={style.starColor} />
          </idk-div>
        </idk-div>
        <idk-p class="zid-m-0" style={{ fontSize: '14px', fontWeight: 500 }}>
          Based on {reviews} reviews
        </idk-p>
      </idk-div>
    </idk-div>
  );
};

export default Badge1;
