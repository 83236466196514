import axios from 'axios';
import React from 'react';
import { API_BASE_URL } from '../contants';

interface IPayload {
  reporter_email: string;
  reason: string;
  media_id: number;
  bguid: string;
  captchaToken: string;
}

export default function useReportMedia(onClose, onCongratsModalOpen) {
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const mutate = (payload: IPayload) => {
    setIsLoading(true);
    const endpoint = API_BASE_URL() + '/report-media';
    axios.post(endpoint, payload).then(({ data }) => {
      if (onClose && onCongratsModalOpen) {
        onClose();
        onCongratsModalOpen();
      }
      setIsLoading(false);
      setData(data);
    });
  };
  return { mutate, isLoading, data };
}
