import React from 'react';
import Loader from '../../common/Loader';
import GuidPageView from './GuidPage.view';
import useGalleryLayout from './hooks/useGalleryLayout';
import { GuidPageContext } from './utils/context';
import { emptyImage } from '../../contants';

export default function GuidPageContainer({ token, bguid }) {
  const { data, isLoading } = useGalleryLayout(token);

  if (isLoading) {
    return (
      <idk-div class="">
        <Loader show={true} />
      </idk-div>
    );
  }

  if (data?.length == 0) {
    return (
      <idk-div class="zid-flex zid-flex-col zid-gap-4 zid-p-[20px] zid-items-center">
        <idk-div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            height: '100%',
            marginTop: '150px',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <img src={emptyImage} width={'400px'} loading="lazy" />
          <idk-div style={{ display: 'flex', gap: '5px', flexDirection: 'column', justifyContent: 'center' }}>
            <idk-p style={{ textAlign: 'center', fontSize: '24px' }}>No guid, start by adding one.</idk-p>
            <idk-p style={{ textAlign: 'center', fontSize: '16px' }}>
              <idk-span
                onClick={() => {
                  window.open('https://app.idukki.io/dashboard/ugc-flow/create-feeds', '_blank');
                }}
                style={{ fontWeight: 500, color: '#098', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Click here{' '}
              </idk-span>
              to add
            </idk-p>
          </idk-div>
        </idk-div>
      </idk-div>
    );
  }

  return (
    <GuidPageContext.Provider value={{ bguid, token, data, isLoading }}>
      <GuidPageView />
    </GuidPageContext.Provider>
  );
}
