// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import { IData } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleBadgeDesign from '../GoogleBadgeDesign';
import GoogleView from '../GoogleView';
import { UseWindowDimensions } from '../collage/utils';
import ProductsOverlay from '../Modals/components/ProductsOverlay';

const Stories = () => {
  const { showModal, albumData, clickMutate, setAlbumId, isGoogle, layout, shopifyData } = useDisplayWidgetContext();
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryData = data?.result?.layout;

  const responsive = {
    auto: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };
  const { width: screenWidth } = UseWindowDimensions();
  const mobileView = screenWidth < 500;

  const [containerWidth, setContainerWidth] = React.useState(0);

  React.useEffect(() => {
    // Calculate the container width dynamically
    const container = document.querySelector('.carousel-multi-container');
    if (container) {
      const computedStyle = window.getComputedStyle(container);
      const width = computedStyle.getPropertyValue('width');
      setContainerWidth(parseFloat(width));
    }
  }, [screenWidth]);

  // Calculate the number of items based on the container's width and item's width
  const itemWidth = 110; // Adjust this value as needed
  const items = Math.floor(containerWidth / itemWidth);
  if (isGoogle) {
    return (
      <React.Fragment>
        <style>{`
      .idk-carousel-item{
        padding: 0 ${parseInt(layout?.galleryInformation?.postSpacing) / 2}px;
      }`}</style>
        <idk-div
          style={{
            gap: `${layout?.galleryInformation?.postSpacing}px`,
            display: 'grid',
            gridTemplateColumns: `repeat(${mobileView ? 1 : 2},1fr)`,
          }}
        >
          {isGoogle && (
            <idk-div
              style={{
                flexGrow: 1,
                height: '100%',
              }}
            >
              <GoogleBadgeDesign metaJson={data?.result?.metaJson} />
            </idk-div>
          )}
          <idk-div
            style={{
              maxWidth:
                isGoogle && layout?.googleBadges?.showBadge
                  ? !mobileView
                    ? `calc(100vw - 350px)`
                    : `calc(100vw - 32px)`
                  : '100vw',
              height: '100%',
            }}
          >
            <Carousel
              responsive={responsive}
              containerClass={'carousel-multi-container'}
              autoPlay={galleryData.sliderSettings.autoPlay}
              infinite={true}
              autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
              customLeftArrow={<LeftArrow />}
              customRightArrow={<RightArrow />}
              itemClass="idk-carousel-item"
            >
              {albumData.map((row, id) => {
                return (
                  <idk-div
                    key={id}
                    class="zid-flex zid-items-center zid-justify-center"
                    style={{
                      height: '100%',
                      flexGrow: 1,
                      borderRadius: layout?.cardSettings?.cardBorder,
                    }}
                  >
                    <GoogleView data={row} />
                  </idk-div>
                );
              })}
            </Carousel>
          </idk-div>
        </idk-div>
      </React.Fragment>
    );
  }

  return (
    <>
      <style>
        {`.carouselItem{
            max-width: 110px;
            min-width: 110px;
            padding: 0 ${parseInt(layout?.galleryInformation?.postSpacing) / 2}px;
        }  
`}
      </style>
      <idk-div class="zid-mx-auto">
        <Carousel
          responsive={{ auto: { breakpoint: { max: 3000, min: 0 }, items } }}
          containerClass={'carousel-multi-container'}
          autoPlay={galleryData.sliderSettings.autoPlay}
          itemClass={'carouselItem'}
          autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
          customLeftArrow={<LeftArrow />}
          customRightArrow={<RightArrow />}
          arrows={true}
          swipeable={true}
          draggable={true}
        >
          {albumData.map((row, id) => {
            return (
              <idk-div
                class="zid-h-[96px] zid-w-[96px] zid-border-2 zid-rounded-full zid-p-1 zid-bg-white zid-flex zid-items-center zid-justify-center"
                //   class={`zid-w-[100%] zid-flex zid-items-center zid-justify-center zid-h-full`}
                style={{ border: `2px solid ${galleryData.cardSettings.cardBorderColor}` }}
                key={id}
                onClick={() => {
                  showModal(row, 'slider');
                  setAlbumId(row.id);
                  clickMutate(row.id);
                }}
              >
                <idk-div
                  class="zid-rounded-full zid-relative zid-bg-white zid-rounded-full zid-overflow-hidden zid-relative zid-w-full zid-h-full"
                  onClick={() => {
                    showModal(row, 'slider');
                    setAlbumId(id);
                  }}
                >
                  <HotspotMedia
                    layout={layout}
                    row={row}
                    playFunctionality={false}
                    style={{ borderRadius: '50%' }}
                    view={galleryData.galleryView}
                    autoPlay={layout?.videoSettings?.videoAutoPlay}
                  />
                  {row?.products?.length > 0 && layout.gallerySettings.showProducts && (
                    <ProductsOverlay shopifyData={shopifyData} row={row}></ProductsOverlay>
                  )}
                </idk-div>
              </idk-div>
            );
          })}
        </Carousel>
      </idk-div>
    </>
  );
};

export function LeftArrow({ ...props }) {
  const { onClick } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == 0) return <></>;

  return (
    <idk-div
      onClick={onClick}
      class={`zid-absolute zid-translate-y-[-50%] zid-top-[50%] zid-translate-x-[20px] zid-cursor-pointer zid-p-2 zid-rounded-full zid-bg-slate-800 zid-opacity-[0.7] zid-h-[36px] ${props?.className}`}
    >
      <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px" fill="white">
        <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
      </svg>
    </idk-div>
  );
}

export function RightArrow({ ...props }) {
  const { onClick, totalSlides } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == totalSlides - 1) return <></>;
  return (
    <idk-div
      onClick={onClick}
      class={`zid-absolute zid-translate-y-[-50%] zid-right-0 zid-top-[50%] zid-translate-x-[-20px] zid-z-[100] zid-cursor-pointer zid-p-2 zid-rounded-[99px] zid-bg-slate-800 zid-opacity-[0.7] zid-h-[36px] ${props?.className}`}
    >
      <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px" fill="white">
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg>
    </idk-div>
  );
}

export default Stories;
