import React, { useRef } from 'react';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import Cover from '../Cover';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import ReadMoreReadLess from '../../common/ReadMoreReadLess';

export default function FeedBox({ colData }) {
  const { showModal, clickMutate, setAlbumId, layout } = useDisplayWidgetContext();
  const ref = useRef(null);
  const [offsetWidth, setOffsetWidth] = React.useState('0');
  React.useEffect(() => {
    if (ref?.current) {
      setOffsetWidth(ref?.current?.offsetWidth);
    }
  }, [ref]);
  return (
    <idk-div
      class={`zid-flex zid-items-center zid-justify-center zid-flex-col zid-w-full zid-overflow-hidden`}
      onClick={() => {
        clickMutate(colData.id);
      }}
      style={{ borderRadius: layout?.cardSettings?.cardBorder }}
    >
      <idk-div
        class={`commonImageContainer zid-relative zid-overflow-hidden ${
          colData?.media_type == 'CAROUSEL_ALBUM' ? '' : 'zid-flex zid-items-center zid-justify-center'
        }`}
        style={{
          borderRadius: '5px 5px 0 0',
          width: colData?.media_type == 'CAROUSEL_ALBUM' ? offsetWidth + 'px' : '100%',
        }}
        onClick={() => {
          showModal(colData, 'slider');
          setAlbumId(colData.id);
        }}
      >
        <HotspotMedia
          layout={layout}
          row={colData}
          playFunctionality={false}
          autoPlay={layout?.videoSettings?.videoAutoPlay}
        />

        {/* <Cover
          onClick={() => {
            showModal(colData, 'slider');
            setAlbumId(colData.id);
          }}
          row={colData}
        /> */}
      </idk-div>
      <idk-div
        ref={ref}
        class="zid-bg-white zid-w-[100%] zid-text-[rgba(0,0,0,0.87)] zid-p-4 zid-flex zid-flex-col zid-justify-center zid-items-center"
      >
        {colData?.product_name && <h5 className="zid-text-[24px] zid-text-center">{colData?.product_name}</h5>}
        {colData?.timestamp && (
          <idk-p class="zid-text-[12px] zid-text-[#525660] zid-text-center">
            on{' '}
            {(window as any).moment &&
              colData?.timestamp &&
              (window as any).moment(colData?.timestamp).format('MMM DD, YYYY')}
          </idk-p>
        )}
        <idk-p class="zid-my-4 zid-text-[14px] zid-text-center">{colData?.description}</idk-p>
        <idk-p>
          <ReadMoreReadLess totalWords={30}>{colData?.caption}</ReadMoreReadLess>
        </idk-p>
      </idk-div>
    </idk-div>
  );
}
