import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleView from '../GoogleView';
import React, { useRef, useEffect, useState } from 'react';
import { UseWindowDimensions } from '../collage/utils';
import { convertHeightWidth } from '../../Helpers/Helpers';
import LinkedinView from '../LinkedinView';

const Premium2 = () => {
  const child1Ref = useRef(null);
  const [myHeight, setMyHeight] = useState(0);
  const {
    showModal,
    clickMutate,
    albumData,
    postSpacing,
    gridTemplateColumns,
    setAlbumId,
    isGoogle,
    layout,
    isLinkedIn,
  } = useDisplayWidgetContext();

  useEffect(() => {
    const containerElement = child1Ref.current;

    if (containerElement) {
      const childElements = Array.from(containerElement.children);

      childElements.sort((a, b) => {
        return b?.['clientHeight'] - a?.['clientHeight'];
      });

      let totalHeight = 0;
      for (let i = 0; i < Math.min(2, childElements.length); i++) {
        totalHeight += childElements[i]?.['clientHeight'];
      }

      setMyHeight(totalHeight + 10);
    }
  }, []);

  function calculateImageStyles(screenWidth: any) {
    const images = [
      {
        x: screenWidth * 1,
        y: screenWidth * 13,
        width: screenWidth * 5,
        height: screenWidth * 5,
      },
      {
        x: screenWidth * 8,
        y: screenWidth * 9,
        width: screenWidth * 9,
        height: screenWidth * 9,
      },
      {
        x: screenWidth * 19,
        y: screenWidth * 5,
        width: screenWidth * 13,
        height: screenWidth * 13,
      },
      {
        x: screenWidth * 34,
        y: screenWidth * 0,
        width: screenWidth * 18,
        height: screenWidth * 18,
      },
      {
        x: screenWidth * 54,
        y: screenWidth * 3,
        width: screenWidth * 15,
        height: screenWidth * 15,
      },
      {
        x: screenWidth * 71,
        y: screenWidth * 5,
        width: screenWidth * 13,
        height: screenWidth * 13,
      },
      {
        x: screenWidth * 86,
        y: screenWidth * 9,
        width: screenWidth * 9,
        height: screenWidth * 9,
      },

      {
        x: screenWidth * 5,
        y: screenWidth * 20,
        width: screenWidth * 9,
        height: screenWidth * 9,
      },
      {
        x: screenWidth * 16,
        y: screenWidth * 20,
        width: screenWidth * 13,
        height: screenWidth * 13,
      },
      {
        x: screenWidth * 31,
        y: screenWidth * 20,
        width: screenWidth * 15,
        height: screenWidth * 15,
      },
      {
        x: screenWidth * 48,
        y: screenWidth * 20,
        width: screenWidth * 18,
        height: screenWidth * 18,
      },
      {
        x: screenWidth * 68,
        y: screenWidth * 20,
        width: screenWidth * 13,
        height: screenWidth * 13,
      },
      {
        x: screenWidth * 83,
        y: screenWidth * 20,
        width: screenWidth * 9,
        height: screenWidth * 9,
      },
      {
        x: screenWidth * 94,
        y: screenWidth * 20,
        width: screenWidth * 5,
        height: screenWidth * 5,
      },
    ];

    // Loop through images and calculate dynamic styles
    const imagesWithStyles = images.map((image) => {
      const dynamicStyles = {
        transform: `translate(${image.x}px, ${image.y}px)`,
        width: `${image.width}px`,
        height: `${image.height}px`,
        borderRadius: '12%',
      };

      return {
        ...image,
        style: dynamicStyles,
      };
    });

    return imagesWithStyles;
  }

  const { width } = UseWindowDimensions();

  const imageData = calculateImageStyles(
    (convertHeightWidth(layout.gallerySettings.width, true) < 300 || width < 575.98
      ? 540
      : window.innerWidth < convertHeightWidth(layout.gallerySettings.width, true)
      ? window.innerWidth - 50
      : convertHeightWidth(layout.gallerySettings.width, true) - 50) / 100,
  );

  return (
    <React.Fragment>
      <idk-div
        style={{
          height: myHeight,
          minWidth: 300,
          transform: width < 575.98 ? 'translateX(-110px)' : 'none',
          maxWidth:
            convertHeightWidth(layout.gallerySettings.width, true) < 300 || width < 575.98
              ? 340
              : convertHeightWidth(layout.gallerySettings.width, true),
        }}
        class="zid-m-auto"
      >
        <idk-div class="zid-relative" ref={child1Ref}>
          {albumData?.length > 0 &&
            albumData.slice(0, 14).map((row: any, index: number) => {
              if (isGoogle) return <GoogleView key={index} data={row} />;
              if (isLinkedIn) return <LinkedinView key={index} data={row} />;
              return (
                <idk-div
                  key={index}
                  onClick={() => {
                    clickMutate(row.id);
                    showModal(row, 'slider');
                    setAlbumId(row?.id);
                  }}
                  style={{
                    transformOrigin: 'top-left',
                    height: layout.cardSettings.cardHeight,
                    borderRadius: layout.cardSettings.cardBorder,
                    boxShadow:
                      '0 119px 179px #5c57431c, 0 49.7154px 74.7819px #5c574314, 0 26.5802px 39.982px #5c574311, 0 14.9006px 22.4136px #5c57430e, 0 7.91362px 11.9037px #5c57430b, 0 3.29304px 4.95339px #5c574308',
                    ...imageData[index].style,
                  }}
                  class="zid-absolute zid-top-0 zid-left-0"
                >
                  <HotspotMedia
                    layout={layout}
                    row={row}
                    playFunctionality={false}
                    style={{ borderRadius: '5px' }}
                    view={layout.galleryView}
                    autoPlay={layout?.videoSettings?.videoAutoPlay}
                  />
                </idk-div>
              );
            })}
        </idk-div>
      </idk-div>
    </React.Fragment>
  );
};

export default Premium2;
