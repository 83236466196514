import React, { useEffect } from 'react';

const GenerateMeta = ({ ogProperties }) => {
  useEffect(() => {
    if (Array.isArray(ogProperties)) {
      ogProperties.forEach((property) => {
        const { name, content } = property;
        if (name && content) {
          const ogTag = document.createElement('meta');
          ogTag.setAttribute('property', name);
          ogTag.content = content;
          document.head.appendChild(ogTag);
        }
      });

      return () => {
        ogProperties.forEach((property) => {
          const { name } = property;
          if (name) {
            const existingTag = document.querySelector(`meta[property="${name}"]`);
            if (existingTag) {
              document.head.removeChild(existingTag);
            }
          }
        });
      };
    }
  }, [ogProperties]);

  return <></>;
};

export default GenerateMeta;
