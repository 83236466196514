// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { useState } from 'react';
import { IData } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import { UseWindowDimensions } from '../collage/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import ProductsOverlay from '../Modals/components/ProductsOverlay';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { GoUnmute, GoMute } from 'react-icons/go';
import { restructureCarouselAlbum } from '../../Helpers/Helpers';
import LinkedinView from '../LinkedinView';

const Frame = () => {
  const { showModal, albumData, clickMutate, setAlbumId, isGoogle, isLinkedIn, layout, shopifyData } =
    useDisplayWidgetContext();
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryData = data?.result?.layout;

  const { width: screenWidth } = UseWindowDimensions();
  const mobileView = screenWidth < 500;
  const [activeIndex, setActiveIndex] = React.useState(0);

  const [isMuted, setIsMuted] = useState(true);

  const updateHeight = () => {
    const ht: number = document.querySelector('.widgetContainer').offsetHeight;
    const wd: number = document.querySelector('.widgetContainer').offsetWidth;
    const short_video_container = document.querySelector('.short_video_container');
    short_video_container['style']['height'] = '100%';
    short_video_container['style']['max-height'] = ht + 'px';
    short_video_container['style']['width'] = '100%';
    short_video_container['style']['max-width'] = wd < 400 ? `${wd}px` : '400px';

    if (short_video_container) {
      const tempWrapper = short_video_container.querySelector('.swiper-wrapper');
      const tempSlide = short_video_container.querySelector('.swiper-slide');
      tempWrapper.style.height = ht + 'px';
      tempSlide.style.height = ht + 'px';
      tempWrapper.style.width = wd < 400 ? `${wd}px` : '400px';
      tempSlide.style.width = wd < 400 ? `${wd}px` : '400px';
    }
  };

  const handleSlide = (slider: any) => {
    setActiveIndex(slider.realIndex);
    const modalContainer = document.getElementById('short_video_container');
    const tempElement = modalContainer.querySelectorAll('.swiper-slide');
    const currentVideoElement = tempElement[slider.activeIndex].getElementsByTagName('video');
    const previousVideoElement = tempElement[slider.previousIndex].getElementsByTagName('video');

    if (currentVideoElement?.length > 0) {
      if (!isMuted) {
        currentVideoElement[0].muted = false;
      } else {
        currentVideoElement[0].muted = true;
      }
      currentVideoElement[0].currentTime = 0;
      currentVideoElement[0].play();
    }
    if (previousVideoElement?.length > 0) {
      previousVideoElement[0].pause();
    }
  };

  const fetchNextPost = () => {
    console.log('fetch more posts');
  };

  const handleMute = (index) => {
    const modalContainer = document.getElementById('short_video_container');
    const tempElement = modalContainer.querySelectorAll('.swiper-slide');
    const currentVideoElement = tempElement[index].getElementsByTagName('video');
    if (currentVideoElement.length > 0) {
      if (currentVideoElement[0].muted) {
        setIsMuted(false);
        currentVideoElement[0].muted = false;
      } else {
        setIsMuted(true);
        currentVideoElement[0].muted = true;
      }
    }
  };

  return (
    <>
      <style>{`.swiper{ width: 100%; height: 100%; max-width: fit-content; max-height: 100vh; }
      `}</style>
      <idk-div
        class="short_video_container  zid-w-max zid-flex zid-items-center zid-justify-center zid-mx-auto zid-relative"
        id="short_video_container"
      >
        <Swiper
          autoHeight={true}
          direction="vertical"
          onInit={() => {
            updateHeight();
          }}
          onSlideChange={(slider) => {
            updateHeight();
            handleSlide(slider);
            if (slider.isEnd) {
              fetchNextPost();
            }
          }}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
            disabledClass: 'swiper-button-disabled',
          }}
          modules={[Navigation]}
        >
          {restructureCarouselAlbum(albumData).map((row: any, index: number) => {
            return (
              <SwiperSlide key={index} className="zid-h-full zid-max-w-[400px] zid-rounded" style={{ height: '100%' }}>
                {isLinkedIn ? (
                  <LinkedinView key={index + 1} data={row} />
                ) : (
                  <>
                    <HotspotMedia
                      layout={layout}
                      row={row}
                      playFunctionality={true}
                      autoPlay={index < activeIndex + 3 ? layout?.videoSettings?.videoAutoPlay : false}
                      preload={index < activeIndex + 3 ? 'auto' : 'none'}
                      style={{ borderRadius: '10px', objectFit: 'cover' }}
                    />{' '}
                    {row?.products?.length > 0 && layout.gallerySettings.showProducts && (
                      <ProductsOverlay isOpen={true} shopifyData={shopifyData} row={row}></ProductsOverlay>
                    )}
                    <idk-div
                      class="zid-absolute zid-z-[100] zid-flex zid-gap-3 zid-flex-col zid-right-[10px]"
                      style={{ top: '50%', transform: 'translateY(-50%)' }}
                    >
                      <idk-div
                        style={{ backdropFilter: 'blur(10px)' }}
                        class="image-swiper-button-prev zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                      >
                        <MdKeyboardArrowUp color="#fff" fontSize={30} />
                      </idk-div>
                      <idk-div
                        style={{ backdropFilter: 'blur(10px)' }}
                        class="image-swiper-button-next zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                      >
                        <MdKeyboardArrowDown color="#fff" fontSize={30} />
                      </idk-div>
                    </idk-div>
                    {row.media_type == 'VIDEO' && (
                      <idk-div
                        class="zid-absolute zid-z-[100] zid-right-[10px] zid-cursor-pointer zid-bg-[#090a0b33] zid-rounded-full zid-h-[40px] zid-w-[40px] zid-flex zid-items-center zid-justify-center"
                        style={{ bottom: '25%' }}
                        onClick={() => handleMute(index)}
                      >
                        {isMuted ? <GoMute color="#fff" fontSize={24} /> : <GoUnmute color="#fff" fontSize={24} />}
                      </idk-div>
                    )}
                  </>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </idk-div>
    </>
  );
};

export default Frame;
