// MomentContext.js
import React, { createContext, useEffect, useState } from 'react';

const MomentContext = createContext();

const MomentProvider = ({ children }) => {
  const [momentInstance, setMomentInstance] = useState(null);
  const data = 'hello';

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js';
    script.async = true;

    script.onload = () => {
      setMomentInstance(window.moment);
    };

    script.onerror = (error) => {
      console.error('Error loading Moment.js:', error);
    };

    document.body.appendChild(script);

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <MomentContext.Provider value={{ momentInstance, data }}>{children}</MomentContext.Provider>;
};

export { MomentProvider, MomentContext };
