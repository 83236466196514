import axios from 'axios';
import { API_BASE_URL } from '../contants';

export default function useImpression(galleryId: string, version: string, fromRedis: any) {
  async function fetch(galleryId: string, userId: string): Promise<any> {
    const endpoint =
      API_BASE_URL() +
      `/analytics/impression/${galleryId}?userId=${userId}&lastActivePage=${window.location}&version=${version}`;
    return await axios.get(endpoint).then(({ data }) => data as any);
  }
  const mutate = (userId: string) => {
    if (!fromRedis) {
      fetch(galleryId, userId);
    }
  };

  return { mutate };
}
