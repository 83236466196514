import React from 'react';
import { useDisplayWidgetContext } from '../../DisplayWidget/utils/context';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { MdOutlineShoppingCart } from 'react-icons/md';

export default function WocommerceModalRight({ row, quantity, updateQuantity }) {
  const { addToCartLoader, productOverlayIndex, handleAddCartWocommerce } = useDisplayWidgetContext();

  if (row?.products?.length > 0) {
    return (
      <idk-div class="zid-flex zid-flex-col zid-justify-between zid-h-full">
        <idk-div class="zid-flex zid-items-center zid-gap-3 zid-p-4 zid-border-b">
          <idk-div class="zid-h-20 zid-rounded zid-w-20	zid-p-2 zid-border zid-bg-white zid-drop-shadow-md">
            <img
              src={
                row?.products?.[productOverlayIndex]?.images?.[0].image_url ||
                row?.products?.[productOverlayIndex]?.images?.[0]
              }
              loading="lazy"
              className="zid-w-full zid-h-full zid-object-contain	"
            ></img>
          </idk-div>
          <idk-div class="zid-flex zid-flex-col zid-gap-1">
            <idk-div class="zid-flex zid-items-center zid-gap-2">
              <b>{row?.products?.[productOverlayIndex]?.title}</b>
              <a
                href={
                  row.products?.[productOverlayIndex]?.product_url
                    ? row.products?.[productOverlayIndex]?.product_url
                    : window.location.origin + row.products?.[productOverlayIndex]?.url
                }
                target="_blank"
              >
                <HiOutlineExternalLink fontSize={20} />
              </a>
            </idk-div>
            <idk-div>
              {document?.getElementsByClassName('woocommerce-Price-currencySymbol')?.[0]?.innerHTML}
              {row.products?.[productOverlayIndex]?.variants?.[0]?.price}
              {/* {calculatePrice(parseFloat(selectedVariant?.price) / 100, parseFloat(shopifyData?.currency?.rate))} */}
            </idk-div>
          </idk-div>
        </idk-div>
        <idk-div class="zid-p-4 zid-border-b zid-flex zid-gap-3 zid-items-center">
          <b>Quantity :</b>
          <idk-div class="zid-flex zid-items-center zid-gap-2">
            <idk-div
              class={`zid-flex zid-items-center zid-px-2 zid-h-9 zid-bg-slate-300 ${
                quantity == 1 ? 'zid-opacity-60	' : ''
              } zid-rounded`}
            >
              <AiOutlineMinus
                onClick={() => {
                  if (quantity > 1) {
                    updateQuantity((prevState: number) => prevState - 1);
                  }
                }}
              />
            </idk-div>
            <idk-div class="zid-p-2 zid-bg-slate-300 zid-h-9 zid-rounded zid-w-10 zid-text-center zid-flex zid-items-center zid-justify-center">
              <b>{quantity}</b>
            </idk-div>
            <idk-div class={`zid-flex zid-items-center zid-px-2 zid-h-9 zid-bg-slate-300 zid-rounded`}>
              <AiOutlinePlus
                onClick={() => {
                  updateQuantity((prevState: number) => prevState + 1);
                }}
              />
            </idk-div>
          </idk-div>
        </idk-div>
        <idk-div class="zid-flex zid-gap-3 zid-items-center zid-justify-center zid-p-4">
          <button
            className="zid-text-base zid-text-white zid-py-2 zid-w-60 zid-rounded zid-bg-black zid-border zid-border-black zid-cursor-pointer zid-h-full"
            onClick={() => handleAddCartWocommerce(quantity, row.products?.[productOverlayIndex]?.id_from_product)}
          >
            {addToCartLoader ? (
              <idk-div role="status">
                <svg
                  aria-hidden="true"
                  className="zid-inline zid-w-6 zid-h-6 zid-mr-2 zid-text-gray-200 zid-animate-spin dark:zid-text-gray-600 zid-fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </idk-div>
            ) : (
              'Add to cart'
            )}
          </button>
          <a
            className="zid-text-base zid-w-16 zid-flex zid-justify-center zid-items-center zid-gap-2 zid-py-2 zid-border zid-border-black	zid-rounded zid-relative"
            target="_blank"
            href={'/cart'}
          >
            <MdOutlineShoppingCart fontSize={24} color="#000000" />
          </a>
        </idk-div>
        <idk-div class="zid-p-4 zid-h-full zid-border-b zid-flex-1 zid-overflow-auto">
          {row?.products?.[productOverlayIndex]?.description && (
            <div dangerouslySetInnerHTML={{ __html: `${row?.products?.[productOverlayIndex]?.description}` }}></div>
          )}
        </idk-div>
      </idk-div>
    );
  }
}
