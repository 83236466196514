import React from 'react';
import { BsStar, BsStarFill } from 'react-icons/bs';
import Rating from 'react-rating';
export default function GoogleRating({ rating = 4, size = '20px', color = '#fbbc04' }) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Rating
      {...{
        emptySymbol: <BsStar style={{ fontSize: size, marginRight: '5px', color: color }}>star_border</BsStar>,
        fullSymbol: <BsStarFill style={{ fontSize: size, marginRight: '5px', color: color }}>star_rate</BsStarFill>,
        fractions: 2,
      }}
      initialRating={rating}
      readonly
    />
  );
}
