import axios from 'axios';
import React from 'react';
import { API_BASE_URL } from '../contants';

export default function useNotifyUpload() {
  const [data, setData] = React.useState(null);
  const fetch = (payload) => {
    const endpoint = API_BASE_URL() + '/business/widget-upload-success';
    axios.post(endpoint, payload).then(({ data }) => {
      setData(data);
    });
  };
  return { mutate: fetch, data };
}
