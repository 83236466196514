import axios from 'axios';
import React from 'react';
import { API_BASE_URL } from '../contants';
import { setSubscriptionData } from '../utils/sharedData';

export default function useGetBusiness(setGetSubsError, bguid) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const fetch = () => {
    setIsLoading(true);
    if (bguid) {
      const endpoint = API_BASE_URL() + '/business/active-subscription?bguid=' + bguid;
      axios
        .get(endpoint)
        .then(({ data }) => {
          setIsLoading(false);
          setData(data?.data);
          setSubscriptionData(data);
        })
        .catch((err) => {
          setIsLoading(false);
          const { response } = err;
          setGetSubsError(response?.data?.message);
        });
    }
  };
  React.useEffect(() => {
    fetch();
  }, []);
  return { isLoading, data };
}
