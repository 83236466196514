import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import { UploadMedia } from './pages/UploadMedia';
import Video from './Video';

export default function Router({ data }) {
  const urlParams = new URLSearchParams(window.location.search);
  const bguid = urlParams.get('bguid');
  const oguid = urlParams.get('oguid');
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/video">
          <Video />
        </Route>
        <Route exact path="/upload">
          <idk-div class="zid-flex zid-w-full zid-justify-center">
            <UploadMedia bguid={bguid} oguid={oguid} />
          </idk-div>
        </Route>
        <Route path="/">
          <App data={data} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
