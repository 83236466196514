import CustomModal from '../../../components/CustomModal';
import CloseIcon from '../../../icons/CloseIcon';
import { check_gif } from '../../../utils/assests';
import { useUploadMediaContext } from '../utils/context';

export default function SucessModal({ isOpen, onClose }) {
  const { uploadMediaClose, setUploadLoading } = useUploadMediaContext();
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <idk-div class="zid-bg-[#fafaf7] zid-w-[93vw] zid-max-w-[400px] zid-pt-[40px] zid-p-[16px] zid-flex zid-flex-col zid-justify-center zid-items-center zid-relative">
        <idk-div class="zid-cursor-pointer zid-fixed zid-top-2 zid-right-2" onClick={() => onClose()}>
          <CloseIcon width="25px" height="25px" />
        </idk-div>
        <idk-div class="zid-flex zid-flex-col zid-justify-center zid-items-center zid-w-full zid-gap-4">
          <idk-div class="zid-flex zid-flex-col zid-gap-4 zid-w-[80%]">
            <idk-div class="zid-flex zid-justify-center">
              <img src={check_gif} width="80px" height="80px" loading="lazy" />
            </idk-div>
            <idk-p class="zid-text-center zid-text-[14px] sm:zid-text-[16px]">
              Your media has been uploaded successfully.
            </idk-p>
          </idk-div>
          <idk-div class="zid-w-full">
            <button
              className="zid-px-6 zid-py-2 zid-border-black zid-border-[1px] zid-bg-blue-700 zid-text-white zid-cursor-pointer zid-rounded-md zid-w-full"
              onClick={() => {
                if (uploadMediaClose) {
                  uploadMediaClose();
                } else {
                  window.location.reload();
                }
                onClose();
                setUploadLoading(false);
              }}
            >
              Close
            </button>
          </idk-div>
        </idk-div>
      </idk-div>
    </CustomModal>
  );
}
