import React from 'react';
import CloseIcon from '../../icons/CloseIcon';
import TickIcon from '../../icons/TickIcon';
import CustomModal from '../CustomModal';

export default function CongractsModal({ isOpen, onClose }) {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <idk-div class="zid-bg-white zid-w-[93vw] zid-max-w-[400px] zid-pt-[40px] zid-p-[16px] zid-flex zid-flex-col zid-justify-center zid-items-center zid-relative">
        <idk-div class="zid-cursor-pointer zid-fixed zid-top-2 zid-right-2" onClick={() => onClose()}>
          <CloseIcon width="25px" height="25px" />
        </idk-div>
        <idk-div class="zid-flex zid-flex-col zid-justify-center zid-items-center zid-w-full zid-gap-4">
          <idk-div class="zid-flex zid-flex-col zid-gap-4 zid-w-[80%]">
            <idk-div class="zid-flex zid-justify-center">
              <TickIcon width="80px" height="80px" />
            </idk-div>
            <idk-p class="zid-text-center zid-text-[14px] sm:zid-text-[16px]">
              Thanks for you feedback. We have noted and working on it!
            </idk-p>
          </idk-div>
          <idk-div class="zid-w-full">
            <button
              className="zid-px-6 zid-py-2 zid-border-black zid-border-[1px] zid-bg-blue-700 zid-text-white zid-cursor-pointer zid-rounded-md zid-w-full"
              onClick={() => onClose()}
            >
              Continue
            </button>
          </idk-div>
        </idk-div>
      </idk-div>
    </CustomModal>
  );
}
