import React, { useEffect, useRef, useState } from 'react';
import ErrorScreen from '../../common/ErrorScreen';
import useClick from '../../hooks/useClick';
import useImpression from '../../hooks/useImpression';
import usePostView from '../../hooks/usePostView';
import useWidget from '../../hooks/useWidget';
import CongractsModal from '../Modals/CongratsModal';
import ReportModal from '../Modals/ReportModal';
import Modal from '../Modals/modal/modal';
import UploadModal from '../UploadModal';
import { UseWindowDimensions, twitter } from '../collage/utils';
import DisplayWidgetView from './DisplayWidget.view';
import { DisplayWidgetContext } from './utils/context';
import { MomentProvider } from '../../common/MomentContext';
import axios from 'axios';
export interface IAlbumData {
  albumId: number;
  feed: [
    {
      id: string;
      caption: string;
      media_url: [string];
      like_count: number;
      owner: {
        name: string;
      };
      thumbnail_url: string;
      height: number;
      width: number;
    },
  ];
}

export interface IView {
  widgetScript: any;
  widgetHTML: any;
  galleryView: string;
  slider: boolean;
  sliderSettings: {
    autoPlay: boolean;
    autoPlaySpeed: number;
  };
  collageSettings: {
    collageLayout: 'columns' | 'rows' | 'masonry';
    collageColumns: number;
    collageRowHeight: number;
    collageSpacing: number;
    collagePadding: number;
  };
  headerTitle: {
    title: string;
    fontFamily: string;
    alignment: 'center' | 'left' | 'right';
    desktopFont: string;
    mobileFont: string;
    textColor: string;
    subTitle: string;
  };
  headerButton: {
    followUs: boolean;
    headerButtonText: string;
    headerButtonColor: string;
    headerButtonUrl: string;
    headerButtonTextColor: string;
  };
  googleSummaryOptions: {
    showSummaryChart: boolean;
    showButton: boolean;
    buttonText: string;
    buttonColor: string;
    buttonTextColor: string;
    buttonUrl: string;
    showCtaAsLink: boolean;
    buttonAlignment: string;
    chartAlignment: string;
  };
  googleBadges: {
    starColor: string;
    border: any;
    bgColor: string;
    fontColor: string;
    badgeAlignment: string;
    showBadge: boolean;
    badgeType: string;
    positionTop: boolean;
  };
  googlePhoto: {
    showPhoto: boolean;
    photoPositionTop: boolean;
    showNameAndAddress: boolean;
    padding: string;
    items: number;
  };
  galleryInformation: {
    name: string;
    postPerPage: number;
    columns: string;
    pagination: string;
    postSpacing: string;
  };
  galleryColors: {
    stars: string;
    verifyBadge: string;
    ugcText: string;
    dateTime: string;
    bgColor: string;
  };
  gallerySiteUploads: {
    addButtonText: string;
    siteUploadButtonColor: string;
    siteUploadButtonTextColor: string;
    buttonShow: string;
  };
  gallerySettings: {
    height: string;
    width: string;
    poweredBy: boolean;
    scrollInterval: string;
    scrollSpeed: string;
    tiltAngle: string;
    customCSS: string;
  };
  banners: {
    bannerBottomUrl: any;
    bannerTopUrl: any;
    bannerTop: string;
    bannerBottom: '';
  };
}

export interface IData {
  status: true;
  result: {
    metaJson: any;
    id: number;
    business_id: number;
    name: string;
    layout: IView;
    guid: string;
    isActive: true;
    albums: number[];
    createdAt: string;
    updatedAt: string;
    data: [any];
  };
  page: 1;
  totalPage: 3;
}
export default function DisplayWidgetContainer({ props }) {
  const containerRef = useRef(null);
  const { bid, guid, galleryId, pcat, pid, bguid, version, fromRedis } = props;
  const [error, setError] = useState('');
  const {
    data,
    isLoading,
    album,
    page,
    setPage,
    totalPages,
  }: { data: IData; isLoading: boolean; album: any; page: number; setPage: any; totalPages: number } = useWidget(
    bid,
    guid,
    pcat,
    pid,
    setError,
    fromRedis,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setData] = useState({});
  const [isShopify, setIsShopify] = useState(false);
  const [shopifyData, setShopifyData] = useState(null);
  const [type, setType] = useState('');
  const [quantity, setQuantity] = useState(1);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const [isCongratsModal, setIsCongratsModal] = useState(false);

  const [widgetHTML, setWidgetHTML] = useState(null);
  const [widgetScript, setWidgetScript] = useState(null);

  const [reportModalData, setReportModalData] = useState(null);

  const [albumData, setAlbumData] = useState([]);

  const [userId, setUserId] = useState(null);

  const [width, setWidth] = useState(null);
  const [desktopfont, setDesktopfont] = useState(null);

  const { mutate } = useImpression(guid, version, fromRedis);

  const { mutate: clickMutate } = useClick(userId, guid, fromRedis);
  const { mutate: postViewMutate } = usePostView(userId, guid, fromRedis);

  const [isGoogle, setIsGoogle] = useState(false);
  const [isLinkedIn, setIsLinkedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addToCartLoader, setAddToCartLoader] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [productOverlayIndex, setProductOverlayIndex] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);

  const specialSources = ['TrustPilot', 'Google_Review'];

  useEffect(() => {
    const idukki_widget = localStorage.getItem('idukki-widget');
    if (!idukki_widget) {
      const userId = crypto.randomUUID();
      setUserId(userId);
      localStorage.setItem('idukki-widget', userId);
    } else {
      setUserId(idukki_widget);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      mutate(userId);
    }
  }, [userId]);

  useEffect(() => {
    const ids = [];
    if (albumData.length != 0 && userId) {
      albumData.map((row) => {
        ids.push(row.id);
      });
      postViewMutate(ids);
    }
  }, [albumData, userId]);

  const isWooCommerceSite = () => {
    // Check for WooCommerce container elements or classes
    const containerSelectors = ['.product', '.cart', '.checkout', '.shop'];

    for (const selector of containerSelectors) {
      if (document.querySelector(selector)) {
        return true;
      }
    }

    // Check for WooCommerce-specific data attributes
    const dataAttributes = ['data-product_id', 'data-product_sku', 'data-product_type'];

    for (const attribute of dataAttributes) {
      if (document.querySelector(`[${attribute}]`)) {
        return true;
      }
    }

    // Check for WooCommerce scripts
    const scripts: any = document.querySelectorAll('script');
    for (const script of scripts) {
      if (script.textContent.includes('WooCommerce') || script.src.includes('woocommerce')) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!isLoading && data) {
      setLoader(false);
      const layout = data?.result?.layout;
      if (layout?.widgetHTML) setWidgetHTML(layout?.widgetHTML);
      if (layout?.widgetScript) {
        if (layout?.galleryView?.toUpperCase() === twitter) {
          setWidgetScript('https://platform.twitter.com/widgets.js');
        } else {
          setWidgetScript('https://assets.pinterest.com/js/pinit.js');
        }
      }
      if (layout?.galleryInformation) {
        const { headerTitle, galleryInformation } = layout;
        if (layout?.galleryInformation?.pagination == 'pageNumbers') setAlbumData(album.data);
        else if (
          layout?.galleryInformation?.pagination == 'autoScroll' ||
          layout?.galleryInformation?.pagination == 'loadMore'
        )
          setAlbumData([...albumData, ...album.data]);
        else setAlbumData([...album.data]);
        const column = galleryInformation.columns;
        const desktopfont = headerTitle.desktopFont;
        setDesktopfont(desktopfont);
        if (data?.result?.data?.[0]?.source === 'Linkedin') setIsLinkedIn(true);
        if (specialSources.includes(data?.result?.data?.[0]?.source)) {
          setIsGoogle(true);
        }
        if (window && window['Shopify']) {
          setShopifyData(window['Shopify']);
          setIsShopify(true);
        }

        console.log('type', data?.result?.data?.[0]?.source);

        const width =
          column == '100%'
            ? 'md:zid-w-[100%]'
            : column == '50%'
            ? 'md:zid-w-[50%]'
            : column == '33.3%'
            ? 'md:zid-w-[33.3%]'
            : column == '25%'
            ? 'md:zid-w-[25%]'
            : 'md:zid-w-[20%]';
        setWidth(width);
      }
    }
  }, [data]);

  useEffect(() => {
    if (window && window['Shopify']) {
      setShopifyData(window['Shopify']);
      setIsShopify(true);
    }
  }, []);

  useEffect(() => {
    setLoader(true);
  }, [page]);

  const handleLoadMoreCTA = () => {
    if (!loader)
      setPage((page) => {
        if (page < totalPages) {
          setLoader(true);
          return page + 1;
        }
        return page;
      });
  };

  const showModal = (row: any, type: string) => {
    setIsOpen(true);
    setData(row);
    if (row?.media_url?.length > 1) {
      setType(type);
    } else setType('');
  };

  const onUploadModalClose = () => {
    setIsUploadModalOpen(false);
  };

  const onCongratsModalOpen = () => {
    setIsCongratsModal(true);
  };

  const onCongratsModalClose = () => {
    setIsCongratsModal(false);
  };

  const onReportModalOpen = () => {
    setIsReportModalOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onReportModalClose = () => {
    setIsReportModalOpen(false);
  };
  const fetchNextPost = () => {
    if (totalPages > page) {
      setPage((prevState) => prevState + 1);
    }
  };

  const layout: IView = data?.result?.layout;
  const buttonColor = layout?.headerButton?.headerButtonColor;

  const gridCol = parseInt(data?.result?.layout?.galleryInformation?.columns);

  const { width: screenWidth } = UseWindowDimensions();

  const layout_gap = parseInt(data?.result?.layout?.galleryInformation?.postSpacing?.split('px')[0]);
  const gridTemplateColumns = `repeat(${screenWidth >= 860 ? gridCol : screenWidth >= 600 ? 2 : 1},1fr)`;

  useEffect(() => {
    if (layout?.galleryInformation?.pagination == 'autoScroll') {
      if (containerRef && containerRef.current) {
        containerRef.current.addEventListener('scroll', () => {
          if (containerRef.current.offsetTop + containerRef.current.scrollHeight >= containerRef.current.scrollHeight) {
            handleLoadMoreCTA();
          }
        });
      }
    }
  }, [containerRef, containerRef?.current]);

  const [albumId, setAlbumId] = useState(-1);

  useEffect(() => {
    if (widgetScript) {
      const script = document.createElement('script');
      script.async = true;
      script.src = widgetScript;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, [widgetScript]);

  const getCartCount = () => {
    axios.get(`${window.location.origin}/cart.json`).then((response) => {
      if (response.status == 200) {
        setCartCount(response.data.item_count);
      }
    });
  };

  useEffect(() => {
    if (isShopify) {
      getCartCount();
    }
  }, [isShopify]);

  const handleAddCart = (quantity) => {
    setAddToCartLoader(true);
    axios
      .post(`${window.location.origin}/cart/add.js`, {
        items: [
          {
            quantity: quantity,
            id: selectedVariant.id,
          },
        ],
      })
      .then(() => {
        setAddToCartLoader(false);
        getCartCount();
      });
  };

  const handleAddCartWocommerce = (quantity, product_id) => {
    setAddToCartLoader(true);
    if ('undefined' === typeof window['wc_add_to_cart_params']) {
      setAddToCartLoader(false);
      // The add to cart params are not present.
      return false;
    }

    const data = {
      product_id: product_id,
      quantity: quantity,
    };

    window['jQuery'].post(
      window['wc_add_to_cart_params'].wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'),
      data,
      function (response) {
        setAddToCartLoader(false);
        if (!response) {
          return;
        }

        // This redirects the user to the product url if for example options are needed ( in a variable product ).
        // You can remove this if it's not the case.
        if (response.error && response.product_url) {
          window.location = response.product_url;
          return;
        }

        // Remove this if you never want this action redirect.
        if (window['wc_add_to_cart_params'].cart_redirect_after_add === 'yes') {
          window.location = window['wc_add_to_cart_params'].cart_url;
          return;
        }
        window['jQuery'](document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
      },
    );
  };

  if (error) {
    <ErrorScreen error={error} />;
  }

  if (isLoading)
    return (
      <idk-div class="zid-py-6 zid-max-w-[1440px] zid-h-[290px] zid-flex zid-justify-center zid-items-center zid-mx-auto zid-gap-6 zid-px-8">
        {window.innerWidth < 576.98 ? (
          <idk-div class="zid-bg-gray-500 pulsate zid-h-[100%] zid-w-[100%] zid-rounded-lg zid-flex zid-justify-center zid-items-center zid-text-[25px] zid-text-white" />
        ) : (
          Array.from({ length: 3 }, (_, id) => (
            <idk-div
              key={id}
              class="zid-bg-gray-500 pulsate zid-h-[100%] zid-w-[100%] zid-rounded-lg zid-flex zid-justify-center zid-items-center zid-text-[25px] zid-text-white"
            />
          ))
        )}
      </idk-div>
    );

  if (layout?.galleryView == 'PINTEREST' || layout?.galleryView == 'TWITTER') {
    return <idk-div dangerouslySetInnerHTML={{ __html: widgetHTML }} />;
  }
  if (data) {
    return (
      <MomentProvider>
        <DisplayWidgetContext.Provider
          value={{
            isLoading,
            data,
            width,
            desktopfont,
            showModal,
            albumData,
            setAlbumData,
            setReportModalData,
            onReportModalOpen,
            onCongratsModalOpen,
            setIsUploadModalOpen,
            handleLoadMoreCTA,
            page,
            setPage,
            clickMutate,
            bid,
            galleryId,
            buttonColor,
            bguid,
            headerColor: layout?.headerTitle?.textColor,
            columns: data?.result?.layout?.galleryInformation.columns,
            postSpacing: layout_gap,
            gridCol,
            gridTemplateColumns,
            totalPages,
            ref: containerRef,
            loader,
            setLoader,
            layout,
            setAlbumId,
            albumId,
            modalData,
            isGoogle,
            isLinkedIn,
            isShopify,
            shopifyData,
            cartCount,
            getCartCount,
            handleAddCart,
            handleAddCartWocommerce,
            quantity,
            setQuantity,
            guid,
            addToCartLoader,
            selectedVariant,
            setSelectedVariant,
            productOverlayIndex,
            setProductOverlayIndex,
            fetchNextPost,
            isWooCommerceSite,
          }}
        >
          <style>{`${layout.gallerySettings.customCSS}`}</style>
          <DisplayWidgetView />
          {isOpen && <Modal type={type} isOpen={isOpen} onClose={onClose} />}
          {isReportModalOpen && (
            <ReportModal isOpen={isReportModalOpen} onClose={onReportModalClose} data={reportModalData} />
          )}
          {isCongratsModal && <CongractsModal isOpen={isCongratsModal} onClose={onCongratsModalClose} />}
          {isUploadModalOpen && <UploadModal isOpen={isUploadModalOpen} onClose={onUploadModalClose} />}
        </DisplayWidgetContext.Provider>
      </MomentProvider>
    );
  }

  return <ErrorScreen />;
}
