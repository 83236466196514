import { AiFillStar } from 'react-icons/ai';
import { IData, IView } from './DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';
import GoogleRating from './GoogleRating';
export default function GoogleSummary() {
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;

  const totalReviews = data?.result?.metaJson?.[0]?.totalReview;

  const convertPercentage = (value: number, total: number) => {
    if (value && total) {
      const percentage = (value / total) * 100;
      if (total === 0) {
        return 0 + '%';
      }
      return percentage.toFixed(1) + '%';
    }
    return '0%';
  };

  return (
    <>
      {galleryLayout?.googleSummaryOptions?.showSummaryChart ? (
        <idk-div
          class="zid-w-full zid-gap-3 zid-my-2"
          style={{ justifyContent: galleryLayout?.googleSummaryOptions?.chartAlignment }}
        >
          <idk-div class="zid-mb-2">
            <idk-p style={{ fontSize: '20px', fontWeight: 700 }}>Customer Reviews</idk-p>
            <idk-div class="zid-flex zid-items-center zid-gap-2" style={{ flexWrap: 'wrap' }}>
              <GoogleRating size={'20px'} rating={4} />
              <idk-p style={{ margin: 2, fontSize: '14px' }}>Based on {totalReviews} reviews</idk-p>
            </idk-div>
          </idk-div>
          <idk-div class="zid-flex zid-flex-col zid-w-full lg:zid-w-96">
            <StarRating
              rating={5}
              percentage={convertPercentage(data?.result?.metaJson?.[0]?.reviews_per_rating?.['5'], totalReviews)}
            />
            <StarRating
              rating={4}
              percentage={convertPercentage(data?.result?.metaJson?.[0]?.reviews_per_rating?.['4'], totalReviews)}
            />
            <StarRating
              rating={3}
              percentage={convertPercentage(data?.result?.metaJson?.[0]?.reviews_per_rating?.['3'], totalReviews)}
            />
            <StarRating
              rating={2}
              percentage={convertPercentage(data?.result?.metaJson?.[0]?.reviews_per_rating?.['2'], totalReviews)}
            />
            <StarRating
              rating={1}
              percentage={convertPercentage(data?.result?.metaJson?.[0]?.reviews_per_rating?.['1'], totalReviews)}
            />
          </idk-div>
        </idk-div>
      ) : (
        <></>
      )}
    </>
  );
}

const StarRating = ({ rating, percentage }) => {
  return (
    <idk-div class="zid-flex zid-items-center zid-gap-2">
      <idk-p style={{ margin: 2, fontSize: '14px' }}>{rating}</idk-p>
      <AiFillStar size={20} color="#fbbc04" />
      <idk-div
        style={{
          borderRadius: 10,
          flex: 1,
          height: '10px',
          backgroundColor: '#f1f3f4',
          border: '1px solid #EAEDED',
        }}
      >
        <idk-div style={{ width: percentage, height: '100%', backgroundColor: '#fbbc04', borderRadius: 10 }} />
      </idk-div>
      <idk-p style={{ margin: 2, fontSize: '14px', textAlign: 'right', width: '30px' }}>{percentage}</idk-p>
    </idk-div>
  );
};
