import React from 'react';

export default function ErrorScreen({ error = '' }) {
  console.log(error ? error : 'Error ! invalid data. contact support@idukki.io');
  return <></>;
  return (
    <idk-div class="zid-flex zid-justify-center zid-items-center zid-mt-[20px]">
      {error ? (
        <idk-p class="zid-text-[24px] zid-font-bold">{error}</idk-p>
      ) : (
        <idk-p class="zid-text-[24px] zid-font-bold">
          Error ! invalid data. contact{' '}
          <a className="zid-underline zid-text-blue-500" href="mailto:support@idukki.io">
            support@idukki.io
          </a>
        </idk-p>
      )}
    </idk-div>
  );
}
