import React from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { UseWindowDimensions } from '../../collage/utils';

export default function PageNumber({ page, setPage, totalPages }) {
  const nextPageHandler = () => {
    handler(page + 1);
  };
  const prevPageHandler = () => {
    handler(page - 1);
  };
  const [pageButtons, setPageButtons] = React.useState([]);
  React.useEffect(() => {
    if (totalPages > 6) {
      let arr = [];
      if (page <= 2) arr = [1, 2, 3, '...', totalPages];
      else if (page >= totalPages - 1) arr = [1, '...', totalPages - 2, totalPages - 1, totalPages];
      else {
        if (page == 3) arr = [1, 2, 3, 4, '...', totalPages];
        else if (page === totalPages - 2) arr = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        else {
          arr = [1, '...', page - 1, page, page + 1, '...', totalPages];
        }
      }
      setPageButtons(arr);
    } else {
      const arr = [];
      Array.from({ length: totalPages }, (v, i) => i + 1).map((number) => {
        arr.push(number);
      });
      setPageButtons(arr);
    }
  }, [page, totalPages]);

  function handler(pageNum) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(pageNum);
  }

  const { width } = UseWindowDimensions();

  if (totalPages <= 1) return <></>;
  return (
    <idk-div class="zid-w-full zid-mx-[20px] zid-mb-[10px]">
      <idk-div
        style={{
          marginTop: '18px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: width > 600 ? 'row' : 'column',
        }}
      >
        {/* <p
          style={{
            fontSize: '14px',
          }}
        >
          Page {page} of {totalPages}
        </idk-p> */}
        <idk-div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PrevButton
            page={page}
            onClick={() => {
              if (page > 1) prevPageHandler();
            }}
          />
          <idk-div
            style={{
              margin: '0 8px',
              display: 'flex',
            }}
          >
            {pageButtons.map((pageNum, id) => {
              return (
                <NumButton
                  page={page}
                  pageNum={pageNum}
                  key={id}
                  onClick={() => {
                    if (pageNum != '...') handler(pageNum);
                  }}
                />
              );
            })}
          </idk-div>
          <NextButton
            onClick={() => {
              if (page < totalPages) nextPageHandler();
            }}
            page={page}
            totalPages={totalPages}
          />
        </idk-div>
      </idk-div>
    </idk-div>
  );
}

const NumButton = ({ page, pageNum, onClick }) => {
  return (
    <button
      style={{
        width: '40px',
        height: '40px',
        fontWeight: 'normal',
        border: 'none',
        borderRadius: '99px',
        color: page == pageNum ? '#099' : 'inherit',
        backgroundColor: page == pageNum ? '#fff' : 'inherit',
        fontSize: page == pageNum ? '14px' : 'inherit',
        cursor: 'pointer',
      }}
      className="hover:zid-bg-[#fff] zid-text-[#099]"
      onClick={onClick}
    >
      {pageNum}
    </button>
  );
};

function NextButton({ onClick, page, totalPages }) {
  return (
    <button
      style={{
        cursor: page >= totalPages ? 'not-allowed' : 'pointer',
      }}
      onClick={onClick}
      className="zid-bg-white zid-m-2 zid-rounded-[99px] zid-p-2 "
    >
      <BsChevronRight />
    </button>
  );
}

function PrevButton({ onClick, page }) {
  return (
    <button
      style={{
        cursor: page <= 1 ? 'not-allowed' : 'pointer',
      }}
      onClick={onClick}
      className="zid-bg-white zid-m-2 zid-rounded-[99px] zid-p-2 "
    >
      <BsChevronLeft />
    </button>
  );
}
