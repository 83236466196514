import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import useReportMedia from '../../hooks/useReportMedia';
import CloseIcon from '../../icons/CloseIcon';
import { validateEmail } from '../../utils';
import { Captcha } from '../Captcha';
import CustomModal from '../CustomModal';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import Media from '../Media';

export default function ReportModal({ isOpen, onClose, data }) {
  const [email, setEmail] = React.useState({ value: '', error: '' });
  const [reason, setReason] = React.useState({ value: '', error: '' });
  const [captcha, setCaptcha] = React.useState({ value: '', error: '' });

  React.useEffect(() => {
    setEmail({ value: '', error: '' });
    setReason({ value: '', error: '' });
    setCaptcha({ value: '', error: '' });
  }, [isOpen]);

  const { onCongratsModalOpen, bguid } = useDisplayWidgetContext();
  const { mutate, isLoading } = useReportMedia(onClose, onCongratsModalOpen);
  const handleClick = () => {
    const emailValid = validateEmail(email.value);

    if (email.value && emailValid && reason.value && captcha.value) {
      mutate({
        reporter_email: email.value,
        reason: reason.value,
        media_id: data?.id,
        bguid: bguid,
        captchaToken: captcha.value,
      });
    } else {
      if (email.value == '') setEmail({ ...email, error: 'Please enter your email' });
      else {
        if (!emailValid) setEmail({ ...email, error: 'Email is not valid' });
      }
      if (reason.value == '') setReason({ ...reason, error: 'Please enter your reason' });
      if (captcha.value == '') setCaptcha({ ...captcha, error: 'Verify Captcha' });
    }
  };

  if (isOpen)
    return (
      <CustomModal isOpen={isOpen} onClose={onClose}>
        <idk-div class="zid-w-[93vw] zid-max-w-[400px] zid-max-h-[90vh] md:zid-w-[400px] md:zid-max-h-[700px] zid-bg-white zid-overflow-y-scroll">
          <idk-div class="zid-w-full zid-h-full zid-flex zid-flex-col zid-justify-center zid-items-center zid-relative zid-py-[40px] zid-px-[20px] zid-gap-[30px]">
            <idk-div class="zid-cursor-pointer zid-fixed zid-top-2 zid-right-2" onClick={() => onClose()}>
              <CloseIcon width="25px" height="25px" />
            </idk-div>
            <idk-div class="zid-flex zid-flex-col zid-gap-[20px] zid-justify-center zid-items-center">
              <idk-div class="zid-text-black zid-font-[700] sm:zid-text-[24px] sm:zid-leading-[32px] zid-text-[18px] zid-leading-[24px]">
                Report this media?
              </idk-div>
              <idk-div class="zid-text-black zid-font-[400] zid-text-[12px] zid-leading-[18px]  sm:zid-text-[14px] sm:zid-leading-[20px]">
                <idk-p>
                  {
                    "This means it is inappropriate, it has violated a law, or it infrinfes upon someone's right. Reporting this media will flag it for further review."
                  }
                </idk-p>
              </idk-div>
            </idk-div>
            <idk-div class="zid-w-[60%] zid-border-b-gray-400 zid-border-[2px]">
              <Media row={data} width="100%" style={{ objectFit: 'contain', maxHeight: '400px', width: 'auto' }} />
            </idk-div>

            <idk-div class="zid-flex zid-flex-col zid-gap-[40px] zid-justify-center zid-items-center zid-w-full">
              <idk-div class="zid-self-start zid-w-full zid-flex zid-flex-col zid-gap-4 sm:zid-text-[12px] sm:zid-leading-[18px] zid-text-[10px] zid-leading-[16px]">
                <idk-div class="zid-flex zid-flex-col zid-gap-2 zid-font-[700]">
                  <idk-div>Email:</idk-div>
                  <idk-div class="zid-flex zid-flex-col">
                    <input
                      value={email.value}
                      onChange={(e) => setEmail({ value: e.target.value, error: '' })}
                      placeholder={'me@email.com'}
                      className={`${
                        email.error ? 'zid-border-[#F04438]' : 'zid-border-black'
                      } zid-border-[1px] zid-py-2 zid-px-3 zid-w-full zid-font-[400]`}
                    />
                    {email.error && (
                      <idk-div class={`zid-text-[#F04438] zid-font-[500] zid-leading-[20px] zid-text-[10px]`}>
                        {email.error}
                      </idk-div>
                    )}
                  </idk-div>
                </idk-div>
                <idk-div class="zid-flex zid-flex-col zid-gap-2 zid-font-[700]">
                  <idk-div>Reason:</idk-div>
                  <idk-div class="zid-flex zid-flex-col">
                    <input
                      value={reason.value}
                      onChange={(e) => setReason({ value: e.target.value, error: '' })}
                      placeholder={'I am reporting this media because...'}
                      className={`${
                        reason.error ? 'zid-border-[#F04438]' : 'zid-border-black'
                      } zid-border-[1px] zid-py-2 zid-px-3 zid-w-full zid-font-[400]`}
                    />
                    {reason.error && (
                      <idk-div class="zid-text-[#F04438] zid-font-[500] zid-leading-[20px] zid-text-[10px]">
                        {reason.error}
                      </idk-div>
                    )}
                  </idk-div>
                </idk-div>
              </idk-div>

              <idk-div>
                <Captcha
                  onVerify={(token) => {
                    setCaptcha({ value: token, error: '' });
                  }}
                  isOpen={isOpen}
                />
                {captcha.error && (
                  <idk-div class="zid-text-[#F04438] zid-font-[500] zid-leading-[20px] zid-text-[10px]">
                    {captcha.error}
                  </idk-div>
                )}
              </idk-div>

              <idk-div class="zid-flex zid-gap-[24px] zid-font-[700] zid-text-[12px] zid-leading-[18px]">
                <button
                  className="zid-px-6 zid-py-2 zid-border-gray-600 zid-border-[1px] zid-cursor-pointer"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  className="zid-px-6 zid-py-2 zid-border-black zid-border-[1px] zid-bg-black zid-text-white zid-cursor-pointer"
                  onClick={() => handleClick()}
                >
                  {isLoading ? <AiOutlineLoading3Quarters /> : 'Report'}
                </button>
              </idk-div>
            </idk-div>
          </idk-div>
        </idk-div>
      </CustomModal>
    );

  return null;
}
