import React from 'react';

const Loader = (props) => {
  return props.show === true ? (
    <idk-div class="loader-container">
      <idk-div class="lds-ring">
        <idk-div></idk-div>
        <idk-div></idk-div>
        <idk-div></idk-div>
        <idk-div></idk-div>
      </idk-div>
    </idk-div>
  ) : (
    <></>
  );
};

export default Loader;
