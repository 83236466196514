import React from 'react';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import Cover from '../Cover';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import ReadMoreReadLess from '../../common/ReadMoreReadLess';

export default function Wall2Box({ colData }) {
  const { clickMutate, showModal, setAlbumId, layout } = useDisplayWidgetContext();
  return (
    <idk-div
      onClick={() => {
        clickMutate(colData.id);
        setAlbumId(colData.id);
      }}
      class="zid-w-full zid-bg-white zid-flex  zid-flex-col zid-flex-wrap zid-overflow-hidden"
      style={{ borderRadius: layout?.cardSettings?.cardBorder }}
    >
      <idk-div
        class={`commonImageContainer zid-relative zid-overflow-hidden zid-rounded-lg zid-h-full zid-w-full zid-flex-1 zid-items-center zid-justify-center`}
      >
        <idk-div
          onClick={() => {
            showModal(colData, 'slider');
            showModal(colData, 'slider');
            setAlbumId(colData.id);
          }}
        >
          <HotspotMedia
            layout={layout}
            row={colData}
            playFunctionality={false}
            autoPlay={layout?.videoSettings?.videoAutoPlay}
          />
        </idk-div>
        {/* <Cover
          onClick={() => {
            showModal(colData, 'slider');
            setAlbumId(colData.id);
          }}
          row={colData}
        /> */}
      </idk-div>
      <idk-div class="zid-p-[8px] zid-flex zid-flex-col zid-justify-center zid-items-center zid-text-[13px] zid-text-[#707070]">
        <idk-p class="zid-text-justify">{colData?.description}</idk-p>
        <idk-p>
          <ReadMoreReadLess totalWords={30}>{colData?.caption}</ReadMoreReadLess>
        </idk-p>
      </idk-div>
      <idk-div class="zid-p-4 zid-flex zid-justify-between zid-w-[100%] zid-items-center zid-text-[13px] zid-text-[rgba(0,0,0,0.87)]">
        <idk-div class="zid-flex zid-gap-2 zid-justify-center">
          {colData?.media_url == 'IMAGE' && (
            <img
              loading="lazy"
              src={colData?.media_url}
              height="40px"
              width="40px"
              style={{ borderRadius: '999px', minHeight: '40px', maxHeight: '40px' }}
            ></img>
          )}
          <idk-div class="zid-text-[13px] zid-text-[#000000]">
            <idk-p>{colData?.product_name}</idk-p>
            <b>{colData?.username}</b>
            {colData?.timestamp && (
              <idk-p class="zid-text-[#707070]">
                {(window as any).moment &&
                  colData?.timestamp &&
                  (window as any).moment(colData?.timestamp).format('MMM DD, YYYY')}
              </idk-p>
            )}
          </idk-div>
        </idk-div>
        <idk-div
          class="zid-text-[30px]"
          style={{ color: 'rgb(228, 64, 95)', cursor: 'pointer' }}
          onClick={() => window.open(colData?.data?.permalink, '_blank')}
        >
          {colData?.source == 'Shorts' && (
            <img
              height={25}
              width={25}
              src="https://assets.idukki.io/widget-assets/ShortsLogo.svg"
              loading="lazy"
            ></img>
          )}
          {colData?.source == 'Youtube' && (
            <img
              height={25}
              width={25}
              src="https://assets.idukki.io/widget-assets/YoutubeLogo.svg"
              loading="lazy"
            ></img>
          )}
          {colData?.source == 'Instagram_business' && (
            <img
              height={25}
              width={25}
              src="https://assets.idukki.io/widget-assets/InstagramLogo.svg"
              loading="lazy"
            ></img>
          )}
          {colData?.source == 'Twitter' && (
            <img
              height={25}
              width={25}
              src="https://assets.idukki.io/widget-assets/XTwitterLogo.svg"
              loading="lazy"
            ></img>
          )}
        </idk-div>
      </idk-div>
    </idk-div>
  );
}
