import ErrorScreen from '../../../common/ErrorScreen';
import {
  badge,
  carousel,
  carousel3D,
  collage,
  expandableImage,
  feed,
  frame,
  grid,
  masonry,
  premium1,
  premium2,
  specialGrid,
  stories,
  testimonial1,
  testimonial2,
  testimonial3,
  vertical,
  wall1,
  wall2,
} from '../../../contants';
import Badge from '../../badge/Badge';
import CarouselView from '../../carousel/carousel';
import Carousel3d from '../../Carousel3d';
import CarouselRightPost from '../../CarouselRightPost/CarouselRightPost';
import Collage from '../../collage/collage';
import Expandable from '../../Expandale';
import Feed from '../../feed/Feed';
import Frame from '../../frame/frame';
import Grid from '../../grid/grid';
import Masonry from '../../masonry/masonry';
import Premium1 from '../../premium1/premium1';
import Premium2 from '../../premium2/premium2';
import SpecialGrid from '../../specialGrid';
import Stories from '../../stories/stories';
import Testimonial1 from '../../testimonials/Testimonial1';
import Testimonial2 from '../../testimonials/Testimonial2';
import Testimonial3 from '../../testimonials/Testimonial3';
import Vertical from '../../vertical/vertical';
import Wall2 from '../../wall/wall2';
import { IData } from '../DisplayWidget.container';
import { useDisplayWidgetContext } from '../utils/context';

export default function View() {
  const { albumData, data }: { albumData: any[]; data: IData } = useDisplayWidgetContext();
  const galleryView = data?.result?.layout?.galleryView?.toLowerCase();

  // return <Carousel3d />;
  if (albumData?.length == 0)
    return (
      <idk-div class="zid-flex zid-justify-center zid-items-center">
        <idk-p class="zid-mx-auto zid-text-lg">No Data</idk-p>
      </idk-div>
    );
  if (galleryView === grid?.toLowerCase()) return <Grid />;

  if (galleryView === vertical.toLowerCase()) return <Vertical />;

  if (galleryView === carousel?.toLowerCase()) return <CarouselView />;

  if (galleryView === testimonial1?.toLowerCase()) return <Testimonial1 />;

  if (galleryView === testimonial2?.toLowerCase()) return <Testimonial2 />;

  if (galleryView === testimonial3?.toLowerCase()) return <Testimonial3 />;

  if (galleryView === collage?.toLowerCase()) return <Collage />;

  if (galleryView === feed?.toLowerCase()) return <Feed />;

  if (galleryView === wall1?.toLowerCase()) return <CarouselRightPost />;

  if (galleryView === wall2?.toLowerCase()) return <Wall2 />;

  if (galleryView === masonry?.toLowerCase()) return <Masonry />;

  if (galleryView === carousel3D?.toLowerCase()) return <Carousel3d />;

  if (galleryView === expandableImage?.toLowerCase()) return <Expandable />;

  if (galleryView === premium1.toLowerCase()) return <Premium1 />;

  if (galleryView === premium2.toLowerCase()) return <Premium2 />;

  if (galleryView === stories?.toLowerCase()) return <Stories />;

  if (galleryView === badge.toLowerCase()) return <Badge />;

  if (galleryView === frame.toLowerCase()) return <Frame />;

  if (galleryView === specialGrid.toLowerCase()) return <SpecialGrid />;

  return <ErrorScreen />;
}
