// const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];
// const minHeight = 400;
// const maxHeight = 600;
// const minWidth = 500;
// const maxWidth = 600;

import { useState, useEffect } from 'react';

// export const randomizeHeight = () => {
//     let finalHeight;
//     finalHeight = calculateHeight(finalHeight || 600);
//     if(finalHeight > 1000 || finalHeight < 200){
//         calculateHeight(finalHeight)
//     } else return finalHeight;
// }

// export const randomizeWidth = () => {
//     let finalWidth;
//     finalWidth = calculateWidth(finalWidth || 400);
//     if(finalWidth > 600 || finalWidth < 200){
//         calculateWidth(finalWidth)
//     } else return finalWidth;
// }

// export const calculateHeight = (heightValue: any) => {
//     if(heightValue) {
//        heightValue= Math.floor((Math.random()) * (maxWidth-minWidth));
//     }
//     return heightValue;
// }

// export const calculateWidth = (widthValue: any) => {
//     if(widthValue){
//         widthValue= Math.floor((Math.random()) * (maxHeight-minHeight))
//     }
//     return widthValue;
// }

// function generateRandomInteger(min: number, max: number) {
//   return Math.floor(min + Math.random() * (max - min + 1));
// }

// export const filterData = (data: any) => {
//   return data.map((photo: any) => ({
//     src: photo.media_url[0],
//     width: generateRandomInteger(250, 550),
//     height: generateRandomInteger(350, 650),
//     text: photo.text,
//     author: photo.owner.name,
//     like_count: photo.like_count,
//     // images: breakpoints.map((breakpoint) => {
//     //     const height = Math.round((photo.height / photo.width) * breakpoint);
//     //     return {
//     //         src: photo.src,
//     //         width: breakpoint,
//     //         height,
//     //     };
//     // }),
//   }));
// };

function generateRandomInteger(min: number, max: number) {
  return Math.floor(min + Math.random() * (max - min + 1));
}

export const filterData = (data: any) => {
  return data.map((photo: any) => ({
    ...photo,
    width: generateRandomInteger(250, 450),
    height: generateRandomInteger(350, 550),
  }));
};

export function grouper(array, cols) {
  function split(array, cols) {
    if (cols == 1) return array;
    const size = Math.ceil(array.length / cols);
    return array
      .slice(0, size)
      .concat([null])
      .concat(split(array.slice(size), cols - 1));
  }

  const a = split(array, cols);
  const groups = [];
  let group = [];
  for (let i = 0; i < a.length; i++) {
    if (a[i] === null) {
      groups.push(group);
      group = [];
      continue;
    }
    group.push(a[i]);
  }
  groups.push(group);
  return groups;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const UseWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const AuthHeaders = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export function getRelativeTime(timestamp) {
  if (!timestamp) return '';
  const now = new Date();
  const secondsAgo = Math.floor((now.getTime() - timestamp) / 1000);

  // Define time periods in second
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  if (secondsAgo < minute) {
    return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < hour) {
    const minutesAgo = Math.floor(secondsAgo / minute);
    return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < day) {
    const hoursAgo = Math.floor(secondsAgo / hour);
    return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < week) {
    const daysAgo = Math.floor(secondsAgo / day);
    if (daysAgo === 1) {
      return 'yesterday';
    } else {
      return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    }
  } else if (secondsAgo < month) {
    const weeksAgo = Math.floor(secondsAgo / week);
    return `${weeksAgo} week${weeksAgo !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < year) {
    const monthsAgo = Math.floor(secondsAgo / month);
    return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
  } else {
    const yearsAgo = Math.floor(secondsAgo / year);
    return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
  }
}

export const twitter = 'TWITTER';
