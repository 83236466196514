import React from 'react';
import { LeftArrow, RightArrow } from '../../components/carousel/carousel';

export default function Controls({ postNumber, setPostNumber, row }) {
  return (
    <>
      <idk-div style={{ position: 'absolute', top: '50%', left: '0%' }}>
        <LeftArrow
          carouselState={{ currentSlide: postNumber }}
          totalSlides={row?.children?.data?.length}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setPostNumber((e) => {
              const totalPost = row?.children?.data?.length;
              if (e == 0) return totalPost - 1;
              return e - 1;
            });
          }}
          class="zid-z-[10000]"
        />
      </idk-div>
      <idk-div style={{ position: 'absolute', top: '50%', right: '0%' }}>
        <RightArrow
          carouselState={{ currentSlide: postNumber }}
          totalSlides={row?.children?.data?.length}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setPostNumber((e) => {
              const totalPost = row?.children?.data?.length;
              if (e == totalPost - 1) return 0;
              return e + 1;
            });
          }}
          class="zid-z-[1000]"
        />
      </idk-div>
    </>
  );
}
