import React from 'react';
import { useDisplayWidgetContext } from '../../DisplayWidget/utils/context';

export default function ShopifyInputs({ data, index, handleVariantSelection }) {
  const { selectedVariant } = useDisplayWidgetContext();
  if (data.name == 'Color') {
    return (
      <idk-div class="zid-mb-2">
        <idk-div class="zid-mb-1">{data.name}</idk-div>
        <select className="zid-w-40 zid-text-base">
          {data.values.map((value: any, innerIndex: number) => {
            return <option key={innerIndex}>{value}</option>;
          })}
        </select>
      </idk-div>
    );
  }
  return (
    <idk-div class="zid-mb-2">
      <idk-div class="zid-mb-1">{data.name}</idk-div>
      <idk-div class="zid-flex zid-gap-2">
        {data.values.map((value: any, innerIndex: number) => {
          return (
            <idk-div
              class={`zid-rounded-full zid-bg-slate-300 zid-h-8 zid-w-8 zid-flex zid-items-center zid-justify-center zid-min-w-fit zid-border-2 ${
                selectedVariant?.options?.[index] == value ? 'zid-border-black' : ''
              }`}
              key={innerIndex}
              onClick={() => {
                handleVariantSelection(value, data.position);
              }}
            >
              {value}
            </idk-div>
          );
        })}
      </idk-div>
    </idk-div>
  );
}
