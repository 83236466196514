import React from 'react';

export default function Banner({ src, link }) {
  return (
    <idk-div
      style={{
        width: '100%',
        display: 'flex',
        margin: '10px 0',
        justifyContent: 'center',
        cursor: link ? 'pointer' : 'default',
      }}
      className="zid-rounded zid-drop-shadow"
      onClick={() => {
        if (link) {
          window.open(link, '_blank');
        }
      }}
    >
      <img src={src} style={{ width: '100%' }} className="zid-rounded" loading="lazy" />
    </idk-div>
  );
}
