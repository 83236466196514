// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import axios from 'axios';
import React from 'react';
import { API_BASE_URL } from '../contants';

export default function useWidget(
  bid: string,
  guid: string,
  pcat: string,
  pid: string,
  setError = null,
  fromRedis: any,
) {
  const [data, setData] = React.useState(null);
  const [album, setAlbum] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);

  async function fetchAlbum(): Promise<any> {
    let endpoint = API_BASE_URL() + '/widget/' + guid;
    if (fromRedis) {
      endpoint = `https://app.idukki.io/api/fetch-widget?guid=${guid}`;
    }
    let w_pid = pid;
    let w_pcat = pcat;
    if (window?.Shopify?.shop?.includes('myshopify.com')) {
      if (window?.ShopifyAnalytics?.meta?.product?.id) w_pid = window?.ShopifyAnalytics?.meta?.product?.id;
      if (window?.ShopifyAnalytics?.meta?.product?.type) w_pcat = window?.ShopifyAnalytics?.meta?.product?.type;
    }
    if (sessionStorage.getItem(guid)) {
      return await JSON.parse(sessionStorage.getItem(guid));
    } else
      return await axios
        .get(endpoint, { params: { page: page, pid: w_pid, pcat: w_pcat } })
        .then(({ data }) => {
          if (fromRedis) {
            sessionStorage.setItem(guid, JSON.stringify(data));
          }
          return data as any;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
  }

  React.useEffect(() => {
    (async () => {
      try {
        const responseAlbum = await fetchAlbum();
        if (responseAlbum) {
          let isProducts = false;
          for (const i of responseAlbum.result.data) {
            if (i?.products?.length > 0) {
              isProducts = true;
              break;
            }
          }
          if (isProducts && window?.Shopify) {
            if (responseAlbum.result.data.length > 0) {
              const updatedData = await Promise.all(
                responseAlbum.result.data.map(async (data: any) => {
                  if (data?.products?.length > 0) {
                    const updatedProducts = await Promise.all(
                      data.products.map(async (productData: any) => {
                        try {
                          if (productData.product_url) {
                            const res = await axios.get(productData.product_url + '.js');
                            return res.data;
                          } else return productData;
                        } catch (error) {
                          console.error('Error fetching product data:', error);
                          return null; // or handle error as needed
                        }
                      }),
                    );
                    data.products = updatedProducts;
                  }
                  return data;
                }),
              );
              setIsLoading(false);
              setData(JSON.parse(JSON.stringify(responseAlbum)));
              setAlbum(responseAlbum.result);
              setTotalPages(responseAlbum.totalPage);
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setData(JSON.parse(JSON.stringify(responseAlbum)));
            setAlbum(responseAlbum.result);
            setTotalPages(responseAlbum?.totalPage);
          }
        }
      } catch (error) {
        setIsLoading(false);
        if (setError) setError(error + '');
      }
    })();
  }, [page]);

  return { isLoading, data, album, page, setPage, totalPages };
}
