import ErrorScreen from './common/ErrorScreen';
import { DisplayWidget } from './components/DisplayWidget';
import { GuidPage } from './pages/GuidPage';

function App({ data }) {
  const urlParams = new URLSearchParams(window.location.search);
  const guid = urlParams.get('guid');
  const token = urlParams.get('token');
  const bguid = urlParams.get('bguid');

  const defaultData = {
    guid: guid ? guid : data.guid,
    pcat: data?.pcat,
    pid: data?.pid,
    bguid: bguid ? bguid : data?.bguid,
    version: data?.version,
    fromRedis: data?.fromRedis,
  };

  const isGuidValid = (guid) => {
    if (guid == '' || !guid || (guid?.slice(0, 2) == '{{' && guid.slice(-2, guid?.length) == '}}')) return false;
    return true;
  };

  if (isGuidValid(defaultData?.guid)) {
    return (
      <idk-div class="Idukki-App">
        <DisplayWidget props={defaultData} />
      </idk-div>
    );
  }

  if (token) {
    return (
      <idk-div class="Idukki-App">
        <GuidPage bguid={defaultData?.bguid} token={token} />
      </idk-div>
    );
  }

  return <ErrorScreen error="Error: Token is invalid" />;
}

export default App;
