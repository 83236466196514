import React, { useState, useEffect, useRef } from 'react';

export default function Video() {
  const videoRef = useRef();
  const [questionNum, setQuestionNum] = useState(0);
  const [quizComplete, setQuizComplete] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPlay, setShowPlay] = useState(true);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(false);
    setTimeout(() => {
      setFadeIn(true);
    }, 500);
  }, [questionNum]);

  const questions = [
    {
      question: 'What is the capital of France?',
      options: ['Berlin', 'Paris', 'London', 'Madrid'],
      answer: 'Paris',
    },
    {
      question: 'Which planet is known as the Red Planet?',
      options: ['Mars', 'Venus', 'Jupiter', 'Saturn'],
      answer: 'Mars',
    },
    {
      question: "Who wrote 'Romeo and Juliet'?",
      options: ['Charles Dickens', 'William Shakespeare', 'Jane Austen', 'Mark Twain'],
      answer: 'William Shakespeare',
    },
    {
      question: 'What is the largest mammal in the world?',
      options: ['Elephant', 'Blue Whale', 'Giraffe', 'Hippopotamus'],
      answer: 'Blue Whale',
    },
    {
      question: 'In which year did the Titanic sink?',
      options: ['1912', '1905', '1920', '1931'],
      answer: '1912',
    },
  ];

  const handleAnswer = (option, questionIndex) => {
    if (questionNum < questions.length - 1) {
      setTimeout(() => {
        setQuestionNum((prevState) => prevState + 1);
        setSelectedOption(null);
      }, 500);
    }
    if (questionNum == questions.length - 1) {
      setQuizComplete(true);
    }
    setSelectedOption(option);
  };

  const handlePlay = () => {
    setShowPlay((prevState) => !prevState);
  };

  return (
    <idk-div class="main_video_container zid-relative">
      <video className="main_video" ref={videoRef} onClick={handlePlay}>
        <source
          src="https://businesstoday-pdelivery.akamaized.net/btbazaar/video/2023_11/02_nov_23_hindi_srk_reel_512.mp4"
          type="video/mp4"
        />
        <source
          src="https://businesstoday-pdelivery.akamaized.net/btbazaar/video/2023_11/02_nov_23_hindi_srk_reel_512.mp4"
          type="video/ogg"
        />
      </video>
      {showPlay && <idk-div class="video_overlay"></idk-div>}
      {!quizComplete && (
        <idk-div class={`video-questions-container zid-absolute ${fadeIn ? 'fade-in' : ''}`}>
          <idk-div class="video-question">{questions[questionNum].question}</idk-div>
          <ul className="video_option_list">
            {questions[questionNum].options.map((option, index) => {
              return (
                <li
                  className={`video-option ${selectedOption === option ? 'selected' : ''}`}
                  key={index}
                  onClick={() => handleAnswer(option, index)}
                >
                  {index == 0 && (
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="12" fill="#FFFFFF"></circle>
                      <path
                        d="M8.383 15.5h1.595l.485-1.397h3.08l.484 1.397h1.595l-2.85-8.03h-1.54L8.383 15.5Zm3.62-5.852 1.034 2.992h-2.069l1.034-2.992Z"
                        fill="#2F3137"
                      ></path>
                    </svg>
                  )}
                  {index == 1 && (
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="12" fill="#FFFFFF"></circle>
                      <path
                        d="M9.303 15.5h2.717c1.782 0 2.893-.792 2.893-2.387 0-.88-.418-1.496-1.155-1.837.572-.341.87-.88.87-1.551 0-1.463-1.035-2.255-2.817-2.255H9.303v8.03Zm2.64-3.41c1.122 0 1.43.341 1.43.99s-.462 1.012-1.353 1.012h-1.188V12.09h1.111Zm-.12-3.212c.901 0 1.264.264 1.264.847 0 .561-.33.935-1.276.935h-.979V8.878h.99Z"
                        fill="#2F3137"
                      ></path>
                    </svg>
                  )}
                  {index == 2 && (
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="12" fill="#FFFFFF"></circle>
                      <path
                        d="M12.3 15.61c1.596 0 2.938-.924 3.642-2.288l-1.397-.737c-.407.858-1.177 1.496-2.277 1.496-1.474 0-2.574-1.155-2.574-2.596 0-1.474 1.11-2.618 2.607-2.618 1.023 0 1.727.506 2.178 1.342l1.342-.858A4.057 4.057 0 0 0 12.3 7.36c-2.321 0-4.125 1.727-4.125 4.125 0 2.288 1.639 4.125 4.125 4.125Z"
                        fill="#2F3137"
                      ></path>
                    </svg>
                  )}
                  {index == 3 && (
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="12" fill="#FFFFFF"></circle>
                      <path
                        d="M8.895 15.5h2.255c2.453 0 4.18-1.727 4.18-4.015 0-2.277-1.529-4.015-4.246-4.015H8.895v8.03Zm2.013-6.611c1.97 0 2.893 1.078 2.893 2.596 0 1.474-1.089 2.596-2.827 2.596h-.55V8.889h.484Z"
                        fill="#2F3137"
                      ></path>
                    </svg>
                  )}
                  {option}
                </li>
              );
            })}
          </ul>
        </idk-div>
      )}
      {showPlay && (
        <idk-div
          class="zid-absolute zid-top-2/4 zid-left-2/4 zid-z-20 zid-cursor-pointer"
          style={{ transform: 'translate(-50%, -50%)' }}
          onClick={handlePlay}
        >
          <svg width="90" height="90" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M60 114.546c30.125 0 54.546-24.421 54.546-54.546S90.125 5.455 60 5.455 5.455 29.875 5.455 60 29.875 114.546 60 114.546Z"
              fill="#090A0B99"
            ></path>
            <path
              d="M60 5.455a54.546 54.546 0 0 1 47.236 27.272"
              stroke="url(#player-button-s)"
              strokeWidth="5.986"
            ></path>
            <path
              d="M107.236 32.727a54.54 54.54 0 0 1 0 54.546"
              stroke="url(#player-button-b)"
              strokeWidth="5.986"
            ></path>
            <path
              d="M107.236 87.273A54.548 54.548 0 0 1 60 114.546"
              stroke="url(#player-button-c)"
              strokeWidth="5.986"
            ></path>
            <path
              d="M60 114.546a54.55 54.55 0 0 1-47.236-27.273"
              stroke="url(#player-button-d)"
              strokeWidth="5.986"
            ></path>
            <path
              d="M12.764 87.273a54.545 54.545 0 0 1 0-54.545"
              stroke="url(#player-button-e)"
              strokeWidth="5.986"
            ></path>
            <path
              d="M12.764 32.727A54.546 54.546 0 0 1 60 5.455"
              stroke="url(#player-button-f)"
              strokeWidth="5.986"
            ></path>
            <path
              d="M50.387 85.167c-3.142 0-5.387-2.47-5.387-5.387V40.275C45 37.469 47.245 35 50.387 35c.898 0 1.908.224 2.806.786l32.322 19.752c1.571.898 2.581 2.694 2.581 4.49 0 1.907-1.01 3.703-2.581 4.6L53.193 84.382a5.33 5.33 0 0 1-2.806.786Z"
              fill="#fff"
            ></path>
            <defs>
              <linearGradient
                id="player-button-s"
                x1="60"
                y1="5.455"
                x2="83.619"
                y2="46.363"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#198DEE"></stop>
                <stop offset="0.5" stopColor="#2A3CEF"></stop>
              </linearGradient>
              <linearGradient
                id="player-button-b"
                x1="107.236"
                y1="32.727"
                x2="107.236"
                y2="87.273"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#2A3CEF"></stop>
                <stop offset="0.7" stopColor="#E124D9"></stop>
              </linearGradient>
              <linearGradient
                id="player-button-c"
                x1="107.236"
                y1="87.273"
                x2="83.617"
                y2="128.181"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#E124D9"></stop>
                <stop offset="1" stopColor="#E52E56"></stop>
              </linearGradient>
              <linearGradient
                id="player-button-d"
                x1="60"
                y1="114.546"
                x2="36.381"
                y2="73.637"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stopColor="#FFB400"></stop>
                <stop offset="1" stopColor="#C4FF29"></stop>
              </linearGradient>
              <linearGradient
                id="player-button-e"
                x1="5.456"
                y1="87.273"
                x2="5.456"
                y2="32.727"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.1" stopColor="#C4FF29"></stop>
                <stop offset="1" stopColor="#198DEE"></stop>
              </linearGradient>
              <linearGradient
                id="player-button-f"
                x1="12.764"
                y1="32.727"
                x2="36.383"
                y2="-8.181"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#198DEE"></stop>
                <stop offset="1" stopColor="#198DEE"></stop>
              </linearGradient>
            </defs>
          </svg>
        </idk-div>
      )}
    </idk-div>
  );
}
