import React from 'react';
import Badge1 from './Badges/Badge1';
import Badge2 from './Badges/Badge2';
import Badge3 from './Badges/Badge3';
import Badge4 from './Badges/Badge4';
import Badge5 from './Badges/Badge5';
import { IData, IView } from './DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';
import GoogleSummary from './GoogleSummary';

export default function GoogleBadgeDesign({ metaJson }) {
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;
  const [reviews, setReviews] = React.useState(0);
  const [rating, setRating] = React.useState(0);

  React.useEffect(() => {
    if (metaJson) {
      const reviews = metaJson[0]?.totalReview || 0;
      const rating = metaJson[0]?.rating || 0;
      setReviews(reviews);
      setRating(rating);
    }
  }, [metaJson]);
  const badgeType = galleryLayout?.googleBadges?.badgeType;
  // if (galleryLayout?.googleBadges?.showBadge)
  return (
    <idk-div
      class=""
      style={{
        justifyContent: galleryLayout?.googleBadges?.badgeAlignment,
        color: '#616A6B',
        height: '100%',
        width: '100%',
      }}
    >
      {galleryLayout.googlePhoto.showNameAndAddress && (
        <>
          <h2 className="zid-text-xl zid-font-bold">{data?.result?.metaJson?.[0]?.details?.[0]?.owner_name}</h2>
          <h6 className="zid-mb-3">{data?.result?.metaJson?.[0]?.details?.[0]?.address}</h6>
        </>
      )}
      {galleryLayout?.googleSummaryOptions?.showButton && (
        <idk-div
          class="zid-flex"
          style={{ justifyContent: galleryLayout?.googleSummaryOptions?.buttonAlignment, fontSize: '14px' }}
        >
          {galleryLayout?.googleSummaryOptions?.showCtaAsLink ? (
            <a
              href={galleryLayout?.googleSummaryOptions?.buttonUrl}
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              {galleryLayout?.googleSummaryOptions?.buttonText}
            </a>
          ) : (
            <>
              <idk-div
                style={{
                  border: '1px solid',
                  borderRadius: '4px',
                  padding: '4px 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color: galleryLayout?.googleSummaryOptions?.buttonTextColor,
                  backgroundColor: galleryLayout?.googleSummaryOptions?.buttonColor
                    ? galleryLayout?.googleSummaryOptions?.buttonColor
                    : 'transparent',
                  borderColor: galleryLayout?.googleSummaryOptions?.buttonTextColor,
                  width: 'auto',
                }}
                onClick={() => {
                  window.open(
                    `https://search.google.com/local/writereview?placeid=${data?.result?.metaJson?.[0]?.details?.[0]?.place_id}`,
                    '_blank',
                  );
                }}
              >
                {galleryLayout?.googleSummaryOptions?.buttonText}
              </idk-div>
              <idk-div />
            </>
          )}
        </idk-div>
      )}
      <idk-div class="zid-flex zid-flex-row zid-items-center zid-flex-col lg:zid-flex-row">
        {galleryLayout?.googleSummaryOptions?.showSummaryChart && (
          <idk-div style={{ flex: 1 }}>
            <GoogleSummary />
          </idk-div>
        )}
        {galleryLayout?.googleBadges?.showBadge && (
          <>
            {badgeType == '1' ? (
              <Badge1 reviews={reviews} rating={rating} />
            ) : badgeType == '2' ? (
              <Badge2 reviews={reviews} rating={rating} />
            ) : badgeType == '3' ? (
              <Badge3 reviews={reviews} rating={rating} />
            ) : badgeType == '4' ? (
              <Badge4 reviews={reviews} rating={rating} />
            ) : badgeType == '5' ? (
              <Badge5 reviews={reviews} rating={rating} />
            ) : (
              <></>
            )}
          </>
        )}
      </idk-div>
    </idk-div>
  );

  return <></>;
}
