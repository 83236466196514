// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';
import { IData } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import { UseWindowDimensions } from '../collage/utils';
import ProductsOverlay from '../Modals/components/ProductsOverlay';
import { FaQuoteLeft } from 'react-icons/fa';
import ReadMoreReadLess from '../../common/ReadMoreReadLess';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { HiUserCircle } from 'react-icons/hi';

const Testimonial2 = () => {
  const { showModal, albumData, clickMutate, setAlbumId, isGoogle, layout, shopifyData } = useDisplayWidgetContext();
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryData = data?.result?.layout;
  const column = galleryData.galleryInformation.columns;
  const width = parseInt(column);

  const responsive = {
    mobile: {
      breakpoint: { max: 40000, min: 0 },
      items: width,
    },
    desktop: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
    super: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  const { width: screenWidth } = UseWindowDimensions();
  const mobileView = screenWidth < 500;

  if (isGoogle) {
    return (
      <idk-div
        style={{
          gap: `${layout?.galleryInformation?.postSpacing}px`,
          gridTemplateColumns: `repeat(${mobileView ? 1 : 2},1fr)`,
        }}
      >
        <idk-div
          style={{
            width: '80%',
            margin: 'auto',
            position: 'relative',
            maxWidth:
              isGoogle && layout?.googleBadges?.showBadge
                ? !mobileView
                  ? `calc(100vw - 350px)`
                  : `calc(100vw - 32px)`
                : '100vw',
            height: '100%',
          }}
        >
          <Carousel
            responsive={responsive}
            containerClass={'carousel-multi-container'}
            autoPlay={galleryData.sliderSettings.autoPlay}
            infinite={true}
            autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<CustomButtonGroup />}
          >
            {albumData.map((row, id) => {
              return (
                <idk-div
                  key={id}
                  class="zid-p-5 zid-bg-white zid-mx-2 zid-shadow-md"
                  style={{
                    height: layout?.cardSettings?.cardHeight,
                    borderRadius: layout?.cardSettings?.cardBorder,
                    boxShadow: '0 37px 68px #00000021',
                  }}
                >
                  <idk-div class="zid-flex zid-flex-col zid-gap-5 zid-items-center">
                    <FaQuoteLeft className="zid-text-5xl" />
                    <idk-span class="zid-text-center">
                      <i>
                        <ReadMoreReadLess totalWords={30}>{row.caption}</ReadMoreReadLess>
                      </i>
                    </idk-span>
                    <idk-div class="zid-flex zid-gap-5 zid-items-center zid-flex-col">
                      {row.profilePic ? (
                        <img src={row.profilePic} className="zid-h-24 zid-w-24 zid-rounded-full" loading="lazy"></img>
                      ) : (
                        <HiUserCircle className="zid-h-24 zid-w-24 zid-text-slate-200"></HiUserCircle>
                        // <div className="zid-h-24 zid-w-24 zid-border zid-bg-slate-100 zid-rounded-full zid-text-6xl zid-flex zid-items-center zid-justify-center zid-font-extrabold">
                        //   {row.username.charAt(0).toUpperCase()}
                        // </div>
                      )}
                      <idk-div class="zid-flex zid-flex-col zid-gap-2 ">
                        <idk-span>{row.username}</idk-span>
                      </idk-div>
                    </idk-div>
                  </idk-div>
                </idk-div>
              );
            })}
          </Carousel>
        </idk-div>
      </idk-div>
    );
  }

  return (
    <React.Fragment>
      <style>{`
      .idk-carousel-item{
        padding: 0 ${parseInt(layout?.galleryInformation?.postSpacing) / 2}px;
      }`}</style>
      <idk-div class="zid-mx-auto">
        <Carousel
          responsive={responsive}
          containerClass={'carousel-multi-container'}
          autoPlay={galleryData.sliderSettings.autoPlay}
          infinite={true}
          autoPlaySpeed={galleryData.sliderSettings.autoPlaySpeed}
          customLeftArrow={<LeftArrow />}
          customRightArrow={<RightArrow />}
          itemClass="idk-carousel-item"
        >
          {albumData.map((row, id) => {
            return (
              <idk-div
                class={`zid-w-[100%] zid-flex zid-items-center zid-justify-center zid-h-full`}
                key={id}
                onClick={() => {
                  showModal(row, 'slider');
                  setAlbumId(row.id);
                  clickMutate(row.id);
                }}
                style={{
                  borderRadius: layout?.cardSettings?.cardBorder,
                }}
              >
                <idk-div
                  class="zid-relative zid-bg-white zid-rounded zid-overflow-hidden zid-rounded-lg zid-relative zid-w-full zid-h-full"
                  style={{ height: galleryData.cardSettings.cardHeight }}
                  onClick={() => {
                    showModal(row, 'slider');
                    setAlbumId(id);
                  }}
                >
                  {/* {isShopify && row.products.length > 0 && (
                  <idk-div class="">
                    <img src=""></img>
                    <div dangerouslySetInnerHTML={{ __html: row?.products?.[0]?.description }}></div>
                  </idk-div>
                )} */}
                  <HotspotMedia row={row} playFunctionality={false} />
                  {row?.products?.length > 0 && layout.gallerySettings.showProducts && (
                    <ProductsOverlay shopifyData={shopifyData} row={row}></ProductsOverlay>
                  )}

                  {/* <Cover
                  onClick={() => {
                    showModal(row, 'slider');
                    setAlbumId(id);
                  }}
                  row={row}
                /> */}
                </idk-div>
              </idk-div>
            );
          })}
        </Carousel>
      </idk-div>
    </React.Fragment>
  );
};

export function LeftArrow({ ...props }) {
  const { onClick } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == 0) return <></>;

  return (
    <idk-div
      onClick={onClick}
      class={`zid-absolute zid-translate-y-[-50%] zid-top-[50%] zid-translate-x-[20px] zid-cursor-pointer zid-p-2 zid-rounded-full zid-bg-white zid-opacity-[0.7] zid-h-[36px] ${props?.className}`}
    >
      <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px" fill="white">
        <polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 " />
      </svg>
    </idk-div>
  );
}

export function RightArrow({ ...props }) {
  const { onClick, totalSlides } = props;
  const {
    carouselState: { currentSlide },
  } = props;

  if (currentSlide == totalSlides - 1) return <></>;
  return (
    <idk-div
      onClick={onClick}
      class={`zid-absolute zid-translate-y-[-50%] zid-right-0 zid-top-[50%] zid-translate-x-[-20px] zid-z-[100] zid-cursor-pointer zid-p-2 zid-rounded-[99px] zid-bg-slate-800 zid-opacity-[0.7] zid-h-[36px] ${props?.className}`}
    >
      <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px" fill="white">
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg>
    </idk-div>
  );
}

const CustomButtonGroup = ({ next, previous }) => (
  <div className="">
    <button
      className="zid-absolute zid-z-[100] zid-cursor-pointer md:zid-left-[-100px] zid-left-[-10px] zid-p-2 zid-bg-white zid-opacity-[0.7] zid-h-[51px] zid-w-[51px] zid-flex zid-items-center zid-justify-center"
      style={{
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '30px',
        filter: 'drop-shadow(0px 4px 12px rgba(0,0,0,.25))',
      }}
      onClick={previous}
    >
      <MdArrowBackIosNew fontSize={20} />
    </button>
    <button
      className="zid-absolute zid-z-[100] zid-cursor-pointer zid-p-2 md:zid-right-[-100px] zid-right-[-10px] zid-bg-white zid-opacity-[0.7] zid-h-[51px] zid-w-[51px] zid-flex zid-items-center zid-justify-center"
      style={{
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '30px',
        filter: 'drop-shadow(0px 4px 12px rgba(0,0,0,.25))',
      }}
      onClick={next}
    >
      <MdArrowForwardIos fontSize={20} />
    </button>
  </div>
);

export default Testimonial2;
