import React from 'react';
import Carousel from 'react-multi-carousel';
import { useDisplayWidgetContext } from './DisplayWidget/utils/context';
import Cover from './Cover';
import { VideoPlayer } from './VideoPlayer/VideoPlayer';
import { LeftArrow, RightArrow } from './carousel/carousel';
import LinkedinView from './LinkedinView';

export default function Expandable() {
  const { albumData, showModal, setAlbumId, layout, isLinkedIn } = useDisplayWidgetContext();
  const [activeImage, setActiveImage] = React.useState(0);
  const postResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleActiveImage = (activeKey) => {
    setActiveImage(activeKey);
  };

  return (
    <React.Fragment>
      <style>
        {` .expandable_panel.active {
      flex: ${albumData?.length};
    }`}
      </style>
      <idk-div class="expandImage-container zid-h-[400px]">
        {albumData.slice(0, 6).map((imageData: any, index: number) => {
          if (isLinkedIn) {
            return (
              <idk-div
                key={index}
                onClick={() => {
                  handleActiveImage(index);
                  if (activeImage === index) {
                    showModal(imageData, 'slider');
                  }
                  setAlbumId(imageData?.id);
                }}
                style={{ height: layout.cardSettings.cardHeight }}
                class={`expandable_panel ${activeImage === index ? 'active' : ''} zid-relative`}
              >
                <LinkedinView key={index} data={imageData} />
              </idk-div>
            );
          }
          return (
            <idk-div
              key={index}
              onClick={() => {
                handleActiveImage(index);
                if (activeImage === index) {
                  showModal(imageData, 'slider');
                }
                setAlbumId(imageData?.id);
              }}
              style={{ height: layout.cardSettings.cardHeight }}
              class={`expandable_panel ${activeImage === index ? 'active' : ''} zid-relative`}
            >
              {albumData === true && <img src={imageData.src}></img>}
              {imageData?.media_type === 'IMAGE' && (
                <img
                  loading="lazy"
                  src={imageData.media_url}
                  style={{ borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit' }}
                ></img>
              )}
              {imageData.media_type === 'VIDEO' && (
                <>
                  {imageData.source === 'Instagram_business' && (
                    <VideoPlayer
                      autoPlay={false}
                      showMute={false}
                      videoSource={imageData.media_url + '#t=0.1'}
                      style={{ objectFit: 'cover', margin: 0 }}
                      poster={imageData?.thumbnail_url}
                    />
                    // <video
                    //   loop
                    //   width="100%"
                    //   height="100%"
                    //   src={imageData.media_url}
                    //   muted
                    //   autoPlay
                    //   className="zid-rounded zid-h-full"
                    //   style={{ objectFit: 'cover', margin: 0 }}
                    //   poster={imageData?.thumbnail_url}
                    //   playsInline
                    // >
                    //   <source src={imageData.media_url} type="video/mp4" />
                    // </video>
                  )}
                  {(imageData.source === 'Youtube' || imageData.source === 'Shorts') && (
                    <iframe
                      width="100%"
                      height="100%"
                      className="zid-rounded zid-h-full"
                      src={`https://www.youtube.com/embed/${imageData.source_id}?autoplay=1&mute=1`}
                      srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;height: 100%; object-fit:cover;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${
                        imageData.source_id
                      }?autoplay=1&mute=1><img src=https://img.youtube.com/vi/${
                        imageData.source_id
                      }/hqdefault.jpg><span>${
                        imageData.source == 'Youtube'
                          ? '<svg height="40px" version="1.1" viewBox="0 0 68 48" width="100%"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>'
                          : '<svg height="40px" version="1.0" xmlns="http://www.w3.org/2000/svg" width="201.000000pt" height="250.000000pt" viewBox="0 0 201.000000 250.000000"><g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)" fill="#ff0000" stroke="none"><path d="M1414 2485 c-71 -15 -87 -23 -704 -347 -261 -138 -499 -269 -529 -291 -218 -168 -237 -502 -40 -700 70 -69 180 -125 263 -134 31 -3 56 -9 56 -12 0 -3 -52 -33 -116 -65 -241 -124 -334 -265 -322 -491 5 -85 9 -104 42 -171 50 -100 124 -174 218 -219 150 -71 287 -68 446 10 238 116 1054 552 1101 588 171 131 225 375 129 577 -62 130 -217 243 -352 257 -31 3 -56 9 -56 12 0 3 53 33 117 66 175 89 262 179 304 316 23 72 25 191 5 265 -46 174 -205 316-386 344 -80 12 -100 12 -176 -5z m-379 -1035 c154 -95 290 -180 303 -188 21 -14 16 -18 -160 -127 -101 -61 -243 -149 -315 -193 l-133 -82 0 386 c0 312 2 385 13 381 7 -3 139 -82 292 -177z"/></g></svg>'
                      }</span></a>`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </>
              )}
              {imageData.media_type === 'CAROUSEL_ALBUM' &&
                (activeImage === index ? (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  <Carousel
                    responsive={postResponsive}
                    infinite
                    containerClass={`zid-h-full zid-rounded`}
                    customLeftArrow={
                      <LeftArrow
                        arrowColor={layout?.sliderSettings?.arrowColor}
                        arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
                      />
                    }
                    customRightArrow={
                      <RightArrow
                        arrowColor={layout?.sliderSettings?.arrowColor}
                        arrowBackgroundColor={layout?.sliderSettings?.arrowBackgroundColor}
                      />
                    }
                  >
                    {imageData.children.data.map((image: any, i: number) => {
                      return (
                        <idk-div key={i} class="zid-h-full">
                          {image.media_type === 'VIDEO' && (
                            <VideoPlayer
                              autoPlay={false}
                              showMute={false}
                              videoSource={imageData.media_url + '#t=0.1'}
                              poster={image?.thumbnail_url}
                            />

                            // <video
                            //   loop
                            //   height="100%"
                            //   src={image.media_url}
                            //   muted
                            //   autoPlay
                            //   poster={image?.thumbnail_url}
                            //   playsInline
                            // >
                            //   <source src={image.media_url} type="video/mp4" style={{ margin: 0 }} />
                            // </video>
                          )}
                          {image.media_type === 'IMAGE' && (
                            <img loading="lazy" src={image.media_url} width="100%" className="zid-rounded"></img>
                          )}
                        </idk-div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <>
                    {imageData.children.data[0].media_type === 'VIDEO' && (
                      <VideoPlayer
                        autoPlay={false}
                        showMute={false}
                        videoSource={imageData.children.data[0].media_url + '#t=0.1'}
                        poster={imageData?.thumbnail_url}
                        style={{ margin: 0 }}
                      />

                      // <video
                      //   loop
                      //   height="100%"
                      //   src={imageData.children.data[0].media_url}
                      //   muted
                      //   autoPlay
                      //   className="zid-h-full"
                      //   poster={imageData?.thumbnail_url}
                      //   style={{ margin: 0 }}
                      //   playsInline
                      // >
                      //   <source src={imageData.children.data[0].media_url} type="video/mp4" />
                      // </video>
                    )}
                    {imageData.children.data[0].media_type === 'IMAGE' && (
                      <img
                        loading="lazy"
                        src={imageData.children.data[0].media_url}
                        width="100%"
                        className="zid-rounded zid-h-full"
                      ></img>
                    )}
                  </>
                ))}
              {imageData.caption ? <h3>{imageData.caption.slice(0, 30)}</h3> : <h3>Explore The World</h3>}
            </idk-div>
          );
        })}
      </idk-div>
    </React.Fragment>
  );
}
