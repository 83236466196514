import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import TextTruncate from '../TextTruncate';
import { getSubscriptionData } from '../../utils/sharedData';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import { getCurrentSymbol } from '../../Helpers/Helpers';
export default function TagProductBox({ data }) {
  const amountFormatter = new Intl.NumberFormat();
  const currency = getSubscriptionData()?.data?.currency;

  const { data: widgetData } = useDisplayWidgetContext();

  return (
    <idk-div
      class="zid-p-2 zid-m-0 zid-flex zid-gap-2 zid-drop-shadow zid-rounded zid-items-center zid-justify-between	"
      style={{ maxWidth: '200px', cursor: 'pointer', backgroundColor: '#fff', minWidth: '200px' }}
      onClick={() => {
        window.open(data?.product_url, '_blank');
      }}
    >
      {data?.images && (
        <img
          loading="lazy"
          src={data?.images[0]?.image_url}
          className="zid-rounded"
          width="100%"
          style={{ maxWidth: '40px', height: 'auto' }}
        />
      )}
      <idk-div class="zid-h-full">
        <TextTruncate style={{ WebkitLineClamp: 2, textAlign: 'start', color: '#141414', fontSize: '14px' }}>
          <b>{data?.title}</b>
        </TextTruncate>
        {data?.variants && (
          <idk-p class="zid-mt-1 zid-p-0" style={{ fontWeight: 500, fontSize: '12px' }}>
            {(widgetData.result.currency &&
              getCurrentSymbol(widgetData.result.currency, navigator?.language || navigator['userLanguage'])) ||
              currency}
            {amountFormatter.format(data?.variants[0]?.price ? data?.variants[0]?.price : '')}
          </idk-p>
        )}
      </idk-div>
      <MdKeyboardArrowRight size={40} />
    </idk-div>
  );
}
