import * as React from 'react';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import { epochToRelativeDate } from '../../Helpers/Helpers';
import { IoPerson } from 'react-icons/io5';
import { FaLinkedin } from 'react-icons/fa';
import HotspotMedia from '../../common/ImageHotspot/HotspotMedia';

export default function LinkedinView({ data }) {
  const [width, setWidth] = React.useState(1000);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const ref = React.useRef(null);
  const { clickMutate, layout } = useDisplayWidgetContext();

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  function convertText(originalText) {
    let convertedText = originalText.replace(
      /\{hashtag\|\\#\|(\w+)\}/g,
      "<a target='__blank' class='text-blue-500 font-medium hover:underline' href='https://www.linkedin.com/feed/hashtag/?keywords=$1'>#$1</a>",
    );

    convertedText = convertedText.replace(/@\[([^\]]+)\]\(urn:li:organization:\d+\)/g, '@$1');

    return convertedText;
  }

  const parsedCaption = convertText(data?.caption);

  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref?.current?.clientWidth);
    }
  }, [ref]);

  return (
    <idk-div
      ref={ref}
      style={{
        display: 'flex',
        justifyContent: width > 600 ? 'space-between' : 'flex-start',
        height: '100%',
        backgroundColor: '#fff',
        padding: `${width <= 600 ? '20px' : '30px'} 10px 30px 10px`,
        minWidth: '300px',
        width: '100%',
        maxWidth: '600px',
        flexGrow: 1,
        rowGap: 10,
        flexDirection: width <= 600 ? 'column' : 'row',
        position: 'relative',
        borderRadius: layout?.cardSettings?.cardBorder,
        color: 'black',
      }}
      onClick={() => {
        clickMutate(data.id);
      }}
    >
      <idk-div
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <idk-div class="zid-w-full zid-flex zid-gap-[10px] zid-justify-between zid-items-center zid-py-[10px]">
          <idk-div class="zid-flex-1 zid-flex zid-justify-start zid-items-start zid-gap-2">
            <idk-div class="zid-rounded-full zid-overflow-hidden zid-bg-slate-300 zid-flex zid-items-center zid-justify-center zid-w-[32px] zid-h-[32px]">
              <ImageWithFallback alt="" src={data?.profilePic} />
            </idk-div>
            <idk-div class="zid-flex zid-flex-col">
              <idk-p class="zid-m-0 zid-text-start zid-text-[14px] zid-font-[600] zid-text-[#141414]">
                {data?.username}
              </idk-p>
              <p className="zid-m-0 zid-text-xs zid-font-light zid-text-slate-400">
                {epochToRelativeDate(data?.timestamp)}
              </p>
            </idk-div>
          </idk-div>
          <FaLinkedin size={25} color="#0762C8" />
        </idk-div>
        {data?.repost == true && (
          <idk-div class="zid-flex zid-gap-2 zid-items-center">
            <idk-div class="zid-flex zid-gap-2 zid-items-center">
              {data?.author?.profilePictures?.[0]?.url && (
                <img src={data?.author?.profilePictures?.[0]?.url} className="zid-h-10 zid-w-10 zid-rounded-full"></img>
              )}
              <h4 className="zid-font-semibold zid-m-0 zid-text-sm">
                <a className="zid-text-black" target="_blank" href={data?.author?.url}>
                  {data?.author?.firstName} {data?.author?.lastName}
                </a>
              </h4>
            </idk-div>
          </idk-div>
        )}
        <idk-div class="zid-p-[10px]">
          {data?.caption && data.caption !== 'undefined' && (
            <idk-span
              style={{
                whiteSpace: 'pre-wrap',
              }}
              className="zid-text-slate-800 zid-text-sm zid-leading-relaxed zid-break-words"
              dangerouslySetInnerHTML={{
                __html: isExpanded ? parsedCaption : `${parsedCaption.slice(0, 150)}`,
              }}
            />
          )}
          {data?.caption?.length > 150 && (
            <idk-span onClick={handleToggleExpand} className="zid-mt-3 zid-text-right">
              <span className="zid-text-slate-500 hover:zid-text-blue-400 hover:zid-underline zid-cursor-pointer zid-rounded-full zid-text-sm zid-font-light zid-pl-2">
                {isExpanded ? null : '...see more'}
              </span>
            </idk-span>
          )}
        </idk-div>
        {data?.article && (
          <a className="zid-text-black" href={data?.permalink} target="_blank">
            {data?.article?.largeImage?.length > 0 && (
              <img src={data?.article?.largeImage[0].url} className="zid-w-full" />
            )}
            {data?.article?.smallImage?.length > 0 && (
              <img src={data?.article?.smallImage[0].url} className="zid-w-full" />
            )}
            {data?.article?.title && (
              <idk-div class="zid-bg-gray-100 zid-p-2 zid-mb-2">
                {data?.article?.title && <p className="zid-text-sm zid-font-semibold">{data?.article?.title}</p>}
                {data?.article?.subtitle && <p className="zid-text-xs">{data?.article?.subtitle}</p>}
              </idk-div>
            )}
          </a>
        )}
        {data?.resharedPost && (
          <idk-div class="zid-m-[10px] zid-p-[10px] zid-border zid-rounded">
            <h4 className="zid-font-semibold zid-text-sm">
              <a className="zid-text-black" href={data?.resharedPost?.company?.url} target="_blank">
                {data?.resharedPost?.company?.name}
              </a>
            </h4>
            <idk-div class="zid-flex zid-gap-2 zid-items-center">
              {data?.resharedPost?.author?.profilePictures?.[0]?.url && (
                <img
                  src={data?.resharedPost?.author?.profilePictures?.[0]?.url}
                  className="zid-h-10 zid-w-10 zid-rounded-full"
                ></img>
              )}
              <h4 className="zid-font-semibold zid-m-0 zid-text-sm">
                <a className="zid-text-black" target="_blank" href={data?.resharedPost?.author?.url}>
                  {data?.resharedPost?.author?.firstName} {data?.resharedPost?.author?.lastName}
                </a>
                <idk-span class="zid-text-sm zid-block zid-font-normal">
                  {data?.resharedPost?.author?.headline}
                </idk-span>
              </h4>
            </idk-div>

            <a className="zid-text-black" href={data?.resharedPost?.article?.link} target="_blank">
              {data?.resharedPost?.article?.smallImage?.length > 0 && (
                <img src={data?.resharedPost?.article?.smallImage[0].url} className="zid-w-full" />
              )}
              {data?.resharedPost?.article?.largeImage?.length > 0 && (
                <img src={data?.resharedPost?.article?.largeImage[0].url} className="zid-w-full" />
              )}
              {data?.resharedPost?.article?.title && (
                <p className="zid-text-sm zid-font-semibold">{data?.resharedPost?.article?.title}</p>
              )}
              {data?.resharedPost?.article?.subtitle && (
                <p className="zid-text-xs">{data?.resharedPost?.article?.subtitle}</p>
              )}
              <p className="zid-text-sm">{data?.resharedPost?.text}</p>
              {data?.resharedPost?.video?.length > 0 && (
                <video
                  loop
                  width="100%"
                  height="auto"
                  src={data?.resharedPost?.video?.[0]?.url}
                  muted
                  poster={data?.resharedPost?.video?.[0]?.poster}
                  controls={true}
                >
                  <source src={data?.resharedPost?.video?.[0]?.url} type="video/mp4" />
                </video>
              )}
              {data?.resharedPost?.image?.length > 0 && (
                <img src={data?.resharedPost?.image?.[0]?.url} className="zid-w-full" />
              )}
            </a>
          </idk-div>
        )}
        <HotspotMedia
          layout={layout}
          row={data}
          playFunctionality={false}
          autoPlay={layout?.videoSettings?.videoAutoPlay}
        />
      </idk-div>
    </idk-div>
  );
}

const ImageWithFallback = ({ src, alt }) => {
  const [isBroken, setIsBroken] = React.useState(false);

  const handleError = () => {
    setIsBroken(true);
  };

  return (
    <>
      {isBroken ? (
        <IoPerson size={25} />
      ) : (
        <img src={src} alt={alt} onError={handleError} className="zid-object-contain zid-w-full" />
      )}
    </>
  );
};
