// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getCurrentSymbol, calculatePrice, PriceDisplay } from '../../../Helpers/Helpers';
import ProductMobileOverlay from './ProductMobileOverlay';
import { UseWindowDimensions } from '../../collage/utils';
import { useDisplayWidgetContext } from '../../DisplayWidget/utils/context';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function ProductsOverlay({ row, shopifyData, isOpen }: any) {
  const { data, setProductOverlayIndex, isWooCommerceSite } = useDisplayWidgetContext();
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const { width } = UseWindowDimensions();

  useEffect(() => {
    if (!isOpen) {
      setSelectedProduct(null);
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <idk-div class="zid-absolute zid-bottom-2 zid-px-2 zid-w-full">
        <Swiper centeredSlides={true} slidesPerView={1} loop={true} pagination={false}>
          {row?.products?.length > 0 &&
            row.products.map((product, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="zid-relative zid-flex zid-bg-black zid-bg-opacity-50 zid-rounded zid-text-white zid-w-full zid-h-24 zid-cursor-pointer zid-items-center"
                  onClick={() => {
                    setSelectedProduct(product);
                    setProductOverlayIndex(index);
                  }}
                >
                  <img
                    src={product?.images?.[0]?.image_url || product?.images?.[0]}
                    className="zid-h-24 zid-rounded-l zid-object-contain zid-bg-white"
                    style={{ maxWidth: '96px', maxHeight: '96px' }}
                    loading="lazy"
                  ></img>

                  <idk-div class="zid-flex zid-flex-col zid-justify-between zid-p-2 zid-h-24 zid-w-full">
                    <idk-span
                      class="zid-text-ellipsis zid-overflow-hidden zid-text-base "
                      style={{
                        display: '-webkit-box',
                        '-webkit-line-clamp': '1',
                        '-webkit-box-orient': 'vertical',
                        overflow: 'hidden',
                        fontWeight: 500,
                        lineHeight: '18px',
                      }}
                    >
                      {product?.title}
                    </idk-span>
                    <idk-div class="zid-flex zid-justify-between">
                      <idk-div class="zid-flex zid-gap-2">
                        <idk-div class="zid-font-bold">
                          {data.result.currency &&
                            getCurrentSymbol(data.result.currency, navigator?.language || navigator['userLanguage'])}
                          {isWooCommerceSite() &&
                            !data.result.currency &&
                            document?.getElementsByClassName('woocommerce-Price-currencySymbol')?.[0]?.innerHTML}
                          {shopifyData?.currency?.active &&
                            !data.result.currency &&
                            getCurrentSymbol(shopifyData?.currency?.active, shopifyData?.locale)}
                          {shopifyData ? (
                            calculatePrice(
                              parseFloat(product?.variants?.[0]?.price) / 100,
                              parseFloat(shopifyData?.currency?.rate),
                            )
                          ) : (
                            <PriceDisplay price={product?.variants?.[0]?.price} />
                          )}
                        </idk-div>
                        {shopifyData && product?.variants?.[0]?.compare_at_price > product?.variants?.[0]?.price && (
                          <idk-div>
                            <del className="zid-me-2 zid-text-red-400	zid-text-sm">
                              {data.result.currency &&
                                getCurrentSymbol(
                                  data.result.currency,
                                  navigator?.language || navigator['userLanguage'],
                                )}
                              {isWooCommerceSite() &&
                                !data.result.currency &&
                                document?.getElementsByClassName('woocommerce-Price-currencySymbol')?.[0]?.innerHTML}
                              {shopifyData?.currency?.active &&
                                !data.result.currency &&
                                getCurrentSymbol(shopifyData?.currency?.active, shopifyData?.locale)}
                              {parseFloat(product?.variants?.[0]?.compare_at_price) / 100}
                            </del>
                          </idk-div>
                        )}
                      </idk-div>
                    </idk-div>
                    <idk-div>
                      <button className="zid-absolute zid-right-[10px] zid-bottom-[10px] zid-rounded zid-bg-[#f0f0f0] zid-text-black zid-text-sm zid-font-bold zid-px-3 zid-py-1">
                        Shop
                      </button>
                    </idk-div>
                  </idk-div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </idk-div>
      {(width <= 600 || data.result.layout.galleryView === 'frame') && (
        <ProductMobileOverlay
          isOpen={isOpen}
          product={selectedProduct}
          setProduct={setSelectedProduct}
          shopifyData={shopifyData}
        />
      )}
    </React.Fragment>
  );
}
