import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { IData, IView } from '../DisplayWidget/DisplayWidget.container';
import { useDisplayWidgetContext } from '../DisplayWidget/utils/context';
import GoogleRating from '../GoogleRating';

const Badge5 = ({ rating, reviews }) => {
  const { data }: { data: IData } = useDisplayWidgetContext();
  const galleryLayout: IView = data?.result?.layout;
  const style = galleryLayout?.googleBadges;
  return (
    <idk-div
      style={{
        border: `${style?.border} solid #515A5A`,
        padding: '20px 15px',
        borderRadius: '4px',
        backgroundColor: style.bgColor,
        color: style.fontColor,
        maxWidth: 400,
        height: '100%',
      }}
      class="zid-flex zid-items-center zid-gap-2"
    >
      <idk-div class="zid-my-auto">
        <idk-div style={{ padding: '5px', borderRadius: '8px' }}>
          <FcGoogle size={60} />
        </idk-div>
      </idk-div>
      <idk-div class="zid-flex zid-flex-col ">
        <idk-p class="zid-m-0" style={{ fontSize: '18px', fontWeight: 500 }}>
          Google Reviews
        </idk-p>
        <idk-div class="zid-flex zid-gap-2" style={{ alignItems: 'end' }}>
          <idk-p class="zid-m-0 zid-my-auto" style={{ fontSize: '40px', lineHeight: '40px' }}>
            {rating}
          </idk-p>
          <idk-div>
            <idk-div class="zid-my-auto">
              <GoogleRating rating={rating} size="20px" color={style.starColor} />
            </idk-div>
            <idk-p class="zid-m-0" style={{ fontSize: '12px', fontWeight: 500 }}>
              Based on {reviews} reviews
            </idk-p>
          </idk-div>
        </idk-div>
      </idk-div>
    </idk-div>
  );
};

export default Badge5;
